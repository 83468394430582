<template>
  <div class="seo-text-block module">
    <VaimoTruncatedText v-if="data.text" v-bind="data" class="module__text" markdown />
  </div>
</template>

<script>
import { defineComponent, computed, watch } from '@nuxtjs/composition-api';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';

export default defineComponent({
  name: 'SeoTextBlock',
  components: {
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { isDesktop } = useScreenSize();
    const linesQty = computed(() => isDesktop.value ? 2 : 5);

    const data = computed(() => {
      return {
        text: props.sectionData?.text,
        truncate: 'by lines',
        lines: linesQty.value,
      };
    });

    return {
      data
    };
  }
});
</script>

<style lang="scss" scoped>
.seo-text-block {
  padding: 0 var(--spacer-md);
  text-align: center;

  .module__text {
    font-size: var(--font-size--sm);
    font-weight: 300;
    color: $gris_perle;

    ::v-deep a {
      color: $gris_perle;
    }
  }

  @include for-screen-m-and-l {
    padding: 0 270px;
  }
}
</style>
