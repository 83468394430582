<!-- New Component Name: E-Com Advantage, EComAdvantage -->

<template>
  <SfLink
    v-if="getBannerData.link"
    class="ecom-push"
    :link="getBannerData.link"
    :aria-label="`${declinationData.heading} ${declinationData.descr}`"
  >
    <VaimoBanner
      v-if="bannerSrc"
      v-bind="getBannerData"
      ratio="4:5"
      class="module__banner"
    />
    <VaimoHeading
      v-if="declinationData.heading"
      :heading="declinationData.heading"
      :heading-link="declinationData.headingLink"
      :heading-style="'emphasized'"
      heading-level="h3"
      class="module__heading"
    />
    <p v-if="declinationData.descr" class="module__descr">
      {{ declinationData.descr }}
    </p>
  </SfLink>
  <div v-else class="ecom-push">
    <VaimoBanner
      v-if="bannerSrc"
      v-bind="getBannerData"
      ratio="4:5"
      class="module__banner"
    />
    <VaimoHeading
      v-if="declinationData.heading"
      :heading="declinationData.heading"
      :heading-style="'emphasized'"
      heading-level="h3"
      :animated-heading="false"
      class="module__heading"
    />
    <p v-if="declinationData.descr" class="module__descr">
      {{ declinationData.descr }}
    </p>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'EcomPush',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    SfLink
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const declinationData = computed(() => props.sectionData);
    const { getBannerData } = useDeclination(props.sectionData);
    const bannerSrc = computed(() => getBannerData.value.src);

    return {
      declinationData,
      getBannerData,
      bannerSrc
    };
  }
});
</script>

<style scoped lang="scss">
.ecom-push {
  @apply flex flex-col justify-center items-center p-md bg-[#f8f8f8] m-[0px] text-center no-underline;

  @include for-screen-max-400 {
    @apply py-sm;
  }

  .module__banner {
    @apply mb-[25px] w-full max-w-[76px];

    @include for-screen-m-and-l {
      @apply max-w-[120px];
    }

    @include for-screen-max-400 {
      @apply mb-[10px];
    }
  }

  .module__heading {
    max-height: 75px;
    overflow: hidden;

    ::v-deep {
      .vaimo-heading__title {
        font-size: 0.875rem;

        @include for-screen-m-and-l {
          margin-bottom: 5px;
          font-size: 1rem;
        }
      }
    }
  }

  .module__descr {
    font-size: 0.75rem;
    font-weight: 300;
    max-height: 42px;
    overflow: hidden;

    @include for-screen-m-and-l {
      font-size: 0.875rem;
    }

    @include for-screen-max-400 {
      line-height: 1.2;
    }
  }
}
</style>
