















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HTMLContentMagento',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    content: {
      type: String,
      default: ''
    },
    layout: {
      type: String,
      default: 'defaultLayout'
    }
  }
});
