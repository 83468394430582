<template>
  <div class="no-results">
    <div class="search-info">
      <div class="heading">
        <SkeletonLoader
          v-if="!pageTitleNoResults"
          height="50px"
          width="300px"
          margin="0"
        />
        <VaimoHeading
          v-else
          :heading="pageTitleNoResults"
          :sub-heading="pageSubtitle"
          :animated-heading="false"
          heading-level="h1"
          sub-heading-level="h3"
          :class="{ 'subheading-normal' : isDeStore }"
        />
      </div>

      <div class="text">
        <p>
          {{ $t('Please try searching again with a different keyword.') }}
        </p>
      </div>
    </div>

    <div v-if="sectionsItems">
      <SectionsList :items="sectionsItems" fullscreen />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  onBeforeMount
} from '@nuxtjs/composition-api';
import { searchStore as useSearch } from '../index';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import { putInQuotation } from '~/diptyqueTheme/helpers/putInQuotation';
import { useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'KlevuSearchNoResults',
  components: {
    VaimoHeading: () =>
      import('../../../diptyqueTheme/components/atoms/VaimoHeading.vue'),
    SectionsList: () => import('~/components/templates/SectionsList.vue'),
    SkeletonLoader
  },
  setup(props, context) {
    const { getPageBySlug, page } = useContentfulPage('searchNoResults');
    const { query } = useContentfulGQL('searchNoResults');
    const sectionsItems = computed(() => page.value?.sectionsCollection?.items);
    const { app } = useContext();
    const isDeStore = computed(() => {
      const storeCode = app.i18n.localeProperties.code;
      return storeCode === 'de_eu';
    });

    const { $i18n } = context.root;
    // Stores
    const searchStore = useSearch();

    const pageTitleNoResults = computed(
      () => `${$i18n.t('Sorry! No products matching the your search term were found.')}`
    );

    const pageSubtitle = computed(() => `${$i18n.t('Search Results for')}${$i18n.localeProperties.code === 'zh_hk' ? '' : ':'} ` + putInQuotation(searchStore.searchTerm));

    onBeforeMount(async () => {
      return Promise.all([
        query('getsearchNoResults', {
          title: 'No Results Page'
        }),
        getPageBySlug('searchNoResults')
      ]);
    });

    return {
      searchStore,
      pageSubtitle,
      pageTitleNoResults,
      sectionsItems,
      SkeletonLoader,
      isDeStore
    };
  }
});
</script>

<style lang="scss" scoped>
.search-info {
  @apply flex items-center justify-center flex-col;

  .heading {
    text-align: center;
    margin-bottom: 40px;
  }

  .description {
    @apply mb-4md text-center text-lg font-light max-w-screen-sm;
  }

  @include for-screen-s {
    .description {
      @apply text-base mb-3md;
    }
  }
}

@include for-screen-m-and-l {
  .search-info {
    @apply pt-lg;
  }
}

@include for-screen-l {
  .search-info {
    @apply pt-lg;
  }
}

@include for-screen-s {
  .search-info {
    @apply pl-md pr-md pt-2md;
  }
}
</style>

<style lang="scss">
.search-info {
  .vaimo-heading__subtitle-default {
    font-family: var(--font-family--secondary);
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    text-transform: lowercase;
  }

  .subheading-normal .vaimo-heading__subtitle-default {
    text-transform: none;
  }

  .text {
    margin-bottom: 67px;

    p {
      font-family: var(--font-family--secondary);
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 135%;
      text-align: center;
      color: #000000;
    }
  }
}
</style>
