












































import { computed } from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';
import { useDeclination } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'BrandServiceCard',
  components: {
    VaimoBadge: () => import('atoms/VaimoBadge.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue')
  },
  props: {
    uniqueKey: {
      required: false,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    },
    oneItemTpl: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    const { getBannerData, getButtonData } = useDeclination(props.sectionData);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const $markdown = root?.$markdown;
    const transformText = (text: string): string => {
      return text ? $markdown.render(text) : '';
    };

    const visualRight = computed(() => {
      return (
        props.sectionData?.visualPosition === 'Visual on the right for desktop'
      );
    });

    return {
      transformText,
      getButtonData,
      getBannerData,
      visualRight
    };
  }
});
