<template>
  <div data-purpuse="safe-classlist" class="hidden">
    <span
      class="mx-auto text-center text-start text-end desktop:text-center desktop:mx-auto desktop:text-start desktop:text-end desktop:ml-0 desktop:mr-0 mb-xs desktop:mb-xs desktop:pt-md desktop:pt-lg desktop:pt-3md pt-3md pt-md pt-lg m-and-l:grid-cols-1 m-and-l:grid-cols-2 m-and-l:grid-cols-3 m-and-l:grid-cols-4 s:col-span-1 m-and-l:col-span-1 s:col-span-2 m-and-l:col-span-2 s:col-span-3 m-and-l:col-span-3 s:col-span-4 m-and-l:col-span-4 s:col-span-5 m-and-l:col-span-5 s:col-span-6 m-and-l:col-span-6 s:col-span-7 m-and-l:col-span-7 s:col-span-8 m-and-l:col-span-8 s:col-span-9 m-and-l:col-span-9 s:col-span-10 m-and-l:col-span-10 s:col-span-11 m-and-l:col-span-11 s:col-span-12 m-and-l:col-span-12"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'TailwindSafeClasses'
};
</script>
