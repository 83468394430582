
















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'VaimoCrossSellProducts',
  components: {
    VaimoCrossSellProduct: () =>
      import(
        '~/diptyqueTheme/components/organisms/VaimoCrossSellProducts/VaimoCrossSellProduct.vue'
      )
  },
  props: {
    products: {
      required: true,
      type: Array as PropType<Product[]>
    },
    title: {
      required: false,
      default: '',
      type: String
    }
  },
  setup(props) {
    const getSortedProducts = computed(() => {
      const products = props.products;

      return products.sort((item1, item2) => +item1.position - +item2.position);
    });

    return { getSortedProducts };
  }
});
