









































import {
  defineComponent,
  ref,
  computed,
  useContext
} from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { SfInput } from '@storefront-ui/vue';
import { useUser } from '~/modules/customer/composables/useUser';
import { useApi } from '~/composables/useApi';
import addStockNotification from '~/diptyqueTheme/customQueries/magento/addStockNotification';
export default defineComponent({
  name: 'ProductAvailabilityNotification',
  components: {
    SfInput,
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    ValidationProvider,
    ValidationObserver
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  setup(props) {
    interface AddStockNotificationResponse {
      addStockNotification: {
        success: boolean;
        message: string;
      };
    }

    const { isAuthenticated, user } = useUser();
    const { app } = useContext();
    const { query } = useApi();
    const email = ref<string>('');
    const notifyMessage = ref<string>('');
    const loading = ref<boolean>(false);

    const userEmail = computed<string>({
      get() {
        if (isAuthenticated.value && !email.value) {
          return user.value.email;
        }
        return email.value;
      },
      set(newValue) {
        email.value = newValue as string;
      }
    });
    const onAddStockNotification = async (invalid: boolean): Promise<void> => {
      if (invalid) return;
      try {
        loading.value = true;
        const { data } = await query<AddStockNotificationResponse>(
          addStockNotification,
          {
            email: userEmail.value,
            product_id: props.id
          }
        );
        const addStockNotificationData = data?.addStockNotification;
        if (addStockNotificationData) {
          const { success, message } = addStockNotificationData;
          if (success) {
            notifyMessage.value = app.i18n.t(
              'Thank you. We will notify you when the product is back in stock.'
            ) as string;
          } else {
            notifyMessage.value = app.i18n.t(message) as string;
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };
    return { onAddStockNotification, userEmail, notifyMessage, loading };
  }
});
