var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-slider"},[_c('VaimoSectionTemplate',{staticClass:"px-section",attrs:{"section-data":_vm.getSectionData,"visual-out":true,"content-out":true,"content-out-d":true,"header-visible":false}},[_c('div',{staticClass:"box-slider__heading px-section"},[(_vm.getProductTitleAndDescription(_vm.product).heading)?_c('VaimoHeading',{attrs:{"heading":_vm.getProductTitleAndDescription(_vm.product).heading,"animated-heading":false,"heading-link":_vm.sectionData.headingLink,"heading-level":"h2"}}):_vm._e(),_vm._v(" "),(_vm.getProductTitleAndDescription().description)?_c('div',{staticClass:"box-slider__description"},[_vm._v("\n        "+_vm._s(_vm.getProductTitleAndDescription().description)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"box-slider__product-price"},[(!_vm.isDynamicPrice)?_c('span',[_vm._v("\n        "+_vm._s(_vm.validateFraction(_vm.getProductPrice))+"\n      ")]):(
          _vm.isDynamicPrice &&
          _vm.selectedBundleProducts &&
          !_vm.selectedBundleProducts.length
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.getDynamicPriceText(
            _vm.validateFraction(_vm.product.custom_set_minimal_price)
          ))+"\n      ")]):(
          _vm.isDynamicPrice &&
          _vm.selectedBundleProducts &&
          _vm.selectedBundleProducts.length
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.validateFraction(_vm.getDynamicPrice))+"\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"box-slider__counter"},[_vm._v("\n      "+_vm._s(_vm.getBoxSliderCounterText)+"\n      "),_c('span',[_vm._v(_vm._s(("(" + _vm.getQuantitySum + "/" + _vm.getNumberOfSets + "):")))])]),_vm._v(" "),_c('div',{staticClass:"box-slider__products"},[(_vm.bundleProducts && _vm.bundleProducts.length)?_c('VaimoSlider',{attrs:{"items-length":_vm.bundleProducts.length,"mobile-shift":50,"desktop-shift":0,"slides-to-scroll":1,"desktop-quantity-visible":_vm.getSliderDesktopQty}},_vm._l((_vm.bundleProducts),function(item){return _c('div',{key:item.uid},_vm._l((item.options),function(option){return _c('BoxSliderCard',{key:option.uid,attrs:{"product":option,"selected-items":_vm.selectedBundleProducts,"is-max-added":_vm.isMaxProductsAdded,"number-of-sets":_vm.getNumberOfSets,"fields-visibility-arr":_vm.getFieldsVisibilityArr},on:{"updateQty":_vm.updateQuantity}})}),1)}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"box-slider__add-to-cart",class:{ disabled: _vm.loading }},[_c('VaimoButtons',{key:_vm.isMaxProductsAdded,attrs:{"section-data":_vm.getSectionData},nativeOn:{"click":function($event){return _vm.addBundleProductsToCart.apply(null, arguments)}}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }