
















































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { useDeclination, useLink } from '~/diptyqueTheme/composable';

interface SectionDataInterface {
  itemsCollection: {
    items: Array<{ [key: string]: any; __typename: string }>;
  };
}

export default defineComponent({
  name: 'IllustratedPush',
  components: {
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    SfLink
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object as PropType<SectionDataInterface>
    }
  },
  setup(props, { root }) {
    const { getVisualData } = useDeclination(props.sectionData);
    const { normalizeLink } = useLink();

    return {
      getVisualData,
      normalizeLink
    };
  }
});
