





















import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoMenuLayer',
  components: {
    Menu: () => import('organisms/menu/Menu.vue'),
    EditorialPushItem: () =>
      import('templates/sections/EditorialPush/EditorialPushItem.vue')
  },
  props: {
    data: {
      required: false,
      type: Array,
      default: () => []
    },
    currentNav: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['hide'],
  setup(props, { emit }) {
    const hide = () => {
      emit('hide');
    };

    const getSectionData = computed(() =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (props.data[0]?.data || []).filter(Boolean)
    );

    const pushBLockClasses = computed(() => {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'push-blocks-width--' + (props.data[0]?.pushBlocksWidth || 'small')
      );
    });
    return {
      hide,
      getSectionData,
      pushBLockClasses
    };
  }
});
