











import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';
import { useAccountStore } from '~/diptyqueTheme/stores/account';

export default defineComponent({
  name: 'AccountHeading',
  setup() {
    const accountStore = useAccountStore();
    const { app } = useContext();

    const getHeading = computed(() => {
      const titleMap = {
        AccountInfo: app.i18n.t('My Account Information'),
        AccountOrders: app.i18n.t('My Orders'),
        AccountShippingAddresses: app.i18n.t('Address book'),
        AccountSubscriptions: app.i18n.t('Subscriptions')
      };

      return titleMap[accountStore.currentScreen];
    });

    return { getHeading, accountStore };
  }
});
