















import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'VaimoShippingAddresses',
  components: {
    VaimoAddresses: () => import('organisms/VaimoAddresses.vue')
  },
  setup() {
    const customerStore = useCustomerStore();

    const getUserAddresses = computed(() => {
      const addresses = customerStore.user?.addresses ?? [];
      return addresses.reduce(
        (memo, cur) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          if (cur.address_book_type === 'shipping') {
            memo.shipping.push(cur);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
          } else if (cur.address_book_type === 'billing') {
            memo.billing.push(cur);
          } else {
            memo.shipping.push(cur);
            memo.billing.push(cur);
          }
          return memo;
        },
        { shipping: [], billing: [] }
      );
    });

    return {
      getUserAddresses
    };
  }
});
