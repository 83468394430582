

















































import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';
import { useSection } from '@/diptyqueTheme/composable/useSection';
import { SfLink } from '@storefront-ui/vue';
import { useMegaMenuMobileStore } from '@/diptyqueTheme/stores/megaMenuMobile';
import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'MenuListDisplay',
  components: {
    BlockWrapper: () => import('templates/BlockWrapper.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    SfLink
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    },
    isLastItem: {
      required: false,
      type: Boolean,
      default: false
    },
    mainLevelName: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      app: { localePath, $gtm }
    } = useContext();
    const { normalizeLink } = useLink();
    const megaMenuMobileStore = useMegaMenuMobileStore();
    const closeMobileMenu = () => {
      megaMenuMobileStore.hide();
      megaMenuMobileStore.isMenuLayersVisible = false;
    };
    const { getBlockElementClasses } = useSection(props.sectionData);

    const heading = computed(() => props.sectionData?.heading);

    const publishedItems = props.sectionData?.itemsCollection?.items.filter(
      (value) => value != null
    );
    const items = computed<Array<object>>(() => {
      return publishedItems;
    });

    const specialLastItem = computed<boolean>(
      () => props.sectionData?.specialLastItem
    );
    const isSpecial = (index: number): boolean => {
      return specialLastItem.value && items.value.length === index + 1;
    };
    const getDisplayClass = computed<string>(() => {
      const displayTypeMap = {
        'default list': 'default',
        'emphasised list': 'emphasised'
      };

      return displayTypeMap[props.sectionData?.display];
    });
    const getClickNavDetails = (menuItemName) => {
      $gtm.push({
        event: 'clickNav',
        mainLevel: props.mainLevelName,
        subLevel: menuItemName
      });
    };
    return {
      items,
      heading,
      specialLastItem,
      isSpecial,
      getDisplayClass,
      elemClasses: getBlockElementClasses,
      closeMobileMenu,
      normalizeLink,
      getClickNavDetails
    };
  }
});
