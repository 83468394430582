

















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';

import SectionsList from '~/diptyqueTheme/components/templates/SectionsList.vue';
import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';

export default defineComponent({
  name: 'CategoryMain',
  components: {
    VaimoBreadcrumbs: () => import('atoms/VaimoBreadcrumbs.vue'),
    SectionsList
  },
  transition: 'fade',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    categoryData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const activeCategory = computed(() => props.categoryData);
    const { sectionList, getSectionList } = useContentfulPage('mainCategory');

    onSSR(async () => {
      if (!activeCategory.value) return;

      await getSectionList('main', String(activeCategory.value?.id || 0));
    });

    const contentfulBlocks = computed(() => {
      let data = [];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (sectionList.value?.blocks?.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data = [...sectionList.value?.blocks];
      }

      return data;
    });

    return {
      contentfulBlocks
    };
  }
});
