



































import {
  computed,
  useMeta,
  useRouter,
  useRoute,
  useContext,
  defineComponent
} from '@nuxtjs/composition-api';
import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import VaimoHelpBlock from '~/diptyqueTheme/components/organisms/VaimoHelpBlock.vue';

export default defineComponent({
  components: {
    VaimoButton,
    VaimoHelpBlock
  },
  emits: ['changeStep'],
  setup(props, context) {
    // eslint-disable-next-line no-magic-numbers
    context.emit('changeStep', 4);
    const route = useRoute();
    const router = useRouter();
    const { app } = useContext();

    const orderNumber = computed(() => route.value.query.order ?? '');
    const orderType = computed(() => route.value.query.type ?? 'error');

    const goBackToShop = () => {
      return router.push(app.localePath('/'));
    };

    const metaTags = computed(() => ({
      title: `${app.i18n.t('Checkout')} | Diptyque Paris`,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${app.i18n.t('Checkout')} | Diptyque Paris`
        },
        {
          hid: 'robots',
          property: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }));

    useMeta(metaTags.value);

    return {
      orderType,
      orderNumber,
      goBackToShop
    };
  },
  head: {}
});
