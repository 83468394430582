
























































import { computed, defineComponent } from '@nuxtjs/composition-api';
import articleSectionDeclinations from '~/diptyqueTheme/config/articleSectionDeclinations';
import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'ArticleSectionMobileOneItem',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    Labels: () => import('./Labels.vue')
  },
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const getItem = computed(() => {
      const item = props.data.articlesCollection?.items?.[0] || {};
      item.ctaLink = normalizeLink(item.ctaLink);
      return item;
    });

    const declination = computed(() => {
      const declinations = {
        [articleSectionDeclinations.MAJOR]: 'major',
        [articleSectionDeclinations.STANDARD]: 'standard'
      };
      return declinations[props.data.mVar];
    });

    return {
      getItem,
      declination
    };
  }
});
