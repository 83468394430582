



















































import {
  defineComponent,
  onBeforeMount,
  nextTick,
  computed,
  ref
} from '@nuxtjs/composition-api';
import VaimoBanner from 'atoms/VaimoBanner.vue';
import { useSwipe } from '@vueuse/core';
import { debounce } from 'lodash-es';

export default defineComponent({
  name: 'ProductGalleryMobile',
  components: {
    VaimoBanner,
  },
  props: {
    items: {
      required: true,
      type: Array
    },
    sectionId: {
      required: true,
      type: String
    },
    uniqueKey: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { root }) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const $markdown = root?.$markdown;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const imageDescription = ref(props.items[0]?.descr);
    const activeIndex = ref(0);
    const transformText = (text) => {
      return text ? $markdown.render(text) : '';
    };
    const productGallery = ref(null);

    const { direction } = useSwipe(
      productGallery, {
        onSwipe() {
          handleSwipe(direction.value)
        }
      }
    )

    const handleSwipe = debounce((direction) => {
      const numberOfItems = props.items.length;
      if (numberOfItems <= 1) return;
      if (direction === 'RIGHT') {
        if (activeIndex.value === 0) {
          changeImageWithBullet(numberOfItems - 1);
        } else {
          changeImageWithBullet(activeIndex.value - 1);
        }
      } else if (direction === 'LEFT') {
        if (activeIndex.value === numberOfItems - 1) {
          changeImageWithBullet(0);
        } else {
          changeImageWithBullet(activeIndex.value + 1);
        }
      }
    }, 300);

    const uid = computed(() => props.uniqueKey || props.sectionId);


    const getGalleryItems = () => {
      return document.querySelectorAll(
        '.product-gallery--' + uid.value + ' .gallery_item'
      );
    };

    const clickImg = ($event) => {
      const $clickedItem = $event.target.closest('.gallery_item');
      activateItem($clickedItem);
    };

    const activateItem = ($clickedItem) => {
      if ($clickedItem.classList.contains('active')) return;

      imageDescription.value =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        props.items[+$clickedItem.dataset.index]?.descr;

      removeClass('active');
      removeClass('next1');

      if (!isTwoItems.value) {
        removeClass('next2');
      }

      addClassesStartingWith($clickedItem);
    };

    const isTwoItems = computed(() => props.items.length === 2);

    const removeClass = (cls) => {
      const $el = document.querySelector(
        '.product-gallery--' + uid.value + ' .' + cls
      );
      $el.classList.add('ex-' + cls);
      $el.classList.remove(cls);
      setTimeout(() => {
        $el.classList.remove('ex-' + cls);
      }, 300);
    };

    const addClassesStartingWith = ($clickedItem) => {
      if (!$clickedItem) return;
      const clickedItemIndex = +$clickedItem.dataset.index;
      activeIndex.value = clickedItemIndex;
      const galleryItems = getGalleryItems();
      const itemCount = galleryItems.length;

      const nextIndex1 = (clickedItemIndex + 1) % itemCount;
      const nextIndex2 = (clickedItemIndex + 2) % itemCount;

      const clickedItem = galleryItems[clickedItemIndex];
      const nextItem1 = galleryItems[nextIndex1];
      const nextItem2 = galleryItems[nextIndex2];

      clickedItem.classList.add('active');
      nextItem1.classList.add('next1');

      if (!isTwoItems.value) {
        nextItem2.classList.add('next2');
      }
    };

    const changeImageWithBullet = (index) => {
      const galleryItems = getGalleryItems();
      const $item = galleryItems[index];
      activateItem($item);
    };

    onBeforeMount(() => {
      nextTick(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const $items = [...getGalleryItems()];
        if ($items?.length) {
          $items.forEach((el, index) => {
            if (index === 0) {
              el.classList.add('active');
            } else {
              el.classList.add(`next${index}`);
            }
          });
        }
      });
    });

    return {
      imageDescription,
      changeImageWithBullet,
      clickImg,
      activeIndex,
      transformText,
      productGallery
    };
  }
});
