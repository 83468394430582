<template>
  <div class="multiselect relative">
    <div class="multiselect__label">{{ $t(placeholder) + requiredMark }}</div>
    <multiselect
      :value="internalValue"
      :options="options"
      deselectLabel=""
      :searchable="searchable"
      selectedLabel=""
      selectLabel=""
      :name="name"
      :label="label"
      :track-by="trackBy"
      :placeholder="placeholder"
      @select="onSelect"
      data-cs-mask
    >
      <template #noResult>
        <span>
          {{ $t('No element found. Consider changing the search query.') }}
        </span>
      </template>
    </multiselect>
    <small v-if="errorMessage" class="error-message" data-cs-capture>
      {{ errorMessage }}
    </small>
  </div>
</template>

<script>
import { defineComponent, toRef, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoMultiselect',
  props: {
    value: [String, Object],
    options: {
      required: true,
      type: Array
    },
    label: {
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      required: false,
      default: '',
      type: String
    },
    trackBy: {
      required: false,
      default: '',
      type: String
    },
    errorMessage: {
      required: false,
      default: '',
      type: String
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    searchable: {
      required: false,
      default: true,
      type: Boolean
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const internalValue = toRef(props, 'value');

    const onSelect = (newVal) => {
      emit('input', newVal);
    };

    const requiredMark = computed(() => {
      return props.required ? ' *' : '';
    });

    return {
      onSelect,
      internalValue,
      requiredMark
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/diptyqueTheme/assets/styles/atoms/VaimoMultiselect';
</style>
