






import {defineComponent, computed } from "@nuxtjs/composition-api";

export default defineComponent({
  name: "Divider",
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const type = props.sectionData?.type;

    const classes = computed(() => {
      const typeClass = type ? type.substring(type.indexOf('-') + 2, type.lastIndexOf(' ')).toLowerCase() : '';
      const colorClass = type ? type.substring(type.lastIndexOf(' ')).toLowerCase() : '';
      return typeClass + colorClass;
    })

    return {
      classes
    }
  }
})
