































import { computed, defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'VaimoQuantitySelector',
  props: {
    qty: Number,
    max: Number,
    disableAdd: Boolean,
    disableRemove: Boolean,
    min: {
      type: Number,
      default: 0
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const quantity = ref(props.qty);

    const isMaxReached = computed(() => quantity.value >= props.max);
    const isMinReached = computed(() => quantity.value <= props.min);

    const increment = (): void => {
      if (!isMaxReached.value) {
        quantity.value++;
        emit('input', quantity.value);
      }
    };

    const decrement = (): void => {
      if (!isMinReached.value) {
        quantity.value--;
        emit('input', quantity.value);
      }
    };

    const handleInput = (event: Event): void => {
      let newValue = Number((event.target as HTMLInputElement).value);
      if (newValue > props.max) {
        newValue = props.max;
      } else if (newValue < props.min) {
        newValue = props.min;
      }
      quantity.value = newValue;
      emit('input', quantity.value);
    };

    watch(
      () => props.qty,
      (newQty) => {
        quantity.value = newQty;
      }
    );

    return {
      quantity,
      handleInput,
      isMaxReached,
      increment,
      decrement
    };
  }
});
