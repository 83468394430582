
























































































/*TODO: Add VaimoTabs */
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';

import { useProduct, useUiNotification } from '~/composables';
import productStatuses from '~/diptyqueTheme/config/productStatuses';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import type { CategoryInterface } from '~/modules/catalog/category/types';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import useCart from '~/modules/checkout/composables/useCart';
import { useCartStore } from '~/modules/checkout/stores/cart';
import type { Visual } from '~/diptyqueTheme/types/contentful';
import { useAlternativeVisual } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'VaimoCandleDuet',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getProductList, loading: isProductsLoading } = useProduct();
    const { send: sendNotification } = useUiNotification();
    const { app } = useContext();
    const { cart } = useCart();
    const cartStore = useCartStore();
    const { applyAltVisuals } = useAlternativeVisual();
    const products = ref([]);
    const isLoading = ref(false);
    const allTabsProducts = ref([]);

    const getItems = computed(
      () => props.sectionData.productGroupsCollection?.items
    );

    const allTabsSKUs = computed(
      () => getItems.value.flatMap((product) => product.sku) || []
    );

    const isMoreThanOneProductInActiveTab = computed(
      () => activeTab.value?.products?.length > 1
    );

    const getAllTabsProducts = async () => {
      const data = await getProductList({
        filter: {
          sku: {
            in: allTabsSKUs.value
          }
        }
      });
      allTabsProducts.value = data?.items;
    };

    const sortAndFilterProducts = (sku_list, items, alternativeVisuals) => {
      const MAX_PRODUCT = 2;
      let filteredProducts = items?.filter(
        (product) =>
          product.stock_status === productStatuses.in_stock &&
          product.is_salable
      );
      filteredProducts?.sort((item1, item2) => {
        const index1 = sku_list.indexOf(item1.sku);
        const index2 = sku_list.indexOf(item2.sku);
        return index1 - index2;
      });
      if (alternativeVisuals) {
        filteredProducts = applyAltVisuals(
          filteredProducts as Product[],
          alternativeVisuals as Visual[]
        );
      }
      return filteredProducts.slice(0, MAX_PRODUCT);
    };

    const isActiveTabsProductsExist = computed(
      () => activeTab.value?.products?.length
    );

    const getTabs = computed(() => {
      const tabs = getItems.value.reduce((memo, cur) => {
        const filteredProducts = allTabsProducts.value.filter((product) =>
          cur.sku.includes(product.sku)
        );
        memo.push({
          tabTitle: cur.groupTitle,
          productsSkus: cur.sku,
          id: cur.sys.id,
          description: cur.descr,
          products: sortAndFilterProducts(
            cur.sku,
            filteredProducts,
            cur?.altvisCollection?.items
          )
        });
        return memo;
      }, []);
      return tabs.filter((tab) => tab.products?.length > 1);
    });
    const activeTab = ref(getTabs.value[0]);

    const handleTabClick = (id, $event) => {
      activeTab.value = getTabs.value.find((item) => item.id === id);
      $event.target.closest('.tab').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });
    };
    const getProductImageUrl = (product) => product?.thumbnail?.url;

    const getProductImageUrlOnHover = (product) => product?.hover_on_card;

    const getTotalAmount = computed(() => {
      return activeTab.value?.products.reduce((memo, cur) => {
        memo = productGetters.getPrice(cur).regular + memo;
        return memo;
      }, 0);
    });

    const addProductsToCart = async () => {
      const cartId = cart.value?.id;
      isLoading.value = true;
      const cartItems = activeTab.value.products.map((product) => ({
        quantity: 1,
        sku: product.sku
      }));
      try {
        const res = await app.context.$vsf.$magento.api.addProductsToCart({
          cartId,
          cartItems
        });
        sendNotification({
          id: Symbol('candle_duet_add'),
          message: app.i18n.t(
            'Products successfully added to your bag'
          ) as string,
          type: 'success',
          persist: false,
          title: app.i18n.t('Products added') as string
        });
        if (res.data?.addProductsToCart?.cart?.total_quantity) {
          cartStore.$patch((state) => {
            state.cart = res.data.addProductsToCart.cart;
          });
        }
      } catch (e) {
        sendNotification({
          id: Symbol('candle_duet_add_error'),
          message: e.message,
          type: 'danger',
          persist: false,
          title: app.i18n.t('Something went wrong!') as string
        });
      } finally {
        isLoading.value = false;
      }
    };

    const getProductLink = (product) => {
      const link = `${productGetters.getSlug(
        product as Product,
        product?.categories?.[0] as CategoryInterface
      )}`;

      return app.localePath(link);
    };

    useFetch(async () => {
      await getAllTabsProducts();
      activeTab.value = getTabs.value[0];
    });

    return {
      getItems,
      getTabs,
      isActiveTabsProductsExist,
      isMoreThanOneProductInActiveTab,
      getProductLink,
      getProductImageUrl,
      getTotalAmount,
      isProductsLoading,
      isLoading,
      addProductsToCart,
      products,
      handleTabClick,
      activeTab,
      validateFraction,
      getProductImageUrlOnHover
    };
  }
});
