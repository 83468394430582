






















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { AudioProp, ImageProp } from 'molecules/VaimoAudioPlayer/models';
import VaimoAudioPlayer from 'molecules/VaimoAudioPlayer/VaimoAudioPlayer.vue';
import { SectionDataProp } from 'templates/sections/AudioStory/models';
import type { PropType } from 'vue';
import LazyHydrate from 'vue-lazy-hydration';

import { useScreenSize } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'AudioStory',
  components: { LazyHydrate, VaimoAudioPlayer },
  props: {
    sectionData: {
      required: true,
      type: Object as PropType<SectionDataProp>
    }
  },

  setup(props) {
    const heading = computed(() => props.sectionData.heading);
    const subheading = computed(() => props.sectionData.subheading);
    const audioHeading = computed(() => props.sectionData.audioHeading);
    const audioSubheading = computed(() => props.sectionData.audioSubheading);

    const audio = computed<AudioProp>(() => ({
      src: `https:${props.sectionData.audio?.fields.file.url}`,
      contentType: props.sectionData.audio?.fields.file.contentType as string
    }));

    const { isDesktop } = useScreenSize();

    const image = computed<ImageProp>(() => {
      const visual =
        isDesktop.value && props.sectionData.visual?.visD
          ? props.sectionData.visual?.visD
          : props.sectionData.visual?.vis;

      return {
        src: visual?.url,
        alt: visual?.fields?.title
      };
    });

    return {
      heading,
      subheading,
      audioHeading,
      audioSubheading,
      audio,
      image,
      isDesktop
    };
  }
});
