














































































































































import {
  ref,
  defineComponent,
  computed,
  useContext,
  onMounted
} from '@nuxtjs/composition-api';
import Vue from 'vue';
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { SfImage, SfInput } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import { useSelectedPaymentMethodStore } from "~/diptyqueTheme/stores/selectedPaymentMethod";
import useUser from "~/modules/customer/composables/useUser";

extend('required', {
  ...required,
  message: 'This is a required field.'
});

extend('cardCvc', {
  validate(value) {
    return {
      valid: Vue.prototype.$cardFormat.validateCardCVC(value)
    };
  },
  message: 'Please enter a valid credit card verification number.'
});

export default defineComponent({
  name: 'CreditCardForm',
  components: {
    SfImage,
    SfInput,
    ValidationProvider,
    ValidationObserver,
    VaimoMultiselect: () => import('atoms/VaimoMultiselect.vue'),
    VaimoCheckbox: () => import('molecules/VaimoCheckbox.vue'),
    CvvTooltip: () => import('./CvvTooltip.vue')
  },
  props: {
    config: {
      required: true,
      type: Object
    },
    isNew: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: ['afterSavePayment', 'beforeSavePayment'],
  setup(props, { emit }) {
    const CC_CODE = 'np_gmo_payment_credit_card';
    const CC_VAULT_CODE = 'np_gmo_payment_credit_card_vault';
    const CC_BRAND = {
      amex: 'AE',
      visa: 'VI',
      mastercard: 'MC',
      discover: 'DI',
      dinersclub: 'DN',
      jcb: 'JCB',
      cup: 'CUP',
      maestro: 'MI'
    };

    const { i18n } = useContext();
    const { save } = usePaymentProvider();
    const selectedPaymentMethodStore = useSelectedPaymentMethodStore();
    const cardFormat = Vue.prototype.$cardFormat;
    const cardId = computed(() => props.config.paymentDetails?.id ?? null);
    const availableCcTypes = computed(() => props.config.ccTypes ?? []);
    const methodOptions = computed(() => props.config.ccMethods ?? []);
    const payTimesOptions = computed(() => props.config.ccPayTimes ?? []);
    const monthOptions = computed(() => props.config.ccMonths);
    const yearOptions = computed(() => props.config.ccYears);
    const cvvImageUrl = computed(() => props.config.cvvImageUrl);
    const isShowPayTimes = computed(() => form.value.method?.code == 2 || form.value.method?.code == 4);
    const cardExpiry = computed(() => form.value.cardExpiryYear?.code + form.value.cardExpiryMonth?.code?.padStart(2, '0'))
    const cardBrandIcon = computed(() => getCardIcon());
    const cardBrand = ref(null);
    const cardToken = ref(null);
    const cardTokenForVault = ref(null);
    const maskedCardNumber = ref(null);
    const { isAuthenticated } = useUser();

    const form = ref({
      cardNumber: null,
      cardExpiryMonth: null,
      cardExpiryYear: null,
      cardCvc: null,
      method: methodOptions.value?.[0],
      payTimes: null,
      isSave: false
    });

    const cardErrors = ref({
      cardNumber: null,
      expiryMonth: null,
      expiryYear: null,
      cardCvc: null,
    });

    onMounted(async () => {
      const gmoScript = document.createElement("script");
      gmoScript.setAttribute("src", props.config.endpointUrl);
      document.head.appendChild(gmoScript);
    });

    const useGmoPayment = async () => {
      if (!cardId.value && !isValid()) {
        return false;
      }

      emit('beforeSavePayment');
      try {
        if (selectedPaymentMethodStore.selectedPaymentMethodCode.startsWith(CC_VAULT_CODE)) {
          const data = await save({
            paymentMethod: {
              code: CC_VAULT_CODE,
              np_gmo_payment_credit_card_vault: {
                public_hash: props.config.paymentDetails?.publicHash,
                cc_masked_card_no: props.config.paymentDetails?.maskCardNumber,
                cc_method: form.value.method?.code,
                cc_pay_times: form.value.payTimes?.code,
                cc_type: props.config.paymentDetails?.type
              }
            }
          });
          emit('afterSavePayment', data);
        } else {
          // @ts-ignore
          Multipayment.init(props.config.shopId);

          // @ts-ignore
          Multipayment.getToken({
            cardno: form.value.cardNumber?.replace(/\s/g, ''),
            expire: cardExpiry.value,
            securitycode: form.value.cardCvc,
            holdername: '',
            tokennumber: "2"
          }, async (result) => {
            if (parseInt(result.resultCode) !== 0) {
              emit('afterSavePayment', false);
            }

            cardToken.value = result.tokenObject?.token?.[0];
            cardTokenForVault.value = result.tokenObject?.token?.[1];
            maskedCardNumber.value = result.tokenObject?.maskedCardNo;

            const data = await save({
              paymentMethod: {
                code: CC_CODE,
                np_gmo_payment_credit_card: {
                  cc_cid: form.value.cardCvc,
                  cc_exp_month: form.value.cardExpiryMonth?.code?.padStart(2, '0'),
                  cc_exp_year: form.value.cardExpiryYear?.code,
                  cc_masked_card_no: maskedCardNumber.value,
                  cc_method: form.value.method?.code,
                  cc_pay_times: form.value.payTimes?.code,
                  cc_token: cardToken.value,
                  cc_token_for_vault: cardTokenForVault.value,
                  cc_type: CC_BRAND[cardBrand.value] ?? '',
                  is_active_payment_token_enabler: form.value.isSave
                }
              }
            });
            emit('afterSavePayment', data);
          });
        }
      } catch (e) {
        console.error(e)
      }
    }

    const isValid = () => {
      validCcNumber();
      validExpiryMonth();
      validExpiryYear();
      validCcCVC();
      return Object.values(cardErrors.value).every(value => {
        return value === null;
      });
    }

    const validCcNumber = () => {
      cardErrors.value.cardNumber = null;
      if (!form.value.cardNumber) {
        cardErrors.value.cardNumber = i18n.t("Please enter a valid number in this field.");
      } else if (!props.config.isDebug && !cardFormat.validateCardNumber(form.value.cardNumber)){
        cardErrors.value.cardNumber = i18n.t("Please enter a valid credit card number.");
      } else if (cardBrandIcon.value == null) {
        cardErrors.value.cardNumber = i18n.t("Please enter a valid credit card type number.");
      }
    }

    const validCcCVC = () => {
      cardErrors.value.cardCvc = null;
      if (!form.value.cardCvc) {
        cardErrors.value.cardCvc = i18n.t("Please enter a valid number in this field.");
      } else if (!cardFormat.validateCardCVC(form.value.cardCvc)){
        cardErrors.value.cardCvc = i18n.t("Please enter a valid credit card verification number.");
      }
    }

    const validExpiryMonth = () => {
      cardErrors.value.expiryMonth = null;
      if (!form.value.cardExpiryMonth) {
        cardErrors.value.expiryMonth = i18n.t("This is a required field.");
      } else if (form.value.cardExpiryMonth?.code && form.value.cardExpiryYear?.code) {
        if (!cardFormat.validateCardExpiry(form.value.cardExpiryMonth?.code, form.value.cardExpiryYear?.code)) {
          cardErrors.value.expiryMonth = i18n.t("Incorrect credit card expiration date.");
        }
      }
    }

    const validExpiryYear = () => {
      cardErrors.value.expiryYear = null;
      if (!form.value.cardExpiryYear) {
        cardErrors.value.expiryYear = i18n.t("This is a required field.");
      } else if (form.value.cardExpiryMonth?.code && form.value.cardExpiryYear?.code) {
        if (!cardFormat.validateCardExpiry(form.value.cardExpiryMonth?.code, form.value.cardExpiryYear?.code)) {
          cardErrors.value.expiryMonth = i18n.t("Incorrect credit card expiration date.");
        }
      }
    }

    const getCardIcon = () => {
      if (availableCcTypes.value && cardBrand.value) {
        const brand = CC_BRAND[cardBrand.value] ?? '';
        const ccType = availableCcTypes.value.find((type) => type.code == brand);
        return ccType ? ccType.icon : null;
      }
      return null;
    }

    const onSaveCardChange = () => {
      form.value.isSave = !form.value.isSave;
    };

    return {
      form,
      cardErrors,
      cardBrandIcon,
      validCcNumber,
      validExpiryMonth,
      validExpiryYear,
      cardFormat,
      methodOptions,
      payTimesOptions,
      monthOptions,
      yearOptions,
      isShowPayTimes,
      cvvImageUrl,
      onSaveCardChange,
      useGmoPayment,
      cardBrand,
      cardId,
      isAuthenticated
    };
  },
});
