



















































































































































































import { SfInput, SfTextarea, SfSelect } from '@storefront-ui/vue';
import {
  ref,
  defineComponent,
  computed,
  onMounted
} from '@nuxtjs/composition-api';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { useContact } from "~/diptyqueTheme/composable";
import { useConfig } from "~/composables";
import { useUser } from '~/modules/customer/composables/useUser';

extend('email', {
  ...email,
  message: 'Invalid email address',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'Contact',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    SfInput,
    SfTextarea,
    SfSelect,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { config } = useConfig();
    const { sendContactInformation, loading } = useContact();
    const { isAuthenticated, user } = useUser();
    const objectOptions = computed(() => {
      return config.value.contactus_object_options ? JSON.parse(config.value.contactus_object_options) : [];
    })
    const form = ref({
      lastname: null,
      firstname: null,
      email: null,
      mobile: null,
      object: null,
      orderId: null,
      comment: null
    });

    const fillTheForm = () => {
      if (isAuthenticated) {
        setTimeout(() => {
          form.value.lastname = user.value.lastname;
          form.value.firstname = user.value.firstname;
          form.value.email = user.value.email;
          form.value.mobile = user.value.mobile;
        }, 500);
      }
    }

    onMounted(() => {
      fillTheForm();
    });

    const resetForm = () => {
      Object.keys(form.value).forEach((key) => {
        form.value[key] = null;
      })
      fillTheForm();
    }

    const sendContact = (reset: () => void) => async () => {
      let success = await sendContactInformation(form.value);
      if (success) {
        resetForm();
        reset();
      }
    };

    return {
      sendContact,
      objectOptions,
      form,
      config,
      loading
    };
  },
});
