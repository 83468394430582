var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfTabs',{staticClass:"tab-orphan",attrs:{"open-tab":1}},[_c('SfTab',{attrs:{"title":_vm.$t('My wishlist')}},[(_vm.loading)?_c('div',[_c('SfLoader')],1):(_vm.products && _vm.products.length > 0)?_c('div',[_c('div',{staticClass:"main section"},[_c('SfLoader',{class:{ loading: _vm.loading },attrs:{"loading":_vm.loading}},[(!_vm.loading)?_c('div',{staticClass:"products"},[_c('transition-group',{staticClass:"products__grid",attrs:{"appear":"","name":"products__slide","tag":"div"}},_vm._l((_vm.products),function(product,i){return _c('SfProductCard',{directives:[{name:"e2e",rawName:"v-e2e",value:('wishlist-product-card'),expression:"'wishlist-product-card'"}],key:_vm.productGetters.getSlug(product.product),staticClass:"products__product-card",style:({ '--index': i }),attrs:{"image-tag":"nuxt-img","image-width":_vm.imageSizes.productCard.width,"image-height":_vm.imageSizes.productCard.height,"image":_vm.getMagentoImage(
                    _vm.productGetters.getProductThumbnailImage(product.product)
                  ),"nuxt-img-config":{
                  fit: 'cover',
                },"is-added-to-cart":_vm.isInCart(product.product),"link":_vm.localePath(
                    ("/p/" + (_vm.productGetters.getProductSku(
                      product.product
                    )) + (_vm.productGetters.getSlug(
                      product.product,
                      product.product.categories[0]
                    )))
                  ),"regular-price":_vm.$fc(_vm.productGetters.getPrice(product.product).regular),"reviews-count":_vm.productGetters.getTotalReviews(product.product),"score-rating":_vm.productGetters.getAverageRating(product.product),"special-price":_vm.productGetters.getPrice(product.product).special &&
                    _vm.$fc(_vm.productGetters.getPrice(product.product).special),"title":_vm.productGetters.getName(product.product),"wishlist-icon":"","is-in-wishlist":"","show-add-to-cart-button":"","add-to-cart-disabled":_vm.isCartLoading},on:{"click:wishlist":function($event){return _vm.removeItemFromWishlist(product.product)},"click:add-to-cart":function($event){return _vm.addItemToCart({ product: product.product, quantity: 1 })}}})}),1),_vm._v(" "),_c('div',{staticClass:"products__display-opt"},[_c('LazyHydrate',{attrs:{"on-interaction":""}},[(!_vm.loading)?_c('SfPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.pagination.totalPages > 1),expression:"pagination.totalPages > 1"}],staticClass:"products__pagination",attrs:{"current":_vm.pagination.currentPage,"total":_vm.pagination.totalPages,"visible":5}}):_vm._e()],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pagination.totalPages > 1),expression:"pagination.totalPages > 1"}],staticClass:"products__show-on-page"},[_c('span',{staticClass:"products__show-on-page__label"},[_vm._v("\n                  "+_vm._s(_vm.$t('Show on page'))+"\n                ")]),_vm._v(" "),_c('LazyHydrate',{attrs:{"on-interaction":""}},[_c('SfSelect',{staticClass:"products__items-per-page",attrs:{"value":_vm.pagination.itemsPerPage.toString()},on:{"input":_vm.th.changeItemsPerPage}},_vm._l((_vm.pagination.pageOptions),function(option){return _c('SfSelectOption',{key:option,staticClass:"products__items-per-page__option",attrs:{"value":option}},[_vm._v("\n                      "+_vm._s(option)+"\n                    ")])}),1)],1)],1)],1)],1):_vm._e()])],1)]):_c('EmptyWishlist')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }