<template>
  <div>
    <VaimoOrnement variant="single-line" class="mb-[50px]" />
    <VaimoHeading
      heading-level="h2"
      heading-style="emphasized"
      :heading="heading"
      :heading-link="headingLink"
      class="ecom-benefits-heading"
    />
    <div
      v-if="carouselItems.length"
      v-touch:moving="mouseMoving"
      v-touch:start="startDrag"
      v-touch:end="stopDrag"
      class="ecom-benefits-carousel"
    >
      <div class="move" :style="wrapperStyles">
        <div class="slide-track" :style="trackStyles">
          <div
            v-for="(carouselItem, index) in carouselItems"
            :key="index"
            class="slide-item"
          >
            <VaimoBanner
              v-if="carouselItem.image"
              :src="carouselItem.image"
              :title="carouselItem.title"
              ratio="4:5"
              convert-to-svg
              class="service-img"
              :max-optimized-width="100"
              :link="carouselItem.link"
              :is-external-link="
                carouselItem.link && carouselItem.openLinkInANewTab
              "
            />
            <component
              :is="getLabelComponent(carouselItem)"
              v-if="carouselItem.title"
              v-bind="getLabelComponentData(carouselItem)"
              :class="{ 'font-light': isZhHkStore }"
              class="label"
            >
              {{ carouselItem.title }}
            </component>
          </div>
        </div>
      </div>
      <div v-if="isMobile" class="move opposite" :style="wrapperStylesOpposite">
        <div class="slide-track" :style="trackStyles">
          <div
            v-for="(carouselItem, index) in carouselItems"
            :key="index"
            class="slide-item"
          >
            <VaimoBanner
              v-if="carouselItem.image"
              :src="carouselItem.image"
              :title="carouselItem.title"
              ratio="4:5"
              class="service-img"
              :max-optimized-width="100"
              :link="carouselItem.link"
              :is-external-link="
                carouselItem.link && carouselItem.openLinkInANewTab
              "
            />
            <component
              :is="getLabelComponent(carouselItem)"
              v-if="carouselItem.title"
              v-bind="getLabelComponentData(carouselItem)"
              :class="{ 'font-light': isZhHkStore }"
              class="label"
            >
              {{ carouselItem.title }}
            </component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  useFetch,
  ssrRef
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { useMediaQuery } from '@vueuse/core';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import { useConfig } from '~/composables';
import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'VaimoEcomBenefits',
  components: {
    SfLink: () => import('@storefront-ui/vue').then((m) => m.SfLink),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoOrnement: () => import('atoms/VaimoOrnement.vue')
  },
  setup() {
    const { query, queryResponse } = useContentfulGQL('footerEcomBenefits');
    const { config } = useConfig();
    const { normalizeLink } = useLink();

    const wrapperStyles = ref('');
    const wrapperStylesOpposite = ref('');
    const dragging = ref(false);
    const xOffSet = ref(0);
    const stopXOffSet = ref(0);
    const initialMouseX = ref(0);
    const interval = ref(null);
    const translateX = ref(0);
    const mobileWidth = 96;
    const fallbackLength = 5;
    const treshold = computed(() => {
      return mobileWidth * (items.value.length ?? fallbackLength);
    });

    const isZhHkStore = config?.value.store_code === 'zh_hk';

    const isScreenS = useMediaQuery('(max-width: 1023px)');
    const isMobile = computed(() => {
      return useMediaQuery('(max-width: ' + treshold.value + 'px)').value;
    });

    const heading = ssrRef('');
    const headingLink = ssrRef('');
    const items = ssrRef([]);

    const trackStyles = computed(() => {
      return {
        minWidth: `${treshold.value}px`
      };
    });

    useFetch(async () => {
      await query('getFlexibleSectionBySid', { sid: 'footer-ecom-benefits' });
      const entry = queryResponse.value?.flexibleCollection?.items?.[0];
      heading.value = entry?.heading ?? '';
      headingLink.value = entry?.headingLink ?? '';
      items.value = entry?.itemsCollection?.items ?? [];
    });

    const carouselItems = computed(() => {
      return items.value
        .filter((i) => i)
        .map((i) => {
          return {
            title: i?.title,
            image: i?.image?.url,
            link: i?.link,
            openLinkInANewTab: i?.linkBehaviour
          };
        });
    });

    const getLabelComponent = (item) =>
      item.link && item.link !== '#' ? 'SfLink' : 'span';

    const incrementXOffSet = () => {
      handleTreshold(xOffSet.value - ((1 / 60) * 100) / 20);
    };

    onMounted(() => {
      if (isMobile.value) {
        interval.value = setInterval(incrementXOffSet, 10);
      }
    });

    const startDrag = (e) => {
      if (!isMobile.value) {
        return;
      }
      clearInterval(interval.value);
      dragging.value = true;
      initialMouseX.value = e.changedTouches[0].pageX;
      stopXOffSet.value = xOffSet.value;
    };
    const stopDrag = () => {
      if (!isMobile.value) {
        return;
      }
      dragging.value = false;
      const newXOffSet = xOffSet.value < 0 ? xOffSet.value * -1 : xOffSet.value;
      if (newXOffSet > treshold.value) {
        stopXOffSet.value = 0;
        xOffSet.value = 0;
      } else {
        stopXOffSet.value = xOffSet.value;
      }
      interval.value = setInterval(incrementXOffSet, 10);
    };
    const mouseMoving = (e) => {
      if (!isMobile.value) {
        return;
      }
      if (dragging.value) {
        const dragAmount = e.changedTouches[0].pageX - initialMouseX.value;
        handleTreshold(stopXOffSet.value + dragAmount);
      }
    };
    const handleTreshold = (xOffset) => {
      const isNegative = xOffset < 0;
      const newXOffSet = isNegative ? xOffset * -1 : xOffset;
      if (newXOffSet > treshold.value) {
        if (isNegative) {
          xOffSet.value = xOffset + treshold.value;
        } else {
          xOffSet.value = xOffset - treshold.value;
        }
      } else {
        xOffSet.value = xOffset;
      }
    };

    watch(xOffSet, (newXOffSet) => {
      wrapperStyles.value = `transform: translateX(${newXOffSet}px) translateZ(0px);`;
      wrapperStylesOpposite.value = `transform: translateX(${
        newXOffSet < 0
          ? newXOffSet + treshold.value
          : (treshold.value - newXOffSet) * -1
      }px) translateZ(0px);`;
    });

    watch(isMobile, (newIsMobile) => {
      if (newIsMobile) {
        clearInterval(interval.value);
        interval.value = setInterval(incrementXOffSet, 10);
      } else {
        clearInterval(interval.value);
        wrapperStyles.value = '';
        wrapperStylesOpposite.value = '';
        stopXOffSet.value = 0;
        xOffSet.value = 0;
      }
    });

    const getLabelComponentData = ({ link, openLinkInANewTab, title }) => {
      if (!link || link === '#') return;

      return {
        link: normalizeLink(link),
        target: openLinkInANewTab ? '_blank' : '_self',
        'aria-label': title
      };
    };

    return {
      carouselItems,
      isZhHkStore,
      addBasePath,
      isMobile,
      startDrag,
      stopDrag,
      mouseMoving,
      wrapperStyles,
      wrapperStylesOpposite,
      translateX,
      xOffSet,
      isScreenS,
      stopXOffSet,
      heading,
      headingLink,
      trackStyles,
      getLabelComponent,
      getLabelComponentData
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/diptyqueTheme/assets/styles/organisms/VaimoEcomBenefits';
</style>
