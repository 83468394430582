
















































import {
  SfList,
  SfPrice,
  SfButton,
  SfQuantitySelector,
  SfImage,
  SfAlert
} from '@storefront-ui/vue';
import {
  computed,
  watch,
  ref,
  defineComponent,
  PropType
} from '@nuxtjs/composition-api';

import {
  getGroupedProducts,
  getName as getProductName,
  getPrice as getProductPrice,
  getProductThumbnailImage
} from '~/modules/catalog/product/getters/productGetters';

import { getGroupedProductPriceCommand } from '~/modules/catalog/pricing/getGroupedProductPriceCommand';

import { useImage } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import type { WithTypename } from '~/modules/catalog/product/types';
import type { GroupedProduct } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'GroupedProductSelector',
  components: {
    SfList,
    SfPrice,
    SfButton,
    SfQuantitySelector,
    SfImage,
    SfAlert
  },
  props: {
    canAddToCart: {
      type: Boolean,
      required: false,
      default: true
    },
    product: {
      type: Object as PropType<WithTypename<GroupedProduct>>,
      required: true
    }
  },
  emits: ['update-price'],
  setup(props, { emit }) {
    const { addItem, error: cartError } = useCart();
    const loading = ref(false);
    const groupedItems = computed(() => getGroupedProducts(props.product));
    const addToCart = async () => {
      try {
        loading.value = true;

        const groupedItemsFiltered = groupedItems.value.filter((p) => p.qty);
        if (groupedItemsFiltered.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const p of groupedItemsFiltered) {
            // eslint-disable-next-line no-await-in-loop
            await addItem({ product: p.product, quantity: p.qty });
            if (cartError.value.addItem) {
              break;
            }
          }
        }
        loading.value = false;
      } catch {
        loading.value = false;
      }
    };

    watch(
      () => props.product,
      (productNewValue) => {
        const price = getGroupedProductPriceCommand(productNewValue);
        emit('update-price', price);
      },
      {
        deep: true
      }
    );

    const { getMagentoImage } = useImage();
    const addToCartError = computed(() => cartError.value?.addItem?.message);

    return {
      addToCart,
      groupedItems,
      loading,
      getMagentoImage,
      getProductName,
      getProductPrice,
      getProductThumbnailImage,
      addToCartError
    };
  }
});
