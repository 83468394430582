







































import { computed, defineComponent, useRouter } from '@nuxtjs/composition-api';
import VaimoSectionTemplate from 'molecules/VaimoSectionTemplate.vue';
import { useLink } from "~/diptyqueTheme/composable";

export default defineComponent({
  name: 'ItemMajor',
  components: {
    VaimoSectionTemplate,
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const data = computed(() => props.item?.data || {});
    const { normalizeLink } = useLink();
    const router = useRouter();

    const redirectToArticle = () => {
      data.value.ctaLinkLink && router.push(normalizeLink(data.value.ctaLinkLink))
    }

    return {
      data,
      normalizeLink,
      redirectToArticle
    };
  }
});
