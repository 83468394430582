
























































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';

export default defineComponent({
  setup() {
    const { i18n } = useContext();
    const { config } = useConfig();

    const termsOfServiceData = computed(() => {
      return termsOfServiceLinks[i18n.locale];
    });

    const getCompanyInformationText = () => {
      const specificStoreMap = {
        fr_eu: i18n.t('legal.companyInformationFR_EU'),
        en_eu: i18n.t('legal.companyInformationEN_EU')
      };

      return (
        specificStoreMap[config.value.store_code] ||
        i18n.t('legal.companyInformation')
      );
    };

    const isSpecificStore = computed(() => {
      return ['en_us', 'fr_us', 'ja_jp', 'en_hk', 'zh_hk'].includes(
        config.value.store_code
      );
    });

    return {
      termsOfServiceData,
      isSpecificStore,
      getCompanyInformationText
    };
  }
});
