
































































































































import {
  defineComponent,
  ref,
  computed,
  useContext,
  onMounted,
  nextTick
} from '@nuxtjs/composition-api';
import { useConfig, useUiNotification } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import { SfRadio } from '@storefront-ui/vue';
import { useApi } from '~/composables/useApi';
import { Logger } from '~/helpers/logger';
import setGiftOptionsOnCart from '~/diptyqueTheme/customQueries/magento/setGiftOptionsOnCart';
import { useCartStore } from '~/modules/checkout/stores/cart';

interface GiftWrapping {
  uid: string;
  is_default: boolean;
  image: { url: string };
  description: string;
}

export default defineComponent({
  name: 'VaimoGiftWrapping',
  components: {
    SfRadio,
    VaimoBanner: () => import('atoms/VaimoBanner.vue')
  },
  emits: ['updateGiftWrap'],
  setup(_, { emit }) {
    const { config: storeConfig } = useConfig();
    const { cart, load } = useCart();
    const { send: sendNotification } = useUiNotification();
    const defaultOptionName = 'default';
    const listOptionName = 'list';
    const selectedOption = ref(defaultOptionName);
    const giftWrappingSectionTitle = storeConfig.value?.sales_gift_options_gw_section_title;
    const { mutate } = useApi();
    const { app } = useContext();
    const defaultGiftBox = ref(null);
    const selectedGiftBox = ref(null);
    const loading = ref(false);
    const cartStore = useCartStore();
    const description = ref(null);

    // Allows gift wrapping at the order level. Possible values: 1 (Yes) and 0 (No).
    // Configuration path: sales/gift_options/allow_order
    const isGiftWrappingAllowed = computed(() => {
      if (cart.value.is_virtual) {
        return false;
      }
      return storeConfig.value?.allow_order === '1' || false;
    });

    const savedWrapping = computed(() => {
      const cartValue = cart.value;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!cartValue || !cartValue.gift_wrapping) {
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return cartValue.gift_wrapping;
    });

    const giftBoxes = computed((): GiftWrapping[] => {
      const cartValue = cart.value;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!cartValue || !cartValue.available_gift_wrappings) {
        return [];
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return cartValue.available_gift_wrappings.filter((giftWrapping) => !giftWrapping.is_default);
    });

    const setChosenGiftWrapping = () => {
      const cartValue = cart.value;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (savedWrapping.value && cartValue && cartValue.available_gift_wrappings) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const savedWrappingObj = cartValue.available_gift_wrappings.find(
          (giftWrapping) => giftWrapping.uid === savedWrapping.value.uid
        );

        if (!savedWrappingObj) {
          return false;
        }

        if (savedWrappingObj.is_default) {
          selectedOption.value = defaultOptionName;
          defaultGiftBox.value = savedWrappingObj;

          if (giftBoxes.value.length !== 0) {
            selectedGiftBox.value = giftBoxes.value?.[0];
          }
        } else {
          // The next line is commented out for a while, at the request of the Diptyque team, until gift boxes of other colors appear.
          // selectedOption.value = listOptionName;
          // Remove the next line when the previous line is uncommented
          selectedOption.value = savedWrappingObj.uid;

          selectedGiftBox.value = savedWrappingObj;
        }

        savedWrappingObj && updateGiftWrapping(savedWrappingObj);

        return selectedOption.value;
      }

      return false;
    };

    const setDefaultGiftWrapping = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (cart.value?.available_gift_wrappings) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const defaultGiftWrap = cart.value.available_gift_wrappings.find((giftWrapping) => giftWrapping.is_default) || false;
        defaultGiftBox.value = defaultGiftWrap;
      }

      if (savedWrapping.value) {
        setChosenGiftWrapping();
      } else {
        defaultGiftBox.value && updateGiftWrapping(defaultGiftBox.value);

        if (giftBoxes.value.length !== 0) {
          selectedGiftBox.value = giftBoxes.value?.[0];
        }
      }
      description.value = defaultGiftBox?.value?.description ?? '';
      return defaultGiftBox.value
        ? defaultGiftBox.value
        : selectedGiftBox.value;
    };

    const getImage = (giftWrapp) => {
      if (!giftWrapp?.image?.url) {
        return false;
      }

      return giftWrapp.image.url;
    };

    const setGiftBox = (option) => {
      selectedGiftBox.value = option;
      updateGiftWrapping(option);
    };

    const setOption = (optionName, giftBox) => {
      selectedOption.value = optionName;
      description.value = giftBox.description;
      defaultGiftBox.value && updateGiftWrapping(giftBox);
    };

    const updateGiftWrapping = async (giftWrapping) => {
      emit('updateGiftWrap', giftWrapping);
      const cartId = cart.value?.id;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      cartStore.cart.gift_wrapping = giftWrapping;
      loading.value = true;

      try {
        loading.value = true;
        await mutate(setGiftOptionsOnCart, {
          cartId,
          gift_wrapping: giftWrapping.uid
        });
      } catch (err) {
        Logger.error('updateGiftWrapping', err);
        sendNotification({
          id: Symbol('gift_wrapping_update_error'),
          message: err.message,
          type: 'danger',
          persist: false,
          title: app.i18n.t('Something went wrong!') as string
        });
      } finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      if (!cart.value.id) {
        await load();
      }

      nextTick(() => {
        setDefaultGiftWrapping();
      });
    });

    return {
      description,
      defaultOptionName,
      listOptionName,
      isGiftWrappingAllowed,
      giftBoxes,
      selectedOption,
      defaultGiftBox,
      setDefaultGiftWrapping,
      getImage,
      setOption,
      setGiftBox,
      selectedGiftBox,
      updateGiftWrapping,
      savedWrapping,
      setChosenGiftWrapping,
      giftWrappingSectionTitle
    };
  }
});
