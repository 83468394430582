

























































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'ArticleSectionDesktopOneItem',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    Labels: () => import('./Labels.vue')
  },
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const getItem = computed(() => {
      const item = props.data.articlesCollection?.items?.[0] || {};
      item.ctaLink = normalizeLink(item.ctaLink);
      return item;
    });

    return {
      getItem
    };
  }
});
