




















































import {
  computed,
  defineComponent,
  PropType,
  useContext
} from '@nuxtjs/composition-api';

import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { CategoryInterface } from '~/modules/catalog/category/types';
import productGetters, {
  getPrice
} from '~/modules/catalog/product/getters/productGetters';
import { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'BoxCompositionCard',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue')
  },
  props: {
    product: {
      required: true,
      type: [Object, null] as PropType<Product>
    }
  },
  setup(props) {
    const app = useContext();
    const getProductNameAndDescription = computed(() => {
      return {
        name: props.product?.name || '',
        description: props?.product?.plp_description || ''
      };
    });

    const checkCtfItemButtons = computed(() => {
      //@ts-ignore
      return props.product?.ctfProductItem?.buttonCollection?.items?.length;
    });

    const isDynamicPrice = computed(
      () => props?.product?.price_type === 'DYNAMIC'
    );

    const getMinimalDynamicPrice = computed(
      () => props.product?.custom_set_minimal_price
    );

    const getDynamicPriceText = (price) => {
      return app.i18n.t('From {price}', {
        price
      });
    };

    const getProductPrice = (product) => {
      if (product) {
        return getPrice(product).regular || 0;
      }
    };

    const getProductLink = (product) => {
      const link = `${productGetters.getSlug(
        product as Product,
        product?.categories?.[0] as CategoryInterface
      )}`;

      return app.localePath(link);
    };

    const getProductHoverImage = (product) => {
      if (!product || !product['hover_on_card']) {
        return null;
      }
      return product['hover_on_card'];
    };

    return {
      getProductNameAndDescription,
      isDynamicPrice,
      getDynamicPriceText,
      getMinimalDynamicPrice,
      getProductHoverImage,
      checkCtfItemButtons,
      getProductLink,
      validateFraction,
      getProductPrice
    };
  }
});
