









import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useScreenSize } from '~/diptyqueTheme/composable';
import type {
  CollectionPushModel,
  FlexibleSectionModel,
  ProductGroup
} from '~/diptyqueTheme/types/contentful';
// eslint-disable-next-line no-duplicate-imports
import {
  ProductsLayout,
  VisualPosition
} from '~/diptyqueTheme/types/contentful';

export default defineComponent({
  name: 'CollectionPush',
  components: {
    DesktopFullWidth: () => import('./declinations/DesktopFullWidth.vue'),
    DesktopSide: () => import('./declinations/DesktopSide.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object as PropType<FlexibleSectionModel | CollectionPushModel>
    }
  },

  setup(props) {
    const visualPositionMap = {
      'Option 1 - Slider below': VisualPosition.TOP,
      'Option 2 - Slider on the right': VisualPosition.LEFT,
      'Option 3 - Slider on the left': VisualPosition.RIGHT
    };

    const adaptedData = computed<FlexibleSectionModel>(() => {
      if (props.sectionData.__typename === 'Flexible') {
        return props.sectionData as FlexibleSectionModel;
      }

      const collectionPushData = props.sectionData as CollectionPushModel;

      return {
        ...props.sectionData,
        eTitle: collectionPushData.etitle,
        ...(collectionPushData.button && {
          buttonCollection: { items: [collectionPushData.button] }
        }),
        ...(collectionPushData.productGroup && {
          itemsCollection: { items: [collectionPushData.productGroup] }
        }),
        visual: {
          vis: collectionPushData.visual,
          visD: collectionPushData.visualDesktop,
          pos: VisualPosition.DEFAULT,
          posD: collectionPushData.dVar
            ? visualPositionMap[collectionPushData.dVar]
            : VisualPosition.DEFAULT,
          vidSet: collectionPushData.vSettings
        },
        productDescriptionSettings: collectionPushData.descrSettings
      } as FlexibleSectionModel;
    });

    const componentsMap = {
      [VisualPosition.DEFAULT]: 'DesktopFullWidth',
      [VisualPosition.TOP]: 'DesktopFullWidth',
      [VisualPosition.LEFT]: 'DesktopSide',
      [VisualPosition.RIGHT]: 'DesktopSide'
    };

    const componentName = computed(() => {
      const position = adaptedData.value?.visual?.posD;

      return componentsMap[position];
    });

    const { isDesktop } = useScreenSize();

    const layoutsMap = {
      [ProductsLayout.DEFAULT]: 'slider',
      [ProductsLayout.CAROUSEL]: 'slider',
      [ProductsLayout.GRID]: 'grid'
    };

    const productsData = computed(() => {
      const item: ProductGroup =
        adaptedData.value?.itemsCollection?.items[0] || ({} as ProductGroup);

      return {
        listOfSkus: item.sku || [],
        layout: isDesktop.value ? 'slider' : layoutsMap[item.layout] || 'slider'
      };
    });

    return {
      componentName,
      productsData,
      adaptedData
    };
  }
});
