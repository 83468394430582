








import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import AddToCartNotification from '@/diptyqueTheme/components/AddToCartNotification.vue';
import InfoNotification from '@/diptyqueTheme/components/InfoNotification.vue';
import { useUiNotification } from '~/composables';
import type { UiNotification } from '~/composables/useUiNotification/useUiNotification';

interface AreaInterface {
  area: 'top' | 'default';
}
export default defineComponent({
  name: 'NotificationBar',
  components: {
    AddToCartNotification,
    InfoNotification
  },
  props: {
    area: {
      required: false,
      type: String as PropType<AreaInterface['area']>,
      default: 'default' as AreaInterface['area']
    }
  },
  setup() {
    const { notifications } = useUiNotification();

    const notificationsByArea = computed(() => {
      const categorizedNotifications: {
        top: UiNotification[];
        default: UiNotification[];
      } = {
        top: [],
        default: []
      };

      notifications.value.forEach((notification) => {
        if (notification.action_type === 'add_to_cart') {
          notification.area = 'default';
        } else {
          notification.area = 'top';
        }

        categorizedNotifications[notification.area].push(notification);
      });

      return categorizedNotifications;
    });

    const hasNotifications = (area: AreaInterface['area']) => {
      return Object.keys(notificationsByArea.value[area]).length > 0;
    };

    return {
      notifications,
      notificationsByArea,
      hasNotifications
    };
  }
});
