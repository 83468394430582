





























































































































import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfList } from '@storefront-ui/vue';

import { useConfig, useStore } from '~/composables';
import { useThemeUiState } from '~/diptyqueTheme/composable/useThemeUiState';

export default defineComponent({
  name: 'VaimoAvailableStores',
  components: {
    SfList,
    VaimoHeading: () => import('atoms/VaimoHeading.vue')
  },
  props: {
    showBackButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const { handleStoreSwitchAndRedirect, checkForAmericanStore } = useStore();
    const {
      config: {
        value: { store_code }
      }
    } = useConfig();
    const { app: { i18n: { locales } } = { i18n: { locales: [] } } } =
      useContext();
    const {
      app: { $gtm }
    } = useContext();
    const { closeMobileStoreSwitcher } = useThemeUiState();
    const continentsList = [
      {
        id: 'NA',
        label: 'North America'
      },
      {
        id: 'EU',
        label: 'Europe'
      },
      {
        id: 'AS',
        label: 'Asia'
      }
    ];

    const isWebsiteLocal = (store, index, localesArray) => {
      return (
        localesArray.findIndex((orgStore) => {
          return orgStore.websiteName === store.websiteName;
        }) === index
      );
    };

    const availableStoresByContinentId = (continentId) => {
      const getWebsiteNames = locales
        .filter(isWebsiteLocal)
        .map((store) => store.websiteName);

      return getWebsiteNames.map((websiteName) => {
        return {
          websiteName: websiteName,
          stores: locales.filter((store) => {
            return (
              store.websiteName === websiteName &&
              store.continent === continentId
            );
          })
        };
      });
    };

    const getClickChangeLanguageDetails = (store) => {
      $gtm.push({
        event: 'clickChangeLanguage',
        action: 'Language',
        country: store.websiteName
      });
    };

    return {
      availableStoresByContinentId,
      currentStoreCode: store_code,
      handleStoreSwitchAndRedirect,
      closeMobileStoreSwitcher,
      continentsList,
      getClickChangeLanguageDetails,
      checkForAmericanStore
    };
  }
});
