








































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfProperty } from '@storefront-ui/vue';

import { useConfig } from '~/composables';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';

export default defineComponent({
  name: 'CheckoutTotals',
  components: {
    SfProperty,
    Taxes: () => import('~/diptyqueTheme/modules/checkout/components/Taxes.vue'),
    Fees: () => import('~/diptyqueTheme/modules/checkout/components/Fees.vue'),
    CodFee: () => import('~/diptyqueTheme/modules/checkout/components/CodFee.vue'),
  },
  props: {
    onlyTotal: Boolean,
    showAll: Boolean,
    withShippingAndSubTotal: Boolean
  },
  setup() {
    const { cart } = useCart();
    const { app } = useContext();
    const { config } = useConfig();

    const totals = computed(() => cartGetters.getTotals(cart.value));
    const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
    const hasDiscounts = computed(() => Math.abs(discount.value) > 0);
    const selectedShippingMethod = computed(() =>
      cartGetters.getSelectedShippingMethod(cart.value)
    );
    const isTaxDetailsEnabled = computed(
      () =>
        cart.value?.prices?.applied_taxes?.length &&
        (config.value?.tax_display_is_tax_details_display ?? false)
    );
    const isFeesExist = computed(
      () =>
        cart.value?.prices?.fees?.value
    );
    const isCodFeeExist = computed(() => cart.value?.prices?.vw_cod_fee?.value);
    const getGiftWrappingPrice = computed(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (cart.value && cart.value.gift_wrapping && cart.value.gift_wrapping.price) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return cart.value.gift_wrapping.price.value;
      }

      return false;
    });

    const getShippingMethodValue = computed(() => {
      const shippingMethodPrice = getShippingMethodPrice(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        selectedShippingMethod.value
      );

      if (shippingMethodPrice > 0 && !isNaN(shippingMethodPrice)) {
        return validateFraction(shippingMethodPrice);
      }
      return app.i18n.t('Free');
    });

    const getTotalsTotal = computed(() => {
      return totals.value.subtotal && !totals.value.total
        ? app.i18n.t('Free')
        : validateFraction(totals.value.total);
    });

    return {
      totals,
      isTaxDetailsEnabled,
      discount,
      hasDiscounts,
      selectedShippingMethod,
      getGiftWrappingPrice,
      validateFraction,
      getShippingMethodValue,
      getShippingMethodPrice,
      isCodFeeExist,
      isFeesExist,
      getTotalsTotal
    };
  }
});
