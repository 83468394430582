



























import {
  defineComponent,
  onMounted,
  ref,
  toRef,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import { useUiHelpers } from '~/composables';
// eslint-disable-next-line max-len
import { getCustomFiltersByCategoryIdCommand } from '~/diptyqueTheme/modules/catalog/category/customFilters/command/getCustomFiltersByCategoryIdCommand';
import { useFilters } from '~/modules/catalog/category/components/filters/useFilters';

export default defineComponent({
  name: 'VaimoCustomFilters',
  props: {
    category: {
      required: true,
      type: Object,
      default: () => ({})
    },
    aggregations: {
      required: false,
      default: () => [],
      type: Array
    }
  },
  emits: ['reloadProducts'],
  setup(props, { emit }) {
    const selectedCustomFilterId = ref<string | null>(null);
    const customFilters = ref([]);
    const customFiltersTriggered = ref(null);
    const aggregations = toRef(props, 'aggregations');
    const {
      selectedFilters,
      selectFilter,
      removeFilter,
      getSelectedFiltersFromUrl,
      isFilterSelected,
      clearFilters
    } = useFilters(props.category?.id, aggregations);
    const context = useContext();
    const { changeFilters } = useUiHelpers();
    const isSelected = (filter) => {
      return filter.id === selectedCustomFilterId.value ? 'selected' : '';
    };

    watch(selectedFilters.value, async () => {
      await loadCustomFilters();
      setSelected();
    });

    const clearFiltersFromStorage = () => {
      /**
       * clear filters from storage if it's not present in url
       */
      const filtersFromUrl = getSelectedFiltersFromUrl();
      const selectedFiltersValue = selectedFilters.value;

      for (const selected in selectedFiltersValue) {
        for (const option in selectedFiltersValue[selected]) {
          const urlFilter = filtersFromUrl[selected];

          if (
            typeof urlFilter === 'undefined' ||
            typeof urlFilter[option] === 'undefined'
          ) {
            removeFilter(selected, selectedFiltersValue[selected][option]);
          }
        }
      }
    };

    const setSelected = () => {
      for (const filter of customFilters.value) {
        for (const selectedFilterKey in selectedFilters.value) {
          const selectedOptions = selectedFilters.value[selectedFilterKey];
          const matchingOption = filter.options.find(
            (option) =>
              option.name === selectedFilterKey &&
              selectedOptions.includes(option.value)
          );

          if (matchingOption) {
            selectedCustomFilterId.value = filter.id;
            return;
          }
        }
      }
      const anyFilterHasValues = Object.keys(selectedFilters.value)?.some(
        (filter_key) => selectedFilters.value[filter_key].length
      );
      if (!anyFilterHasValues) {
        selectedCustomFilterId.value = 'default';
      } else {
        selectedCustomFilterId.value = null;
      }
    };

    const loadCustomFilters = async () => {
      customFilters.value = await getCustomFiltersByCategoryIdCommand.execute(
        props.category?.id,
        getSelectedFiltersFromUrl(),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore "getCustomFiltersByCategoryId" dynamically added by custom-middleware
        context.app.$vsf.$magento.api.getCustomFiltersByCategoryId
      );
      const isSeeAllExist = customFilters.value?.find(
        (filter) => filter.id === 'default'
      );
      if (!isSeeAllExist && customFilters.value?.length) {
        /**
         * Add "See all" button
         */
        customFilters.value.unshift({
          __typename: 'CustomFilter',
          active: true,
          id: 'default',
          name: context.app.i18n.t('See all'),
          order: 1,
          options: [{}]
        });
      }
    };

    const setDefaultFilter = async () => {
      clearFilters();
      await changeFilters(selectedFilters.value, false);
      emit('reloadProducts');
    };

    const clearSelectedFilter = (filter) => {
      if (filter?.options) {
        filter.options.forEach(({ name, value }) => {
          removeFilter(name, value);
        });
        customFiltersTriggered.value = false;
      }

      selectedCustomFilterId.value = '';
    };

    const clearSelectedAndApplyCurrentFilter = (filter) => {
      const selectedFilter = customFilters.value.find(
        (customFilter) => customFilter.id === selectedCustomFilterId.value
      );

      if (selectedFilter) {
        selectedFilter.options.forEach((option) => {
          removeFilter(option.name, option.value);
        });
      }

      if (filter?.options) {
        filter.options.forEach((option) => {
          selectFilter(
            { attribute_code: option.name },
            { value: option.value }
          );
        });

        customFiltersTriggered.value = true;
        selectedCustomFilterId.value = filter.id;
      }
    };

    const applyCurrentFilter = (filter) => {
      if (filter?.options) {
        filter.options.forEach(({ name, value }) => {
          if (!isFilterSelected(name, value)) {
            selectFilter({ attribute_code: name }, { value });
          }
        });

        selectedCustomFilterId.value = filter.id;
        customFiltersTriggered.value = true;
      }
    };

    const applyCustomFilter = async (filter) => {
      if (filter.id === 'default') {
        await setDefaultFilter();
        return;
      }

      if (!filter.active) {
        return;
      }

      if (filter.id === selectedCustomFilterId.value) {
        // clear selected filter
        clearSelectedFilter(filter);
      } else {
        if (selectedCustomFilterId.value !== null) {
          // clear selected and apply current filter
          clearSelectedAndApplyCurrentFilter(filter);
        } else {
          // apply current filter
          applyCurrentFilter(filter);
        }
      }

      await changeFilters(selectedFilters.value, false);
      emit('reloadProducts');
    };

    onMounted(async () => {
      clearFiltersFromStorage();
      await loadCustomFilters();
      setSelected();

      if (!Object.keys(selectedFilters.value).length) {
        selectedCustomFilterId.value = 'default';
      }
    });

    return {
      customFilters,
      selectedCustomFilterId,
      selectedFilters,
      applyCustomFilter,
      isSelected
    };
  }
});
