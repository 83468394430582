


































import {defineComponent} from "@nuxtjs/composition-api";
import {SfProperty} from "@storefront-ui/vue";
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';

export default defineComponent({
  name: 'JpSuccessCheckoutTotals',
  components: {
    SfProperty,
  },
  props: {
    totals: {
      type: Object,
      required: true,
      default: () => ({
        discount: null,
        subTotal: null,
        subTotalInclTax: null,
        grandTotal: null,
        tax: null,
        shipping: null,
        shippingInclTax: null,
        vwCodFeeInclTax: null
      }),
    }
  },
  setup(props) {
    return {
      validateFraction
    }
  }
});
