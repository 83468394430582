<template>
  <div class="information-pleats-section">
    <VaimoHeading
      v-if="sectionData.heading"
      :heading="sectionData.heading"
      :heading-link="sectionData.headingLink"
      class="mb-md text-center"
      :aria-label="sectionData.heading"
    />

    <VaimoInformationPleats
      :pleats="pleats"
      @onPleatClick="setPleat"
      :skip-last-border="false"
    />

    <VaimoSidebar
      :visible="isSidebarVisible"
      position="right"
      :overlay="true"
      :title="activePleat && activePleat.key"
      :hide="hideSidebar"
      class="information-pleats-section__sidebar"
    >
      <div
        class="sidebar-pleat-content"
        v-html="$dompurify(activePleatContent)"
      />
    </VaimoSidebar>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  useRouter,
  useRoute
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'InformationPleatsSection',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoSidebar: () => import('organisms/VaimoSidebar.vue'),
    VaimoInformationPleats: () => import('organisms/VaimoInformationPleats.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props, { root }) {
    const $markdown = root?.$markdown;
    const $router = useRouter();
    const $route = useRoute();

    const pleats = computed(() => {
      if (!props.sectionData.itemsCollection) return [];

      return props.sectionData.itemsCollection?.items
        ?.filter((item) => item?.__typename === 'Article')
        .map((item, i) => {
          return {
            key: item.heading,
            value: item.content
          };
        });
    });

    const getPleatFromHash = () => {
      const hash = decodeURIComponent($route.value?.hash?.slice(1));
      return pleats.value.find((pleat) => pleat.key === hash) || {};
    };

    const activePleat = ref(getPleatFromHash());
    const isSidebarVisible = ref(!!activePleat.value?.value);

    const setPleat = (pleat) => {
      $router.push({ hash: pleat.key });
      isSidebarVisible.value = true;
      activePleat.value = pleat;
    };

    const hideSidebar = () => {
      isSidebarVisible.value = false;
      activePleat.value = {};
      setTimeout(() => {
        $router.push({ hash: '' });
      }, 500);
    };

    const activePleatContent = computed(() => {
      return activePleat.value?.value
        ? $markdown.render(activePleat.value.value)
        : '';
    });

    return {
      pleats,
      activePleat,
      activePleatContent,
      setPleat,
      hideSidebar,
      isSidebarVisible
    };
  }
});
</script>

<style scoped lang="scss">
.information-pleats-section {
  @apply px-md;
  @include for-screen-m-and-l {
    @apply mx-auto max-w-5xl;
  }

  .sidebar-pleat-content {
    @apply p-md;

    ::v-deep {
      & > * {
        @apply my-sm;
      }
      h1 {
        @apply paragraph-1em mt-base;
      }
      h2 {
        @apply paragraph-2em mt-base;
      }
      h3 {
        @apply paragraph-3em;
      }
      h4 {
        @apply paragraph-4;
      }
      h5 {
        @apply paragraph-5;
      }
      p {
        @apply paragraph-4;
      }
    }
  }

  ::v-deep {
    .sidebar-aside-close {
      right: 0 !important;
    }
    .sf-sidebar__aside {
      border-left: 1px solid var(--c-gris-nacre) !important;
    }
    .sf-sidebar__title {
      h4 {
        @apply title-h4;
      }
    }
  }
}
</style>
