<template>
  <div class="vaimo-slider" :class="{ 'with-dots': showDots && itemsLength }">
    <VueSlickCarousel
      v-if="itemsLength"
      v-bind="getCarouselSettings"
      ref="carousel"
      :class="[{ 'is-swiping': isSwiping }, ...getCarouselClasses]"
      :aria-hidden="false"
      :fade="fade"
      :arrows="arrows"
      :initial-slide="initialSlide"
      @reInit="toggleFocusOnProduct()"
      @swipe.once="carouselSwiped = true"
      @swipe="setCarouselSwiping(true), toggleFocusOnProduct()"
      @afterChange="emitAfterChange(), toggleFocusOnProduct()"
      @mouseup.native="setCarouselSwiping(false)"
      @touchend.native="setCarouselSwiping(false)"
    >
      <slot />
      <template #prevArrow>
        <div
          role="button"
          tabindex="0"
          :aria-label="$t('Previous slide')"
          :title="$t('Previous slide')"
          @mousemove="moveContextualCursor"
          @click.once="carouselSwiped = true"
          @keypress.enter="carouselSwiped = true"
        >
          <div
            v-if="arrowsType === 'contextual'"
            class="arrows-contextual contextual-cursor"
          >
            <VaimoIcon
              icon="arrowleft"
              :size="36"
              :label="$t('Previous slide')"
            />
          </div>
          <div v-else class="arrows-chevron">
            <VaimoIcon
              icon="chevronleft"
              :size="24"
              :label="$t('Previous slide')"
            />
          </div>
        </div>
      </template>
      <template #nextArrow>
        <div
          role="button"
          tabindex="0"
          :aria-label="$t('Next slide')"
          :title="$t('Next slide')"
          @mousemove="moveContextualCursor"
          @click.once="carouselSwiped = true"
          @keypress.enter="carouselSwiped = true"
        >
          <div
            v-if="arrowsType === 'contextual'"
            class="arrows-contextual contextual-cursor"
          >
            <VaimoIcon icon="arrowright" :size="36" :label="$t('Next slide')" />
          </div>
          <div v-else class="arrows-chevron">
            <VaimoIcon
              icon="chevronright"
              :size="24"
              :label="$t('Next slide')"
            />
          </div>
        </div>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import VaimoIcon from 'atoms/VaimoIcon.vue';

import { isBetween } from '~/diptyqueTheme/helpers/isBetween';

export default defineComponent({
  name: 'VaimoSlider',
  components: {
    VaimoIcon
  },
  props: {
    itemsLength: {
      required: false,
      type: Number,
      default: 0
    },
    adaptiveHeight: {
      required: false,
      type: Boolean,
      default: false
    },
    infinite: {
      type: Boolean,
      required: false,
      default: false
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    autoplaySpeed: {
      type: Number,
      required: false,
      default: 3000
    },
    /* How many percents of the next slide will be shown in the main slider container for Desktop */
    desktopShift: {
      type: Number,
      required: false,
      default: 0,
      validator: (value) => {
        const min = 0;
        const max = 99;
        return isBetween(value, min, max);
      }
    },
    /* How many percents of the next slide will be shown in the main slider container for Mobile */
    mobileShift: {
      type: Number,
      required: false,
      default: 0,
      validator: (value) => {
        const min = 0;
        const max = 99;
        return isBetween(value, min, max);
      }
    },
    mobileQuantityVisible: {
      type: Number,
      required: false,
      default: 1,
      validator: (value) => {
        const min = 0;
        const max = 5;
        return isBetween(value, min, max);
      }
    },
    desktopQuantityVisible: {
      type: Number,
      required: false,
      default: 4,
      validator: (value) => {
        const min = 0;
        const max = 6;
        return isBetween(value, min, max);
      }
    },
    slidesToScroll: {
      type: Number,
      required: false,
      default: 2
    },
    variableWidth: {
      type: Boolean
    },
    responsive: {
      type: Array,
      default: () => []
    },
    desktopCenterMode: {
      required: false,
      default: true,
      type: Boolean
    },
    mobileCenterMode: {
      required: false,
      default: true,
      type: Boolean
    },
    showDots: {
      type: Boolean,
      required: false,
      default: false
    },
    arrows: {
      type: Boolean,
      required: false,
      default: true
    },
    arrowsType: {
      type: String,
      required: false,
      default: 'contextual'
    },
    fade: {
      type: Boolean,
      required: false,
      default: false
    },
    initialSlide: {
      type: Number,
      required: false,
      default: 0
    }
  },
  emits: ['afterChange'],
  setup(props, { emit, refs }) {
    const carouselSwiped = ref(false);

    const desktopSlidesToShow = computed(() => {
      if (props.itemsLength > props.desktopQuantityVisible) {
        return props.desktopQuantityVisible + props.desktopShift / 100;
      }
      return props.desktopQuantityVisible;
    });

    const mobileSlidesToShow = computed(() => {
      const qty = props.mobileQuantityVisible;
      if (props.itemsLength > 1) {
        return qty + props.mobileShift / 100;
      }
      return qty;
    });

    const getCarouselSettings = computed(() => {
      return {
        arrows: true,
        accessibility: true,
        focusOnChange: true,
        dots: props.showDots,
        variableWidth: props.variableWidth,
        ...(props.variableWidth && { centerPadding: '0px' }),
        speed: 500,
        slidesToShow: desktopSlidesToShow.value,
        slidesToScroll: props.slidesToScroll,
        infinite: props.infinite,
        adaptiveHeight: props.adaptiveHeight,
        centerMode: defineCenterMode(props.desktopQuantityVisible, true),
        autoplay: props.autoplay,
        autoplaySpeed: props.autoplaySpeed,
        responsive: props.responsive.length
          ? [...props.responsive]
          : [
              {
                breakpoint: 980,
                settings: {
                  centerMode: defineCenterMode(props.mobileQuantityVisible + 2),
                  slidesToShow: mobileSlidesToShow.value + 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 768,
                settings: {
                  centerMode: defineCenterMode(props.mobileQuantityVisible + 1),
                  slidesToShow: mobileSlidesToShow.value + 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  centerMode: defineCenterMode(props.mobileQuantityVisible),
                  slidesToShow: mobileSlidesToShow.value,
                  slidesToScroll: 1
                }
              }
            ]
      };
    });

    const defineCenterMode = (edgeQuantity, desktop = false) => {
      const isCenterMode = desktop
        ? props.desktopCenterMode
        : props.mobileCenterMode;
      return isCenterMode && props.itemsLength < edgeQuantity;
    };

    const getCarouselClasses = computed(() => {
      const classes = ['vaimo-slider__slider'];
      if (carouselSwiped.value) classes.push('swiped');
      if (props.infinite) classes.push('infinite');
      if (props.infinite && props.desktopShift && props.itemsLength > 4)
        classes.push('shift-desktop--' + props.desktopShift);
      if (props.infinite && props.mobileShift && props.itemsLength > 1)
        classes.push('shift-mobile--' + props.mobileShift);
      if (props.arrowsType) classes.push('arrows-type-' + props.arrowsType);
      return classes;
    });

    const contextualCursorHalfHeight = 30;
    const moveContextualCursor = (event) => {
      if (props.arrowsType !== 'contextual') return;
      let maxY = event.srcElement.clientHeight - contextualCursorHalfHeight;
      let contextualCursorY =
        event.offsetY > maxY
          ? maxY
          : event.offsetY < contextualCursorHalfHeight
          ? contextualCursorHalfHeight
          : event.offsetY;
      event.currentTarget.querySelector('.contextual-cursor').style.transform =
        'translate(' + event.offsetX + 'px, ' + contextualCursorY + 'px)';
    };

    const isSwiping = ref(false);
    const setCarouselSwiping = (state) => {
      isSwiping.value = state;
    };

    /* toggleFocusOnProduct - function handles accessibility issue where hidden product cards have focusable links */

    const toggleFocusOnProduct = (index) => {
      const visibleLinks = refs.carousel.$el.querySelectorAll(
        '.slick-slide.slick-active a.sf-link,.vaimo-product-card__add-to-cart'
      );
      const hiddenLinks = refs.carousel.$el.querySelectorAll(
        '.slick-slide[tabindex="-1"]:not(.slick-active) a.sf-link,.vaimo-product-card__add-to-cart'
      );
      visibleLinks.forEach((link) => {
        link.setAttribute('tabindex', '0');
      });
      hiddenLinks.forEach((link) => {
        link.setAttribute('tabindex', '-1');
      });
    };

    const removeEmptySpace = () => {
      const element = refs.carousel.$el;
      const slickTrack = element.querySelector('.slick-track');
      const slickLast = slickTrack.querySelector('.slick-slide:last-child');
      const sliderStyles = window.getComputedStyle(element);
      const sliderRightPadding = parseInt(
        sliderStyles.getPropertyValue('padding-right')
      );
      const sliderRightEdge = element.getBoundingClientRect().right;
      const sliderRightEdgeWithPadding = sliderRightEdge - sliderRightPadding;
      const lastSlideRightEdge = slickLast.getBoundingClientRect().right;
      const whiteGapMinimumWidth = 25;
      // Clarifying if there is an empty space between the last slide and carousel right edge.
      // And empty space is bigger than the slide width.
      const isEmptySpace =
        sliderRightEdgeWithPadding - lastSlideRightEdge > whiteGapMinimumWidth;

      if (slickLast.classList.contains('slick-active') && isEmptySpace) {
        const transformValue = slickTrack.style.transform;
        const match = transformValue.match(/-?\d+px/g);

        if (match?.length) {
          const value = parseInt(match[0]);
          slickTrack.style.transform = `translate3d(${
            value + (sliderRightEdgeWithPadding - lastSlideRightEdge)
          }px, 0px, 0px)`;
        }
      }
    };

    /* handleAccessibilityFocus - function handles accessibility issue where aria-hidden=true element contains focusable elements */

    const isActive = (element) => element.classList.contains('slick-active');

    const handleAccessibilityFocus = () => {
      if (!refs.carousel.$el) {
        return;
      }
      const element = refs.carousel.$el;
      const slickTrack = element.querySelector('.slick-track');
      if (!slickTrack) {
        console.error('Carousel element or slick-track not found');
        return;
      }
      const slickSlides = Array.from(
        slickTrack.querySelectorAll('.slick-slide')
      );
      const slickLast = slickSlides[slickSlides.length - 1];

      if (isActive(slickLast)) {
        const focusableIndex = slickSlides.length - 4;
        if (focusableIndex >= 0) {
          const focusableSlide = slickSlides[focusableIndex];
          if (!isActive(focusableSlide)) {
            focusableSlide.classList.add('slick-active');
            focusableSlide.setAttribute('aria-hidden', 'false');
          }
        }
      }
    };

    const emitAfterChange = () => {
      const index = refs.carousel.$children[0].currentSlide;
      emit('afterChange', index);
      removeEmptySpace();
      handleAccessibilityFocus();
    };

    return {
      carouselSwiped,
      getCarouselSettings,
      getCarouselClasses,
      moveContextualCursor,
      isSwiping,
      setCarouselSwiping,
      toggleFocusOnProduct,
      emitAfterChange
    };
  }
});
</script>

<style lang="scss" scoped>
.vaimo-slider {
  position: relative;
  margin-top: var(--spacer-3md);

  &__slider {
    overflow-x: hidden;

    @include for-screen-m-and-l {
      padding-left: 50px;
      padding-right: 50px;
    }

    .sf-product-card {
      max-width: 100% !important;
    }

    &.infinite:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50px;
      background: #fff;
      width: 10px;
      opacity: 1;
      transition: opacity 0.1s;
      @include for-screen-m-and-l {
        width: 50px;
      }
    }
    &.swiped:after {
      opacity: 0;
    }
  }
  .slick-list {
    padding-left: var(--spacer-base);
    padding-right: var(--spacer-base);
    margin-left: calc(var(--spacer-base) * -1);
    margin-right: calc(var(--spacer-base) * -1);
  }
  ::v-deep {
    .vaimo-slider__slider {
      img {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
      .slick-track {
        display: flex;
        transition: transform 0.3s ease-in-out;
      }
      .slick-list {
        overflow: visible;
      }
      .slick-slide {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      &.is-swiping {
        .slick-list::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 9999;
        }
      }

      @include for-screen-s {
        &.shift-mobile--25 {
          .slick-slide {
            transform: translateX(25%);
          }
        }
        &.shift-mobile--50 {
          .slick-slide {
            transform: translateX(50%);
          }
        }
        &.shift-mobile--75 {
          .slick-slide {
            transform: translateX(75%);
          }
        }
      }

      @include for-screen-m-and-l {
        &.shift-desktop--25 {
          .slick-slide {
            transform: translateX(25%);
          }
        }
        &.shift-desktop--50 {
          .slick-slide {
            transform: translateX(50%);
          }
        }
        &.shift-desktop--75 {
          .slick-slide {
            transform: translateX(75%);
          }
        }
      }
    }
    .slick-arrow {
      display: none;
      width: 120px;
      height: 100%;
      top: 0;
      bottom: 0;
      transform: none;
      z-index: 2;
      cursor: none;
      @include for-screen-m-and-l {
        display: block;
      }
      &:hover {
        .contextual-cursor {
          opacity: 1;
          visibility: visible;
        }
      }
      &.slick-prev {
        left: -50px;
      }
      &.slick-next {
        right: -50px;
      }
      &:before {
        display: none;
      }
      &.slick-disabled {
        display: none !important;
      }
    }

    .arrows-type-classic {
      .slick-prev {
        left: 0;
        cursor: pointer !important;
        .arrows-chevron {
          position: absolute;
          top: 50%;
          left: calc(50% - 12px);
        }
      }
      .slick-next {
        right: 0;
        cursor: pointer !important;
        .arrows-chevron {
          position: absolute;
          top: 50%;
          left: calc(50% - 12px);
        }
      }
    }
  }
  .contextual-cursor {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $blanc;
    border: 1px solid $primary;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    will-change: transform;
    backface-visibility: hidden;
    pointer-events: none; /* Allow clicking trough the div */
    transition: transform 0s, opacity 0.2s ease, visibility 0.2s ease; /* Sticking effect */
    z-index: 3;
  }
  &.with-dots {
    ::v-deep {
      .slick-list {
        padding-bottom: 30px;
      }
      .slick-dots {
        bottom: 0px;
      }
    }
  }
}
</style>
