




























import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useRoute
} from '@nuxtjs/composition-api';

import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import { useSocialLogin } from '~/diptyqueTheme/composable/useSocialLogin';

export default defineComponent({
  name: 'VaimoSocialLogin',
  components: {
    VaimoButton
  },
  setup() {
    const route = useRoute();
    const routePath = computed(() => route.value.path);
    const {
      loginWithFacebook,
      initGoogleLogin,
      initFacebookLogin,
      renderGoogleButton,
      isFbSocialLoginEnabled,
      isGoogleSocialLoginEnabled
    } = useSocialLogin();
    const isThankYouPage = computed(() =>
      routePath.value.includes('checkout/thank-you')
    );
    const googleSocialLoginButton = ref<HTMLElement>(null);
    const isGoogleButtonVisible = ref<boolean>(false);

    onMounted(() => {
      if (isGoogleSocialLoginEnabled.value) {
        window.onGoogleLibraryLoad = () => {
          initGoogleLogin();
          renderGoogleButton(
            googleSocialLoginButton.value,
            isThankYouPage.value ? 'signin_with' : 'continue_with'
          );

          requestAnimationFrame(() => {
            if (googleSocialLoginButton.value?.querySelector('iframe')) {
              isGoogleButtonVisible.value = true;
            }
          });
        };
      }

      if (isFbSocialLoginEnabled.value) {
        window.fbAsyncInit = () => initFacebookLogin();
      }
    });

    return {
      isThankYouPage,
      loginWithFacebook,
      googleSocialLoginButton,
      isGoogleButtonVisible,
      isFbSocialLoginEnabled,
      isGoogleSocialLoginEnabled
    };
  },
  head() {
    return {
      meta: [{ name: 'referrer', content: 'strict-origin-when-cross-origin' }],
      script: [
        {
          src: 'https://accounts.google.com/gsi/client',
          async: true,
          defer: true
        },
        {
          src: 'https://apis.google.com/js/api.js',
          async: true,
          defer: true
        },
        {
          src: 'https://connect.facebook.net/en_US/sdk.js',
          async: true
        }
      ]
    };
  }
});
