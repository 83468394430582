




























































































import {
  computed,
  defineComponent,
  ref,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';

import { useForgotPassword } from '~/modules/customer/composables/useForgotPassword';
import {
  customerPasswordRegExp,
  invalidPasswordMsg
} from '~/modules/customer/helpers/passwordValidation';

extend('email', {
  ...email,
  message: 'Invalid email address'
});

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value)
});

export default defineComponent({
  name: 'ResetPassword',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoPasswordInput: () => import('organisms/VaimoPasswordInput.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    SfInput,
    ValidationProvider,
    ValidationObserver
  },
  // eslint-disable-next-line consistent-return
  middleware({ redirect, route }) {
    if (!route.query.token) {
      return redirect('/');
    }
  },
  setup() {
    const {
      result,
      setNew,
      error: forgotPasswordError,
      loading: forgotPasswordLoading
    } = useForgotPassword();
    const passwordMatchError = ref<string | null>(null);
    const {
      app: { i18n }
    } = useContext();

    const form = ref({
      email: '',
      password: '',
      repeatPassword: ''
    });
    const isPasswordChanged = computed(
      () => result.value?.setNewPasswordResult
    );

    const route = useRoute();
    const { token } = route.value.query;
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha
    );
    const noSuccess = ref(false);
    const errorMessage = ref('');

    const setNewPassword = async () => {
      passwordMatchError.value = null;

      if (form.value.password !== form.value.repeatPassword) {
        passwordMatchError.value = i18n.t('Passwords do not match') as string;

        return;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();

        await setNew({
          tokenValue: token as string,
          newPassword: form.value.password,
          email: form.value.email,
          recaptchaToken
        });
      } else {
        await setNew({
          tokenValue: token as string,
          newPassword: form.value.password,
          email: form.value.email
        });
      }

      if (!result.value.setNewPasswordResult) {
        noSuccess.value = true;
        errorMessage.value = result.value.setNewPasswordError;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    return {
      isPasswordChanged,
      form,
      setNewPassword,
      forgotPasswordLoading,
      forgotPasswordError,
      passwordMatchError,
      token,
      result,
      isRecaptchaEnabled,
      noSuccess,
      errorMessage
    };
  }
});
