






































































import { defineComponent } from '@nuxtjs/composition-api';

import { useLink } from '~/diptyqueTheme/composable/useLink';

export default defineComponent({
  name: 'ParagraphSection',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup() {
    const { normalizeLink } = useLink();

    return {
      normalizeLink
    };
  }
});
