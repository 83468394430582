



















































































































































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import VaimoBanner from 'atoms/VaimoBanner.vue';

import VaimoSlider from '~/diptyqueTheme/components/molecules/VaimoSlider.vue';
import { useDeclination, useLink } from '~/diptyqueTheme/composable';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductGallery',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    ProductGalleryMobile: () =>
      import('organisms/product/Gallery/ProductGalleryMobile.vue'),
    TestimonyDesktop: () =>
      import(
        'templates/sections/Testimony/declinations/desktopDeclination1.vue'
      ),
    TestimonyMobile: () =>
      import(
        'templates/sections/Testimony/declinations/mobileDeclination1.vue'
      ),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoBanner,
    VaimoSlider,
    SfLink
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    uniqueKey: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { root }) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const $markdown = root?.$markdown;
    const { normalizeLink } = useLink();
    const { getBannerFunction, getCTAdata, getCTALinkData } = useDeclination(props.sectionData);

    const cleanedGalleryItems =
      props.sectionData.galleryItemsCollection?.items.filter(
        (value) => value !== null
      );

    const getGalleryItems = computed(() => cleanedGalleryItems);
    const transformText = (text) => {
      return text ? $markdown.render(text) : '';
    };

    const sectionId = computed(() => {
      return props.sectionData?.sys?.id;
    });

    const ifOneItem = computed(() => getGalleryItems.value?.length === 1);
    const declinationData = computed(() => {
      return {
        mVar: props.sectionData?.mVar,
        heading: props.sectionData?.heading,
        headingLink: props.sectionData?.headingLink,
        descr: props.sectionData?.descr,
        ctaLinkText: props.sectionData?.ctaLinkText,
        ctaLinkLink: props.sectionData?.ctaLinkLink,
        visualLink: null,
        visual: {
          contentType: 'image/png',
          url: getGalleryItems.value[0]?.visual?.url
        },
        visualDesktop: {
          contentType: 'image/png',
          url: getGalleryItems.value[0]?.visualDesktop?.url
        },
        visualPosition: 'Visual on the right for desktop'
      };
    });

    const isOption1 = computed(() => {
      if(!props.sectionData?.mVar) return true;
      return props.sectionData?.mVar.split(' - ')[0] === 'Option 1';
    });

    const isOption2 = computed(() => {
      if(!props.sectionData?.mVar) return false;
      return props.sectionData?.mVar.split(' - ')[0] === 'Option 2';
    });

    const sectionClasses = computed(() => {
      return [
        'product-gallery--' + props.uniqueKey || sectionId.value,
        'product-gallery--' + (isOption1.value ? 'option1' : 'option2')
      ];
    });

    const declinationClasses = computed(() => {
      if (isOption1.value) {
        return {
          container: [],
          textCol: [],
          galleryCol: ['s:hidden']
        };
      }
      return {
        container: ['grid grid-cols-6'],
        textCol: ['col-span-6 m-and-l:col-span-2'],
        galleryCol: ['col-span-6 m-and-l:col-span-4']
      };
    });

    const getItemRatio = (item) => {
      const isVideo = item?.visual?.contentType?.split('/')[0] === 'video';
      const imageRatio = isOption1.value ? '3:4' : '4:3';
      return isVideo ? '16:9' : imageRatio;
    };

    return {
      sectionClasses,
      declinationClasses,
      getBannerFunction,
      getItemRatio,
      getGalleryItems,
      declinationData,
      transformText,
      normalizeLink,
      ifOneItem,
      sectionId,
      isOption1,
      isOption2,
      getCTAdata,
      getCTALinkData
    };
  }
});
