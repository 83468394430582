



















import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoFooterPleatsPanelBlock',
  components: {
    FooterMenu: () => import('organisms/menu/FooterMenu.vue')
  },
  props: {
    data: {
      required: false,
      type: Array,
      default: () => []
    },
    blockName: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const getSectionData = computed(() => props.data[0]?.data);

    return {
      getSectionData
    };
  }
});
