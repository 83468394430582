<template>
  <div>
    <VaimoTopHeaderMobile class="l:hidden m-and-l:hidden" />
    <VaimoTopHeader class="s:hidden" />
    <main id="layout" role="main" style="min-height: 1000px">
      <nuxt :key="$route.fullPath" />
      <TailwindSafeClasses />
    </main>
    <LazyHydrate :when-visible="{ threshold: '0.1' }">
      <Notification area="top" />
    </LazyHydrate>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  name: 'CheckoutLayout',
  components: {
    Notification: () => import('~/diptyqueTheme/components/Notification.vue'),
    LazyHydrate,
    VaimoTopHeader: () => import('organisms/header/VaimoTopHeader.vue'),
    VaimoTopHeaderMobile: () =>
      import('organisms/header/mobile/VaimoTopHeaderMobile.vue'),
    TailwindSafeClasses: () => import('atoms/SafeClasses.vue')
  }
});
</script>

<style scoped></style>
