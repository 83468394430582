




































import { defineComponent, computed, useRouter } from '@nuxtjs/composition-api';
import { useSection } from '@/diptyqueTheme/composable/useSection';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';

export default defineComponent({
  name: 'BaseSection',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    BlockWrapper: () => import('templates/BlockWrapper.vue'),
    Banner: () => import('templates/sections/Banner.vue'),
    VaimoActionButton: () => import('atoms/VaimoActionButton.vue')
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const {
      headingData,
      truncatedTextData,
      getContentAlignClasses,
      getBlockElementClasses
    } = useSection(props.sectionData);
    const router = useRouter();
    const megaMenuMobileStore = useMegaMenuMobileStore();

    const getBannerData = computed<object>(() => {
      return props.sectionData?.banner;
    });

    const getActionType = computed(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (getButtonData.value?.link?.includes('/l/')) {
        return 'open page';
      }
      return 'open product page';
    });

    const goToLink = (link) => {
      router.push(link);
      hideMenu();
    };

    const hideMenu = () => {
      megaMenuMobileStore.hide();
      megaMenuMobileStore.isMenuLayersVisible = false;
    };

    const getButtonData = computed<object>(() => {
      const data = props.sectionData?.buttonsCollection?.items[0];
      if (data) {
        return {
          iconPosition: data?.iconPosition,
          link: data?.link,
          linkBehavior: data?.linkBehavior,
          style: data?.style.toLowerCase(),
          text: data?.text
        };
      }
    });

    return {
      headingData,
      getButtonData,
      goToLink,
      hideMenu,
      getActionType,
      getBannerData,
      truncatedTextData,
      getContentAlignClasses,
      elemClasses: getBlockElementClasses
    };
  }
});
