













































import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useLink } from '~/diptyqueTheme/composable/useLink';
import { useDeclination } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'ItemStandard',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue')
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const { getVisualData } = useDeclination(props.item);
    const isButtonOnly = computed(() => {
      return (
        props.item.buttonCollection && !props.item.heading && !props.item.descr
      );
    });

    return {
      normalizeLink,
      getVisualData,
      isButtonOnly
    };
  }
});
