



















import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import { useThemeUiState } from '~/diptyqueTheme/composable/useThemeUiState';

export default defineComponent({
  name: 'VaimoStoreSwitcherModal',
  components: {
    VaimoSidebar: () => import('organisms/VaimoSidebar.vue'),
    VaimoAvailableStores: () =>
      import('organisms/VaimoStoreSwitcher/VaimoAvailableStores.vue')
  },
  setup() {
    const { isStoreSwitcherModalOpen, toggleStoreSwitcherModal } =
      useThemeUiState();
    const {
      config: {
        value: { store_code }
      }
    } = useConfig();
    const { app: { i18n: { locales } } = { i18n: { locales: [] } } } =
      useContext();

    const isWebsiteLocal = (store, index, localesArray) => {
      return (
        localesArray.findIndex((orgStore) => {
          return orgStore.websiteName === store.websiteName;
        }) === index
      );
    };

    const availableStores = computed(() => {
      const getWebsiteNames = locales
        .filter(isWebsiteLocal)
        .map((store) => store.websiteName);
      return getWebsiteNames.map((websiteName) => {
        return {
          websiteName: websiteName,
          stores: locales.filter((store) => {
            return store.websiteName === websiteName;
          })
        };
      });
    });

    return {
      isStoreSwitcherModalOpen,
      toggleStoreSwitcherModal,
      availableStores,
      currentStoreCode: store_code
    };
  }
});
