































import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'Testimony__mobileDeclination1',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    declinationData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getBannerData } = useDeclination(props.declinationData);
    const containerClasses = computed(() => {
      return [
        props.declinationData.center?.includes('mobile')
          ? 'text-center'
          : 'text-left'
      ];
    });

    return {
      getBannerData,
      containerClasses
    };
  }
});
