











import {
  defineComponent,
  onBeforeUnmount,
  ref,
  watch
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoProgressBar',
  props: {
    progress: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      required: true
    },
    currentTime: {
      type: Number,
      required: true
    },
    draggable: {
      type: Boolean
    }
  },
  emits: ['seeking', 'seeking-start', 'seeking-end'],
  setup(props, { emit }) {
    const progressBar = ref<HTMLElement | null>(null);
    const isSeeking = ref(false);

    watch(isSeeking, (newValue) => {
      emit(newValue ? 'seeking-start' : 'seeking-end');
    });

    const startSeek = (event: MouseEvent | TouchEvent) => {
      if (!props.draggable) {
        return;
      }

      isSeeking.value = true;

      document.addEventListener('mousemove', onSeek);
      document.addEventListener('mouseup', endSeek);
      document.addEventListener('touchmove', onSeek);
      document.addEventListener('touchend', endSeek);

      if (event.type === 'touchstart') {
        seek((event as TouchEvent).touches[0]);
      } else {
        seek(event as MouseEvent);
      }
    };

    const seek = (event: MouseEvent | Touch) => {
      if (!props.draggable) {
        return;
      }

      if (progressBar.value) {
        const rect = progressBar.value.getBoundingClientRect();
        const clickPosition = event.clientX - rect.left;
        const percentage = clickPosition / rect.width;
        const calculatedTime = percentage * props.duration;
        emit('seeking', calculatedTime);
      }
    };

    const onSeek = (event: MouseEvent | TouchEvent) => {
      if (isSeeking.value) {
        if (event.type === 'touchmove') {
          seek((event as TouchEvent).touches[0]);
        } else {
          seek(event as MouseEvent);
        }
      }
    };

    const endSeek = () => {
      isSeeking.value = false;
      document.removeEventListener('mousemove', onSeek);
      document.removeEventListener('mouseup', endSeek);
      document.removeEventListener('touchmove', onSeek);
      document.removeEventListener('touchend', endSeek);
    };

    onBeforeUnmount(() => {
      document.removeEventListener('mousemove', onSeek);
      document.removeEventListener('mouseup', endSeek);
      document.removeEventListener('touchmove', onSeek);
      document.removeEventListener('touchend', endSeek);
    });

    return {
      progressBar,
      startSeek,
      seek
    };
  }
});
