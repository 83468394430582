






































































































































































































































import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  useContext
} from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { SfInput } from '@storefront-ui/vue';
import { email, min, required } from 'vee-validate/dist/rules';
import { useUser } from '~/modules/customer/composables/useUser';
import {
  invalidPasswordMsg,
  customerPasswordRegExp
} from '~/modules/customer/helpers/passwordValidation';

extend('email', {
  ...email,
  message: 'Invalid email address'
});
extend('required', {
  ...required,
  message: 'This field is required'
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters'
});
extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,no-magic-numbers
  validate: (value: string) => customerPasswordRegExp.test(value)
});

export default defineComponent({
  name: 'AccountInfo',
  components: {
    AccountHeading: () => import('organisms/account/parts/AccountHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    SfInput,
    ValidationProvider,
    ValidationObserver
  },
  setup() {
    const { i18n } = useContext();
    const confirm_password_type = ref('password');
    const new_password_type = ref('password');
    const current_password_type = ref('password');
    const { updateUser, changePassword, loading, error, user: getUser } = useUser();
    const isInfoBlockVisible = ref(false);
    const updateError = ref('');

    const form = ref({
      email: '',
      firstname: '',
      lastname: '',
      mobile: '',
      date_of_birth: ''
    });

    const changePasswordData = ref({
      currentPass: '',
      newPass: '',
      confirmedNewPass: ''
    });

    const matchPasswords = computed(() => {
      if (
        changePasswordData.value.newPass !==
        changePasswordData.value.confirmedNewPass
      ) {
        updateError.value = i18n.t('Passwords do not match, please try again.') as string;
        return true;
      }
      updateError.value = '';
      return false;
    });

    const checkPasswordFields = computed(() => {
      return !!(
        changePasswordData.value.currentPass &&
        changePasswordData.value.newPass &&
        changePasswordData.value.confirmedNewPass
      );
    });

    const updateCustomer = async () => {
      await updateUser({ user: form.value });
    };

    const disableDatesAfterCurrent = (date) => {
      return date >= new Date(new Date().setDate(new Date().getDate()));
    };

    const changeCustomerPassword = async (isInvalidForm) => {
      if(isInvalidForm || !checkPasswordFields.value || loading.value || matchPasswords.value) {
        return false;
      }

      const { currentPass, newPass } = changePasswordData.value;
      await changePassword({
        current: currentPass,
        new: newPass
      });

      if (error.value.changePassword) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateError.value = error.value.changePassword;
        setTimeout(() => {
          updateError.value = '';
        }, 5000);
      }
    };

    const showHideInfoBlock = () => {
      isInfoBlockVisible.value = !isInfoBlockVisible.value;
    };
    const handleClickOutside = () => {
      if (isInfoBlockVisible.value) {
        isInfoBlockVisible.value = false;
      }
    };

    const changeInputType = (item) => {
      if (item === 'confirm_password') {
        confirm_password_type.value =
          confirm_password_type.value === 'text' ? 'password' : 'text';
      } else if (item === 'new_password') {
        new_password_type.value =
          new_password_type.value === 'text' ? 'password' : 'text';
      } else if (item === 'current_password') {
        current_password_type.value =
          current_password_type.value === 'text' ? 'password' : 'text';
      }
    };

    onBeforeMount(() => {
      form.value.firstname = getUser.value?.firstname;
      form.value.lastname = getUser.value?.lastname;
      form.value.email = getUser.value?.email;
      form.value.date_of_birth = getUser.value?.date_of_birth;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      form.value.mobile = getUser.value?.mobile;
    });

    return {
      form,
      changePasswordData,
      checkPasswordFields,
      updateError,
      updateCustomer,
      loading,
      confirm_password_type,
      new_password_type,
      current_password_type,
      isInfoBlockVisible,
      disableDatesAfterCurrent,
      handleClickOutside,
      matchPasswords,
      changeInputType,
      changeCustomerPassword,
      showHideInfoBlock
    };
  }
});
