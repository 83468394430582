var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subscription-section"},[_c('div',{staticClass:"subscription-section__heading",domProps:{"innerHTML":_vm._s(_vm.$t('Enter your email address...'))}}),_vm._v(" "),(_vm.showForm)?_c('div',{staticClass:"subscription-section__form"},[_c('SfInput',{staticClass:"center",attrs:{"name":"email","placeholder":_vm.$t('Enter your email address'),"type":"email","autocomplete":"off"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),(_vm.status)?_c('div',{staticClass:"form-feedback"},[_c('VaimoIcon',{staticClass:"checkmark",attrs:{"icon":_vm.isStatusInvalid ? 'cross' : 'check',"size":_vm.isStatusInvalid ? 12 : 7,"label":_vm.$t('Check')}}),_vm._v(" "),_c('p',{staticClass:"form-feedback-message ml-xs",class:{
          success: _vm.status === 'success',
          error: _vm.isStatusInvalid
        }},[_vm._v("\n        "+_vm._s(_vm.status === 'error' ? _vm.$t('Invalid email address') : _vm.statusMessage)+"\n      ")])],1):_vm._e(),_vm._v(" "),_c('p',{staticClass:"form-terms-and-conditions"},[_vm._v("\n      "+_vm._s(_vm.$t('By clicking on subscribe, I agree to the'))+"\n      "),_c('a',{attrs:{"href":_vm.getTermsOfServiceLinks.terms_link,"target":"_blank","aria-label":_vm.$t('General Terms and Conditions') + ' ' + _vm.$t('Opens in new tab'),"title":_vm.$t('General Terms and Conditions') + ' ' + _vm.$t('Opens in new tab'),"rel":"noreferrer nofollow"}},[_vm._v("\n        "+_vm._s(_vm.$t('General Terms and Conditions'))+"\n      ")]),_vm._v("\n      "+_vm._s(_vm.$t(
          _vm.isStoreDe
            ? 'und die Datenschutzerklärung. Informationen hierzu finden Sie in unseren'
            : 'and the'
        ))+"\n      "),_c('a',{attrs:{"href":_vm.getTermsOfServiceLinks.policy_link,"target":"_blank","aria-label":_vm.$t('Privacy Policy') + ' ' + _vm.$t('Opens in new tab'),"title":_vm.$t('Privacy Policy') + ' ' + _vm.$t('Opens in new tab'),"rel":"noreferrer nofollow"}},[_vm._v("\n        "+_vm._s(_vm.$t('Privacy Policy'))+"\n      ")])]),_vm._v(" "),_c('VaimoButton',{attrs:{"variant":"default","disabled":!_vm.email,"label-centered":true,"full-width":true},nativeOn:{"click":function($event){return _vm.submitForm.apply(null, arguments)}}},[_vm._v("\n      "+_vm._s(_vm.$t('Subscribe'))+"\n    ")])],1):_c('p',{staticClass:"mt-3md text-center"},[_vm._v("\n    "+_vm._s(_vm.$t('We will send you a link to confirm your registration.'))+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }