















import { computed, defineComponent } from '@nuxtjs/composition-api';
import VaimoCollectedProducts from '~/diptyqueTheme/components/organisms/cart/parts/VaimoCollectedProducts.vue';
import VaimoSpecialOfferProducts from '~/diptyqueTheme/components/organisms/cart/parts/VaimoSpecialOfferProducts.vue';
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { getOfferProducts } from '~/diptyqueTheme/getters/offerProductsGetter';

export default defineComponent({
  name: 'MiniCartTab',
  components: {
    VaimoCollectedProducts,
    VaimoSpecialOfferProducts
  },
  setup() {
    const { cart } = useCart();
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const hasSpecialOffers = computed(() => {
      return totalItems.value && getOfferProducts(cart.value).length;
    });

    return {
      hasSpecialOffers
    };
  }
});
