


















































import {
  defineComponent,
  computed,
  ref,
  watch,
  useFetch
} from '@nuxtjs/composition-api';
import { useProduct } from '~/composables';
import VaimoSlider from '~/diptyqueTheme/components/molecules/VaimoSlider.vue';
import VaimoBadge from '~/diptyqueTheme/components/atoms/VaimoBadge.vue';
import { useAddToCart } from '~/helpers/cart/addToCart';
import useCart from '~/modules/checkout/composables/useCart';
import VaimoHeading from '~/diptyqueTheme/components/atoms/VaimoHeading.vue';
import { getOfferProducts } from '~/diptyqueTheme/getters/offerProductsGetter';

export default defineComponent({
  name: 'VaimoSpecialOfferProducts',
  components: {
    VaimoHeading,
    VaimoBadge,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoSlider
  },
  props: {
    mobileQuantityVisible: {
      type: Number,
      required: false,
      default: 2,
      validator(value: number) {
        return [1, 2, 3, 4, 5].includes(value);
      }
    },
    desktopQuantityVisible: {
      type: Number,
      required: false,
      default: 4,
      validator(value: number) {
        return [1, 2, 3, 4, 5].includes(value);
      }
    },
    miniCartDisplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const { getProductList } = useProduct();
    const products = ref([]);
    const { addItemToCart } = useAddToCart();
    const { cart, removeItem, loading } = useCart();
    const specialOfferProducts = computed(() => getOfferProducts(cart.value));

    watch(
      () => specialOfferProducts.value,
      async () => {
        await getProducts();
      }
    );

    useFetch(async () => {
      if (specialOfferProducts.value?.length) {
        await getProducts();
      }
    });

    const getProducts = async () => {
      const skusList = specialOfferProducts.value.map((item) => item.sku);
      const data = await getProductList({
        filter: {
          sku: {
            in: skusList
          }
        }
      });
      products.value = data?.items ?? [];
    };
    const getMergedProducts = computed(() => {
      const offers = specialOfferProducts.value;
      return products.value.reduce((acc, item) => {
        const offer = offers.find((offer) => offer.sku === item.sku);
        if (offer) {
          const newItem = {
            ...item,
            offer_enabled: offer.enabled,
            offer_label: offer.offer_label,
            offer_image: offer.offer_image,
            offer_title: offer.offer_title,
            offer_SKU: offer.sku,
            is_special_offer_product: true
          };
          return [...acc, newItem];
        }
        console.error('There is no offer for Item SKU ', item?.sku);
        return acc;
      }, []);
    });

    const checkIfSpecialProductInCart = (product) => {
      const { items } = cart.value;
      const findProduct = items?.find(
        (item) => item.product.sku === product.sku
      );

      return !!findProduct;
    };

    const addProduct = (product) => {
      const { offer_enabled } = product.product;
      if (checkIfSpecialProductInCart(product.product)) {
        removeItem({
          product: cart.value?.items.find(
            (cartItem) => cartItem.product.uid === product.product.uid
          )
        });
        return;
      } else if (!offer_enabled) {
        // PRODUCT DISABLED FOR ADDING
        return;
      }
      addItemToCart(product);
    };

    const offersExist = computed(() => {
      return getMergedProducts.value?.length;
    });

    const getSpecialOfferImage = (product) => {
      return product.offer_image || product.thumbnail?.url;
    };

    return {
      offersExist,
      getMergedProducts,
      getSpecialOfferImage,
      checkIfSpecialProductInCart,
      addProduct,
      loading,
      products
    };
  }
});
