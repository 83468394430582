











import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RatioWrapper',
  props: {
    ratio: {
      type: String,
      required: false,
      default: '3:4'
    }
  },
  setup(props) {
    const transformedRatioClass = computed<string>(() => {
      const ratio = props.ratio.replace(':', '-');

      return `aspect-${ratio}`;
    });

    return {
      transformedRatioClass
    };
  }
});
