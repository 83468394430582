var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SfAccordion',{attrs:{"multiple":true,"show-chevron":true}},[_c('SfAccordionItem',{attrs:{"header":_vm.getLabel(_vm.filter.label)},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var isOpen = ref.isOpen;
var accordionClick = ref.accordionClick;
return [_c('div',{staticClass:"filter-title flex justify-between items-center cursor-pointer",attrs:{"tabindex":"0","role":"button","aria-expanded":isOpen},on:{"click":accordionClick,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return accordionClick.apply(null, arguments)}}},[_c('div',{staticClass:"filter-title__label"},[_vm._v("\n            "+_vm._s(_vm.getLabel(_vm.filter.label))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"accordion-icon"},[(!isOpen)?_c('span',[_vm._v("+")]):_c('span',[_vm._v("-")])]),_vm._v(" "),(false)?_c('transition',{attrs:{"name":"sf-fade"}},[(
                _vm.isResetButtonVisible(_vm.filter.attribute_code, _vm.selectedFilters)
              )?_c('div',{staticClass:"filter-title__reset-btn",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.reset(_vm.filter.attribute_code)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.reset(_vm.filter.attribute_code)}}},[_vm._v("\n              "+_vm._s(_vm.$t('Reset'))+"\n            ")]):_vm._e()]):_vm._e()],1)]}}])},[_vm._v(" "),_c('div',{staticClass:"select-all cursor-pointer",class:{ disabled: _vm.loading },attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.selectAll(_vm.filter, _vm.filter.attribute_code)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectAll(_vm.filter, _vm.filter.attribute_code)}}},[_vm._v("\n        "+_vm._s(!_vm.isAllSelected && !_vm.getSelectedFiltersLength
            ? _vm.$t('Select')
            : _vm.$t('Deselect all'))+"\n      ")]),_vm._v(" "),_c(_vm.filter.options && _vm.filter.options.length > 4 ? 'SfScrollable' : 'div',{tag:"component",staticClass:"scrollable-wrapper",attrs:{"max-content-height":"11rem"}},_vm._l((_vm.filter.options),function(option){return _c('VaimoCheckbox',{key:((_vm.filter.attribute_code) + "-" + (option.value)),staticClass:"filters__item",attrs:{"label":!_vm.isPriceFilter(_vm.filter)
              ? option.label
              : ((option.label) + " " + (_vm.getCurrencySymbol())),"selected":Boolean(_vm.selected(_vm.filter.attribute_code, option.value)),"disabled":!option.count,"data-testid":"category-filter","aria-label":option.label},on:{"change":function($event){return _vm.selectFilterValue(option)}},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectFilterValue(option)}},scopedSlots:_vm._u([{key:"checkbox-label",fn:function(ref){
              var label = ref.label;
return [_c('div',{staticClass:"flex relative"},[_c('div',{staticClass:"sf-checkbox__label",class:{
                  'display-none': !label,
                  selected: Boolean(
                    _vm.selected(_vm.filter.attribute_code, option.value)
                  ),
                  'disabled-checkbox': !option.count
                },domProps:{"innerHTML":_vm._s(_vm.$dompurify(label))}}),_vm._v(" "),_c('div',{staticClass:"count",class:{ 'disabled-checkbox': !option.count }},[_vm._v("\n                "+_vm._s(option.count)+"\n              ")])])]}}],null,true)})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }