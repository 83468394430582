


















































































import { defineComponent } from '@nuxtjs/composition-api';
import VaimoEngravingCard from 'organisms/cart/parts/VaimoEngravingCard.vue';

import cartGetters from '~/modules/checkout/getters/cartGetters';

export default defineComponent({
  name: 'OrderSingleProduct',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoEngravingCard
  },
  props: {
    product: { type: Object, required: true }
  },

  setup() {

    const isEngraving = (product) => {
      return product.engraving ? true : false;
    };

    return {
      isEngraving,
      cartGetters
    };
  }
});
