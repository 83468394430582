










































































































































import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRef,
  useContext
} from '@nuxtjs/composition-api';
import { SfLink, SfProductCard } from '@storefront-ui/vue';

import { useUiNotification } from '~/composables';
import VaimoBadge from '~/diptyqueTheme/components/atoms/VaimoBadge.vue';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import productStatuses from '~/diptyqueTheme/config/productStatuses';
import { useAddToCart } from '~/helpers/cart/addToCart';
import type { Product } from '~/modules/catalog/product/types';
import { useCartStore } from '~/modules/checkout/stores/cart';

export default defineComponent({
  name: 'VaimoProductCard',
  components: {
    SfProductCard,
    SfLink,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoBadge
  },

  props: {
    image: {
      type: [Array, Object, String],
      default: ''
    },
    imageSource: {
      type: String,
      default: 'magento'
    },
    hoverImage: {
      type: [Array, Object, String],
      default: null
    },
    sku: {
      required: false,
      type: String,
      default: ''
    },
    productType: {
      type: String,
      default: 'SimpleProduct'
    },
    fallbackImage: {
      type: [Array, Object, String],
      default: null
    },
    imageWidth: {
      type: [Number, String],
      default: null
    },
    imageHeight: {
      type: [Number, String],
      default: null
    },
    relatedProductsQnty: {
      type: [Number, String],
      default: null
    },
    capacity: {
      type: [Number, String, Array],
      default: null
    },
    variantName: {
      type: [Number, String, Array],
      default: null
    },
    badgeLabel: {
      type: String,
      default: ''
    },
    badgeColor: {
      type: String,
      default: ''
    },
    colors: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    link: {
      type: [String, Object],
      default: null
    },
    linkTag: {
      type: String,
      default: undefined
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false
    },
    maxRating: {
      type: [Number, String],
      default: 5
    },
    basePrice: {
      type: [Number, String],
      default: null
    },
    regularPrice: {
      type: [Number, String],
      default: null
    },
    specialPrice: {
      type: [Number, String],
      default: null
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart'
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill'
    },
    isInWishlist: {
      type: Boolean,
      default: false
    },
    showAddToCartButton: {
      type: Boolean,
      default: false
    },
    isAddedToCart: {
      type: Boolean,
      default: false
    },
    addToCartDisabled: {
      type: Boolean,
      default: false
    },
    imageTag: {
      type: String,
      default: ''
    },
    sizeDesktop: {
      type: String,
      required: false,
      default: 'l'
    },
    sizeMobile: {
      type: String,
      required: false,
      default: 'l'
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({})
    },
    editorialData: {
      type: [Array, null],
      default: null
    },
    loading: Boolean,
    stockStatus: {
      required: false,
      type: String,
      default: ''
    },
    isSalable: {
      required: false,
      type: Boolean,
      default: true
    },
    isDynamicPrice: Boolean,
    product: {
      type: Object as PropType<Product>,
      required: true
    }
  },
  emits: ['add-to-cart'],

  setup(props) {
    const {
      app: { i18n, router }
    } = useContext();
    const storeCode = i18n.localeProperties.code;
    const isJpStore = storeCode === 'ja_jp';
    const isAdded = ref(false);
    const cartStore = useCartStore();
    const productCardRef = ref();
    const { isDesktop } = useScreenSize();
    const { addItemToCart } = useAddToCart();
    const { send: sendNotification } = useUiNotification();
    const desktopSizesMap = {
      l: 'desktop-l',
      m: 'desktop-m',
      s: 'desktop-s'
    };
    const mobileSizesMap = {
      l: 'mobile-l',
      m: 'mobile-m',
      s: 'mobile-s'
    };
    // TODO: Make new logic with hover effect
    const getSizeClasses = computed(() => {
      return [
        desktopSizesMap[props.sizeDesktop],
        mobileSizesMap[props.sizeMobile]
      ];
    });

    const getProductPrice = computed(() => {
      const price = (props as { regularPrice: number }).regularPrice;
      return (props as { isDynamicPrice: boolean }).isDynamicPrice
        ? i18n.t('From {price}', {
            price
          })
        : price;
    });

    const getProductBasePrice = computed(() => {
      const price = props.basePrice;
      return props.isDynamicPrice
        ? i18n.t('From {price}', {
            price
          })
        : price;
    });

    const isMerging = computed(() => cartStore.is_data_loading);

    const isHoverImageAvailable = computed(() => props.hoverImage !== null);

    const isEditorialDataAvailable = computed(() => {
      return props.editorialData !== null && props.editorialData.length;
    });

    const isOutOfStock = computed(() => {
      return (
        props.stockStatus === productStatuses.out_of_stock || !props.isSalable
      );
    });

    const goToProduct = (link) => {
      router.push(link);
    };

    const isAddingToCart = ref(false);
    const addToCart = async () => {
      isAddingToCart.value = true;

      try {
        await addItemToCart({ product: props.product, quantity: 1 });
      } catch (error) {
        sendNotification({
          id: Symbol('product_add_error'),
          message: String(i18n.t('This product is currently unavailable')),
          persist: false,
          type: 'danger',
          area: 'top'
        });
        await router.push({ name: 'error' });
      } finally {
        isAddingToCart.value = false;
      }
    };

    onMounted(() => {
      // Making svg icons not accessible to pass the Level Access test
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const icons = productCardRef.value?.$el.querySelectorAll('span.sf-icon');
      if (icons && icons.length) {
        icons.forEach((el) => {
          el.setAttribute('aria-hidden', 'true');
        });
      }
    });

    const isAddToCartButtonShown = computed(
      () =>
        !isOutOfStock.value &&
        !isMerging.value &&
        props.productType === 'SimpleProduct'
    );
    const isCreateThisSetButtonShown = computed(
      () =>
        !isOutOfStock.value &&
        !isMerging.value &&
        props.productType === 'BundleProduct'
    );

    return {
      isJpStore,
      goToProduct,
      getProductPrice,
      getProductBasePrice,
      isOutOfStock,
      isAdded,
      getSizeClasses,
      isDesktop,
      isHoverImageAvailable,
      isEditorialDataAvailable,
      cartStore,
      isMerging,
      isAddToCartButtonShown,
      addToCart,
      isAddingToCart,
      isCreateThisSetButtonShown,
      productCardRef
    };
  }
});
