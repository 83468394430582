var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-page mb-3md"},[_c('VaimoHeading',{staticClass:"text-center title mt-base mb-3md",attrs:{"heading":_vm.$t('Contact us'),"heading-level":"h1"}}),_vm._v(" "),_c('div',{staticClass:"contact-page__wrapper"},[_c('div',{staticClass:"customer-service mt-3md mb-3md"},[_c('p',{staticClass:"mt-3md"},[_vm._v(_vm._s(_vm.$t('Customer Service')))]),_vm._v(" "),_c('div',{staticClass:"contact-information"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.config.contactus_contact_information)}})]),_vm._v(" "),_c('div',{staticClass:"contact-method"},[(_vm.config.contactus_contact_phone)?_c('span',[_vm._v(_vm._s(_vm.config.contactus_contact_phone))]):_vm._e()])]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('form',{staticClass:"contact form pt-3md",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.sendContact(reset))}}},[_c('p',{staticClass:"pb-md"},[_vm._v(_vm._s(_vm.$t('* Required Fields')))]),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"lastname","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"text","name":"lastname","label":_vm.$t('Last name'),"placeholder":' ',"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"firstname","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"text","name":"lastname","label":_vm.$t('First name'),"placeholder":' ',"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"email","rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"label":_vm.$t('E-mail'),"placeholder":' ',"type":"email","name":"email","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"phone","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"label":_vm.$t('E-Mobile'),"placeholder":' ',"type":"text","name":"mobile","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__select form__element",attrs:{"name":"object","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfSelect',{staticClass:"input--with-label",attrs:{"label":_vm.$t('Object'),"name":"object","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":_vm.$t('Select an object')},model:{value:(_vm.form.object),callback:function ($$v) {_vm.$set(_vm.form, "object", $$v)},expression:"form.object"}},_vm._l((_vm.objectOptions),function(objectOption){return _c('SfSelectOption',{key:objectOption.value,attrs:{"value":objectOption.value}},[_vm._v("\n                "+_vm._s(objectOption.label)+"\n              ")])}),1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__element"},[_c('SfInput',{staticClass:"input--with-label",attrs:{"label":_vm.$t('Order ID'),"placeholder":' ',"type":"text","name":"order_id"},model:{value:(_vm.form.orderId),callback:function ($$v) {_vm.$set(_vm.form, "orderId", $$v)},expression:"form.orderId"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element mt-md",attrs:{"rules":"required|max:10000","name":"comment","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfTextarea',{attrs:{"label":_vm.$t('What’s on your mind?'),"name":"comment","cols":60,"rows":10,"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})]}}],null,true)})],1),_vm._v(" "),_c('VaimoButton',{staticClass:"form__button mt-3md",attrs:{"variant":"primary","type":"submit","full-width":true,"label-centered":true,"disabled":invalid || _vm.loading}},[_vm._v("\n          "+_vm._s(_vm.$t('Submit'))+"\n        ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }