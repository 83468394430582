




















import { SfHeading } from '@storefront-ui/vue';
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { getItem } from '~/helpers/asyncLocalStorage';

export default defineComponent({
  name: 'EditEmail',
  components: {
    SfHeading,
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  setup() {
    const { isAuthenticated } = useUser();
    const userEmail = ref(null);

    onMounted(async () => {
      const checkout = await getItem('checkout');
      if (checkout && checkout['user-account']) {
        const data = checkout['user-account'];
        userEmail.value = data.email;
      }
    });

    return {
      isAuthenticated,
      userEmail
    };
  }
});
