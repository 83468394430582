


















import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { computed, defineComponent } from '@nuxtjs/composition-api';

import { getUniqueId } from '~/diptyqueTheme/helpers/getUniqueId';
import type { ContentType } from '~/diptyqueTheme/types/contentful';

export default defineComponent({
  name: 'SectionsList',
  components: {
    HeroHeader: () =>
      import(
        /* webpackChunkName: "HeroHeader" */ 'templates/sections/HeroHeader/index.vue'
      ),
    InstitutionalPush: () =>
      import(
        /* webpackChunkName: "InstitutionalPush" */ 'templates/sections/InstitutionalPush/index.vue'
      ),
    BlockInternalNavigation: () =>
      import(
        /* webpackChunkName: "BlockInternalNavigation" */ 'templates/sections/BlockInternalNavigation/index.vue'
      ),
    LinkInternalNavigation: () =>
      import(
        /* webpackChunkName: "LinkInternalNavigation" */ 'templates/sections/LinkInternalNavigation/index.vue'
      ),
    ProductBanner: () =>
      import(
        /* webpackChunkName: "ProductBanner" */ 'templates/sections/ProductBanner/index.vue'
      ),
    ProductPush: () =>
      import(
        /* webpackChunkName: "ProductPush" */ 'templates/sections/ProductPush/index.vue'
      ),
    ProductSlider: () =>
      import(
        /* webpackChunkName: "ProductSlider" */ 'templates/sections/ProductSlider/index.vue'
      ),
    EditoProductPush: () =>
      import(
        /* webpackChunkName: "EditoProductPush" */ 'templates/sections/EditoProductPush/index.vue'
      ),
    HighlightEcom: () =>
      import(
        /* webpackChunkName: "HighlightEcom" */ 'templates/sections/HighlightEcom/index.vue'
      ),
    EcomPush: () =>
      import(
        /* webpackChunkName: "EcomPush" */ 'templates/sections/EcomPush/index.vue'
      ),
    SeoTextBlock: () =>
      import(
        /* webpackChunkName: "SeoTextBlock" */ 'templates/sections/SeoTextBlock/index.vue'
      ),
    EditorialPush: () =>
      import(
        /* webpackChunkName: "EditorialPush" */ 'templates/sections/EditorialPush/index.vue'
      ),
    EditorialBanner: () =>
      import(
        /* webpackChunkName: "EditorialBanner" */ 'templates/sections/EditorialBanner/index.vue'
      ),
    EditorialCard: () =>
      import(
        /* webpackChunkName: "EditorialCard" */ 'templates/sections/EditorialCard/index.vue'
      ),
    Gallery: () =>
      import(
        /* webpackChunkName: "Gallery" */ 'organisms/product/Gallery/ProductGallery.vue'
      ),
    Instalike: () =>
      import(
        /* webpackChunkName: "Instalike" */ 'templates/sections/Instalike/index.vue'
      ),
    CandleDuet: () =>
      import(
        /* webpackChunkName: "CandleDuet" */ 'organisms/VaimoCandleDuet.vue'
      ),
    Storytelling: () =>
      import(
        /* webpackChunkName: "Storytelling" */ 'templates/sections/StoryTellingContent/index.vue'
      ),
    Testimony: () =>
      import(
        /* webpackChunkName: "Testimony" */ 'templates/sections/Testimony/index.vue'
      ),
    PostCard: () =>
      import(
        /* webpackChunkName: "PostCard" */ 'templates/sections/PostCard/index.vue'
      ),
    Placeholder: () =>
      import(
        /* webpackChunkName: "Placeholder" */ 'templates/sections/PlaceholderSection.vue'
      ),
    CommitmentPush: () =>
      import(
        /* webpackChunkName: "CommitmentPush" */ 'templates/sections/CommitmentPush/index.vue'
      ),
    CollectionSlider: () =>
      import(
        /* webpackChunkName: "CollectionSlider" */ 'templates/sections/CollectionSlider/index.vue'
      ),
    CampaignPush: () =>
      import(
        /* webpackChunkName: "CampaignPush" */ 'templates/sections/CampaignPush/index.vue'
      ),
    ExploreSlider: () =>
      import(
        /* webpackChunkName: "ExploreSlider" */ 'templates/sections/ExploreSlider/index.vue'
      ),
    Flexible: () =>
      import(
        /* webpackChunkName: "Flexible" */ 'templates/sections/Flexible/index.vue'
      ),
    GoodToKnowPanel: () =>
      import(
        /* webpackChunkName: "GoodToKnowPanel" */ 'templates/sections/GoodToKnowPanel/index.vue'
      ),
    ArticleSection: () =>
      import(
        /* webpackChunkName: "ArticleSection" */ 'templates/sections/ArticleSection/index.vue'
      ),
    Paragraph: () =>
      import(
        /* webpackChunkName: "Paragraph" */ 'templates/sections/Paragraph/index.vue'
      ),
    ListenToTheScent: () =>
      import(
        /* webpackChunkName: "ListenToTheScent" */ 'templates/sections/ListenToTheScent/index.vue'
      ),
    AudioStory: () =>
      import(
        /* webpackChunkName: "AudioStory" */ 'templates/sections/AudioStory/index.vue'
      ),
    Spotify: () =>
      import(
        /* webpackChunkName: "Spotify" */ 'templates/sections/Spotify/index.vue'
      ),
    CollectionPush: () =>
      import(
        /* webpackChunkName: "CollectionPush" */ 'templates/sections/CollectionPush/index.vue'
      ),
    BaseSection: () =>
      import(
        /* webpackChunkName: "BaseSection" */ 'templates/sections/BaseSection.vue'
      ),
    ProductSingleSection: () =>
      import(
        /* webpackChunkName: "ProductSingleSection" */ 'templates/sections/ProductSingleSection.vue'
      ),
    ProductsSection: () =>
      import(
        /* webpackChunkName: "ProductsSection" */ 'templates/sections/ProductsSection.vue'
      ),
    BundleProduct: () =>
      import(
        /* webpackChunkName: "BundleProduct" */ '~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue'
      )
  },
  props: {
    items: {
      type: Array as PropType<ContentType[]>,
      required: false,
      default: () => []
    },
    config: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    pageName: {
      type: String,
      required: false,
      default: ''
    },
    pageType: {
      type: String,
      required: false,
      default: ''
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props) {
    const sections = computed(() => {
      const _sections = Array.isArray(props.items)
        ? props.items.filter(Boolean)
        : [];

      if (_sections.length) {
        _sections[0].isFirstSection = true;
      }

      return _sections;
    });

    const getSectionKey = (section: ContentType, idx: string | number) => {
      const type = section.__typename;
      const id = section.sys?.id || getUniqueId();

      return [type, id, idx].join('__');
    };

    return {
      sections,
      getSectionKey
    };
  }
});
