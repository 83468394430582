



























import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'KeyFigures',
  components: {
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const figures = computed(() =>
      props?.sectionData?.itemsCollection?.items.filter(
        (i) => i.heading || i.descr
      )
    );
    const sectionType = computed(() =>
      props?.sectionData?.type?.split(' - ')[1].toLowerCase()
    );
    const headerSectionDataExist = computed(
      () =>
        !!(props?.sectionData?.heading ||
        props?.sectionData?.descr ||
        (props?.sectionData?.buttonCollection &&
          props?.sectionData?.buttonCollection.items.length > 0))
    );

    return {
      figures,
      sectionType,
      headerSectionDataExist
    };
  }
});
