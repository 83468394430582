var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isBannerHidden && _vm.promoBannerText)?_c('div',{ref:"promoBannerRef",staticClass:"promo-banner",class:{
    opened: _vm.showPromo === 'yes',
    'with-countdown': _vm.isCountDownEnabled
  },style:({
    background: _vm.promoBackgroundColor
  }),attrs:{"role":"complementary","aria-roledescription":_vm.promoBannerText}},[_c('div',{ref:"promoBannerContentRef",staticClass:"promo-banner__content",class:{ 'with-countdown': _vm.isCountDownEnabled },style:({
      color: _vm.promoTextColor
    })},[(_vm.showPromo === 'yes')?_c('div',[(_vm.isCountDownEnabled)?_c('div',{staticClass:"promo-banner__countdown"},[_c('CountdownTimer',{attrs:{"one-line":"","countdown-date":_vm.countDownData.countdownEndDataTime,"disable-seconds":_vm.countDownData.countdownShowHideSeconds},on:{"setExpiredFlag":_vm.setCountdownExpiredFlag}})],1):_vm._e(),_vm._v(" "),(_vm.promoBannerLink)?_c('SfLink',{staticClass:"promo-banner__text",attrs:{"link":_vm.localePath(_vm.promoBannerLink),"role":"link","aria-roledescription":_vm.promoBannerText,"aria-label":_vm.promoBannerText}},[_vm._v("\n        "+_vm._s(_vm.promoBannerText)+"\n      ")]):_c('p',{staticClass:"promo-banner__text"},[_vm._v("\n        "+_vm._s(_vm.promoBannerText)+"\n      ")]),_vm._v(" "),_c('button',{staticClass:"promo-banner__button",attrs:{"type":"button","role":"button","aria-roledescription":_vm.$t('Svg Image'),"aria-label":_vm.$t('Close')},on:{"click":_vm.closePromoBanner}},[_c('i',{staticClass:"icon-close",style:({
            'border-color': _vm.promoTextColor
          }),attrs:{"aria-label":_vm.$t('Close'),"role":"img"}})])],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }