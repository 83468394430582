





























































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';

import { useConfig } from '~/composables';
import { useApi } from '~/composables/useApi';
import { useScreenSize } from '~/diptyqueTheme/composable';
import sampleProductsList from '~/diptyqueTheme/customQueries/magento/sampleProductsList';
import { useAddToCart } from '~/helpers/cart/addToCart';
import type { Product } from '~/modules/catalog/product/types';
import useCart from '~/modules/checkout/composables/useCart';
import { useCartStore } from '~/modules/checkout/stores/cart';

declare type SampleProducts = {
  attributes: any;
  items: Product[];
};

interface SampleProductsInterface {
  getSampleProducts: SampleProducts | null;
}

export default defineComponent({
  name: 'SampleProducts',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoCheckbox: () => import('molecules/VaimoCheckbox.vue'),
    SfLoader
  },
  setup() {
    const { app } = useContext();
    const { cacheableQuery } = useApi();
    const { isDesktop } = useScreenSize();
    const { addItemToCart } = useAddToCart();
    const { removeItem, cart, load } = useCart();
    const { config } = useConfig();
    const samplesCartAmount = computed(
      () => config.value?.samples_samples_cart_amount
    );
    const sampleProducts = ref([]);
    const maxProductAmount = computed(() => {
      return samplesCartAmount.value;
    });
    const DESKTOP_SAMPLES_AMOUNT = 4;
    const MOBILE_SAMPLES_AMOUNT = 3;
    const NOT_FOUND = -1;
    const loading = ref(false);
    const selectedProductsSkus = ref([]);
    const attributeLabels = ref([]);
    const getAttributeLabel = (attributeCode) => {
      const defaultAttributeLabels = {
        material_fragrance_label_web: 'Floral Raw Materials:',
        olfactory_collection_label_web: 'Olfactory collection:'
      };
      const attribute = attributeLabels.value.find(
        (attribute) => attribute.attribute_code === attributeCode
      );
      const defaultLabel = defaultAttributeLabels[attributeCode] ?? '';

      return attribute ? attribute.label + ':' : app.i18n.t(defaultLabel);
    };
    const cartStore = useCartStore();
    const cartLoading = computed(() => cartStore.initial_loading);

    onMounted(async () => {
      if (!cart.value.id) {
        await load();
      }
      selectedProductsSkus.value = [...checkoutSamplesInCart.value];
      app.$bus.$on('remove-sample', (product) => {
        const selectedSKU = product?.product?.sku;
        selectedProductsSkus.value = selectedProductsSkus.value.filter(
          (sku) => sku !== selectedSKU
        );
      });
    });

    useFetch(async () => {
      if (!cart.value.id) {
        await load();
      }
      const { data } = await cacheableQuery<SampleProductsInterface>(
        sampleProductsList,
        {
          cart_id: cart.value.id,
          attribute_codes: [
            'material_fragrance_label_web',
            'olfactory_collection_label_web'
          ]
        }
      );
      sampleProducts.value = data?.getSampleProducts?.items ?? [];
      attributeLabels.value = data?.getSampleProducts?.attributes ?? [];
    });

    const cartItemsN = computed(() => {
      return (
        cart.value?.items?.map((i) => {
          return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            additionalData: i?.additional_data && JSON.parse(i?.additional_data),
            product: i.product,
            quantity: i.quantity
          };
        }) || []
      );
    });

    const checkoutSamplesInCart = computed(() => {
      return cartItemsN.value
        .filter((i) => i.additionalData?.is_checkout_sample)
        .map((i) => i?.product?.sku)
        .filter((i) => i);
    });

    const isShowMoreBtnVisible = computed(() => {
      if (isDesktop.value) {
        return sampleProducts.value.length > DESKTOP_SAMPLES_AMOUNT;
      }
      return sampleProducts.value.length > MOBILE_SAMPLES_AMOUNT;
    });

    const isSelectedMax = computed(() => {
      return checkoutSamplesInCart.value?.length >= maxProductAmount.value;
    });

    const selectingUID = ref(null);
    const unselectingUID = ref(null);
    const processingUID = computed(() => {
      return selectingUID.value || unselectingUID.value;
    });

    const selectProduct = async (product) => {
      if (processingUID.value) {
        return;
      }
      loading.value = true;
      if (checkoutSamplesInCart.value.includes(product.sku)) {
        unselectingUID.value = product.sku;
        const index = selectedProductsSkus.value?.findIndex(
          (sku) => sku === product.sku
        );
        if (index !== NOT_FOUND) {
          selectedProductsSkus.value.splice(index, 1);
        }
        await removeItem({
          product: cart.value?.items.find(
            (cartItem) => cartItem.product.uid === product.uid
          )
        }).finally(() => {
          unselectingUID.value = null;
          loading.value = false;
        });
      } else if (!isSelectedMax.value) {
        selectedProductsSkus.value.push(product.sku);
        selectingUID.value = product.sku;
        addItemToCart({ product, quantity: 1 }).finally(() => {
          selectingUID.value = null;
          loading.value = false;
        });
      }
    };

    const getMaterialFragranceLabel = (product: Product): string | null => {
      if (!product || !product['material_fragrance_label_web']) {
        return null;
      }

      return product['material_fragrance_label_web'];
    };

    const getOlfactoryLabels = (product: Product): string | null => {
      if (!product || !product['olfactory_collection_label_web']) {
        return null;
      }

      const collectionLabels = product['olfactory_collection_label_web'];

      return collectionLabels?.map((label) => label.label)?.join(',');
    };

    const showAll = ref(false);
    const shownProducts = computed(() => {
      const sliceValue = isDesktop.value
        ? DESKTOP_SAMPLES_AMOUNT
        : MOBILE_SAMPLES_AMOUNT;

      const products = showAll.value
        ? sampleProducts.value
        : sampleProducts.value.slice(0, sliceValue);

      return products;
    });

    const onShowMoreClick = () => {
      showAll.value = !showAll.value;
    };

    return {
      sampleProducts,
      shownProducts,
      showAll,
      onShowMoreClick,
      getOlfactoryLabels,
      getMaterialFragranceLabel,
      selectProduct,
      selectingUID,
      unselectingUID,
      processingUID,
      selectedProductsSkus,
      isSelectedMax,
      checkoutSamplesInCart,
      isShowMoreBtnVisible,
      loading,
      getAttributeLabel,
      samplesCartAmount,
      cartLoading
    };
  }
});
