
























import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Quote',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const data = computed(() => {
      const _sectionData = props.sectionData;

      if (_sectionData.visual) {
        if (
          !_sectionData.visual.ratio ||
          _sectionData.visual.ratio === 'Default for current module'
        ) {
          _sectionData.visual.ratio = '3:4';
        }
        if (
          !_sectionData.visual.ratioD ||
          _sectionData.visual.ratioD === 'Default for current module'
        ) {
          _sectionData.visual.ratioD = '3:4';
        }
        if (
          !_sectionData.visual.pos ||
          _sectionData.visual.pos === 'Default for current module'
        ) {
          _sectionData.visual.pos = 'Top';
        }
        if (
          !_sectionData.visual.posD ||
          _sectionData.visual.posD === 'Default for current module'
        ) {
          _sectionData.visual.posD = 'Right';
        }
      }
      return _sectionData;
    });

    const textAlign = computed(() => {
      return props.sectionData?.type === 'Quote - Centered Text'
        ? 'text-center'
        : null;
    });

    return {
      textAlign,
      data
    };
  }
});
