


















































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

import { useUser } from '~/modules/customer/composables/useUser';

import { LOYALTY_PUSH_BANNER_TEXT, LOYALTY_PUSH_WITH_ICONS } from './consts';

export default defineComponent({
  name: 'LoyaltyPush',
  components: {
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    AccountRegistrationForm: () =>
      import('organisms/account/parts/AccountRegistrationForm.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { isAuthenticated } = useUser();
    const isRegistrationShowed = ref(false);

    const items = computed(
      () => props.sectionData?.itemsCollection?.items || []
    );

    const footerVisible = computed(
      () =>
        props.sectionData?.buttonCollection?.items.filter((b) => b).length > 0
    );

    const headerVisible = computed(
      () => !!(props.sectionData.heading || props.sectionData.descr)
    );

    const loyaltyPushClasses = computed(() => {
      switch (props.sectionData?.type) {
        case LOYALTY_PUSH_WITH_ICONS:
          return 'loyalty-push__with-icons';
        case LOYALTY_PUSH_BANNER_TEXT:
          return 'loyalty-push__banner-text';
        default:
          return '';
      }
    });

    const getKey = (item) => item?.sys?.id;
    const hasIcon = (item) => item?.icon?.url;
    const hasValue = (item) => item?.value;

    const isLoyaltyPushBannerText = computed(
      () => props.sectionData?.type === LOYALTY_PUSH_BANNER_TEXT
    );

    const isLoyaltyPushWithIcons = computed(
      () => props.sectionData?.type === LOYALTY_PUSH_WITH_ICONS
    );

    const isRegistrationButtonAvailable = computed(() => {
      return props.sectionData?.buttonCollection?.items.filter(
        (b) => b?.buttonToShowAccountCreationForm
      );
    });

    return {
      footerVisible,
      headerVisible,
      items,
      loyaltyPushClasses,
      getKey,
      hasIcon,
      hasValue,
      isLoyaltyPushBannerText,
      isRegistrationButtonAvailable,
      isAuthenticated,
      isRegistrationShowed,
      isLoyaltyPushWithIcons
    };
  }
});
