<template>
  <div class="applied-taxes">
    <SfProperty
      :name="$t('Tax')"
      :value="validateFraction(getTaxesAmount)"
      class="sf-property--full-width property"
      v-if="!isJpStore"
    />
    <div class="applied-taxes__list" :class="{'pl-base': !isJpStore}">
      <div v-for="tax in appliedTaxes" :key="tax.label">
        <SfProperty
          v-if="tax.rates"
          :key="tax.rates[0].title"
          :name="`${tax.rates[0].title} (${tax.rates[0].percent}%)`"
          :value="validateFraction(tax.amount.value)"
          class="sf-property--full-width property"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfProperty } from '@storefront-ui/vue';

import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import useCart from '~/modules/checkout/composables/useCart';
import { useStore } from '~/composables';

export default defineComponent({
  name: 'Taxes',
  components: {
    SfProperty
  },
  setup() {
    const { cart } = useCart();
    const { isJpStore } = useStore();

    const appliedTaxes = computed(() => {
      if (cart.value?.prices?.applied_taxes?.length) {
        return cart.value?.prices?.applied_taxes ?? [];
      }
    });
    const getTaxesAmount = computed(() => +cart.value?.prices?.tax_amount);
    return {
      appliedTaxes,
      getTaxesAmount,
      validateFraction,
      isJpStore
    };
  }
});
</script>

<style scoped></style>
