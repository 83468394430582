





































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'InstitutionalPush__DesktopDeclination3',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoBadge: () => import('atoms/VaimoBadge.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
  },
  props: {
    declinationData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getBannerData, getCTAdata } = useDeclination(props.declinationData);

    return {
      getBannerData,
      getCTAdata
    };
  }
});
