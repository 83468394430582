
































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'StoryTellingContent__desktopDeclination1',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    declinationData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getBannerData } = useDeclination(props.declinationData);
    const getDescriptionPosition = computed(() => {
      const positionMap = {
        'Description before Visual': 'before',
        'Description after Visual': 'after'
      };
      return positionMap[props.declinationData.descrPosition] || 'before';
    });
    return {
      getBannerData,
      getDescriptionPosition
    };
  }
});
