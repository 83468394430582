



















import { defineComponent, computed, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'LoadMoreProducts',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    pagination: {
      required: true,
      type: Object,
      default: () => ({})
    },
    productsTotal: {
      required: true,
      type: Number,
      default: () => 0
    },
    loading: Boolean
  },
  emits: ['load-more-on-category'],
  setup(props, { emit }) {
    const nthPage = ref(1);
    const amountOfProductsOnPage = computed(() => props.productsTotal);

    const ifAmountsEquals = computed(() => {
      return amountOfProductsOnPage.value === props.pagination.totalItems;
    });

    const loadProducts = () => {
      if (!ifAmountsEquals.value) {
        emit('load-more-on-category', ++nthPage.value);
      } else {
        // todo: add CTA text & link and possibility to manage it from BO (when the task for this will be ready)
      }
    };

    return {
      loadProducts,
      ifAmountsEquals,
      amountOfProductsOnPage
    };
  }
});
