<template>
  <div :style="wrapperStyles" class="block">
    <SpyWrapper :tag="aspectTag" :class="aspectClasses" class="block__aspect">
      <SpyWrapper :tag="aspectTag" class="block__aspect-content" >
        <video
          v-if="getSettings._videoBg.active"
          :autoplay="getSettings._videoBg.autoplay"
          :muted="getSettings._videoBg.muted"
          :loop="getSettings._videoBg.loop"
          class="block__video"
        >
          <source :src="getSettings._videoBg.url" type="video/ogg" />
        </video>

        <div
          :class="contentClasses"
          :style="contentStyles"
          class="block__content"
        >
          <div
            :class="contentColClasses"
            class="block__content-header block__col-content"
          >
            <slot name="header"></slot>
          </div>

          <!-- TODO: Предусмотреть вариант когда баннера вообще нету -->
          <div
            v-if="$slots.banner"
            :class="bannerColClasses"
            class="block__content-banner block__col-banner cursor-pointer"
            @click="goToLink(localePath(getLink))"
          >
            <slot name="banner"></slot>
          </div>

          <div
            :class="contentColClasses"
            class="block__content-main block__col-content"
          >
            <slot></slot>
          </div>
        </div>
      </SpyWrapper>
    </SpyWrapper>
  </div>
</template>

<script>
import { defineComponent, computed, useRouter } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'BlockWrapper',
  components: {
    SpyWrapper: {
      functional: true,
      props: ['tag'],
      render(h, context) {
        const { children, props, data } = context;
        if (props.tag) {
          return h(props.tag, data, children);
        }
        return children;
      }
    }
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const router = useRouter();
    const defaultValues = {
      template: { mobile: 'default', desktop: 'inherit' },
      ratio: { mobile: 'none', desktop: 'inherit' },
      bannerRatio: { mobile: '1:1', desktop: 'inherit' },
      pxBanner: { mobile: 'none', desktop: 'inherit' },
      pxContent: { mobile: 'none', desktop: 'inherit' },
      fsBanner: { mobile: 'none', desktop: 'inherit' },
      fsContent: { mobile: 'none', desktop: 'inherit' },
      alignContent: { mobile: 'default', desktop: 'inherit' },
      maxWidthContent: { mobile: 'auto', desktop: 'inherit' },
      gap: { mobile: 'none', desktop: 'inherit' },
      ptBlock: { mobile: '', desktop: 'inherit' },
      pbBlock: { mobile: '', desktop: 'inherit' },
      pxBlock: { mobile: '', desktop: 'inherit' },
      background: { mobile: {}, desktop: 'inherit' }
    };

    const mobileSettings = computed(
      () => props.sectionData.mobileBlockSettings || {}
    );
    const desktopSettings = computed(
      () => props.sectionData.desktopBlockSettings || {}
    );

    const getLink = computed(
      () => props.sectionData?.buttonsCollection?.items?.[0].link
    );

    const goToLink = (link) => {
      router.push(link);
    };

    const preprocessBackground = (settings) => {
      const bg =
        settings.background?.mobile || settings.background?.desktop || {};
      const imgWidth = bg.image?.width;
      const imgHeight = bg.image?.height;
      const focalX = bg?.type === 'image' && bg?.focal?.focalPoint?.x;
      const focalY = bg?.type === 'image' && bg?.focal?.focalPoint?.y;
      settings._bgApplyTo = bg?.width || 'content';

      settings._imageBg = {
        active: !!(bg?.type === 'image' && (bg?.image?.url || bg?.color)),
        url: bg?.type === 'image' && bg?.image?.url,
        size: bg?.type === 'image' && bg?.size,
        color: bg?.type === 'image' && bg?.color,
        positionX:
          focalX && imgWidth
            ? Math.floor((focalX / imgWidth) * 100) + '%'
            : 'center',
        positionY:
          focalY && imgHeight
            ? Math.floor((focalY / imgHeight) * 100) + '%'
            : 'center'
      };

      settings._videoBg = {
        active: !!(bg?.type === 'video' && bg?.video?.url),
        url: bg?.type === 'video' && bg?.video?.url,
        loop: !!(bg?.type === 'video' && bg?.videoLoop),
        autoplay: !!(bg?.type === 'video' && bg?.videoAutoplay),
        muted: !!(bg?.type === 'video' && !bg?.videoSound)
      };
    };

    const preprocessHelperSettings = (settings) => {
      settings._colsReversed = {
        mobile: !!settings.template.mobile.includes('banner right'),
        desktop: !!settings.template.desktop.includes('banner right')
      };

      settings._fsBanner_screenPaddingOff = {
        mobile: settings.fsBanner.mobile === 'fullscreen out',
        desktop: settings.fsBanner.desktop === 'fullscreen out'
      };

      settings._fsContent_screenPaddingOff = {
        mobile: settings.fsContent.mobile === 'fullscreen out',
        desktop: settings.fsContent.desktop === 'fullscreen out'
      };

      if (settings.fsBanner.mobile === 'fullscreen out')
        settings.fsBanner.mobile = 'fullscreen';
      if (settings.fsContent.mobile === 'fullscreen out')
        settings.fsContent.mobile = 'fullscreen';
      if (settings.fsBanner.desktop === 'fullscreen out')
        settings.fsBanner.desktop = 'fullscreen';
      if (settings.fsContent.desktop === 'fullscreen out')
        settings.fsContent.desktop = 'fullscreen';
    };

    const getSettings = computed(() => {
      const settings = { ...defaultValues };

      for (let setting in settings) {
        if (mobileSettings.value?.[setting] !== undefined)
          settings[setting].mobile = mobileSettings.value[setting];
        if (desktopSettings.value?.[setting] !== undefined)
          settings[setting].desktop = desktopSettings.value[setting];
        if (settings[setting].desktop === 'inherit')
          settings[setting].desktop = settings[setting].mobile;
      }

      preprocessBackground(settings);
      preprocessHelperSettings(settings);

      return settings;
    });

    // TODO: Activate this classes in some other way to be allowed to used as 'comb'+'ined' names in JS logic with no need to list them here
    const paddingsMap = [
      'pt-0',
      'pt-none',
      'pt-xs',
      'pt-sm',
      'pt-base',
      'pt-md',
      'pt-2md',
      'pt-3md',
      'pt-4md',
      'pt-lg',
      'pt-xl',
      'pb-0',
      'pb-none',
      'pb-xs',
      'pb-sm',
      'pb-base',
      'pb-md',
      'pb-2md',
      'pb-3md',
      'pb-4md',
      'pb-lg',
      'pb-xl',
      'px-0',
      'px-none',
      'px-xs',
      'px-sm',
      'px-base',
      'px-md',
      'px-2md',
      'px-3md',
      'px-4md',
      'px-lg',
      'px-xl',
      'px-4xl',
      'desktop:px-0',
      'desktop:px-none',
      'desktop:px-xs',
      'desktop:px-sm',
      'desktop:px-base',
      'desktop:px-md',
      'desktop:px-2md',
      'desktop:px-3md',
      'desktop:px-4md',
      'desktop:px-lg',
      'desktop:px-xl',
      'desktop:px-4xl',
      'desktop:pl-0',
      'desktop:pr-0'
    ];

    const getRespClasses = (
      setting,
      classPrefix = '',
      ignoreMobileNone = false
    ) => {
      let list = [];

      const getValueClass = (value, prefix) => {
        if (typeof value === 'string') {
          value = value?.replaceAll(' ', '-')?.replaceAll(':', '-');
          return prefix + value;
        }
        if (typeof value === 'boolean') {
          if (value) return prefix;
        }
        return null;
      };

      let mobileValue = getSettings.value[setting]?.mobile;
      let _mobileValue =
        ignoreMobileNone && mobileValue === 'none' ? false : mobileValue;
      let mobileClass = getValueClass(_mobileValue, classPrefix);
      if (mobileClass) list.push(mobileClass);

      let desktopValue = getSettings.value[setting]?.desktop;
      if (desktopValue !== mobileValue) {
        let desktopClass = getValueClass(desktopValue, classPrefix);
        if (desktopClass) list.push(desktopClass + '--dt');
      }

      return list;
    };

    const getImageStyles = computed(() => {
      const styles = {};
      const imageBg = getSettings.value._imageBg;
      if (!imageBg?.active) return styles;

      if (imageBg.color) styles.backgroundColor = imageBg.color;
      if (imageBg.url) {
        styles.background = `url(${imageBg.url})`;
        styles.backgroundPositionX = imageBg.positionX;
        styles.backgroundPositionY = imageBg.positionY;
        if (imageBg.size) styles.backgroundSize = imageBg.size;
      }

      return styles;
    });

    const getPaddingClasses = (paddingType, columnName) => {
      const list = [];
      columnName =
        columnName.charAt(0).toUpperCase() + columnName.slice(1).toLowerCase();
      let settingName = paddingType + columnName;

      let pxMobile = getSettings.value[settingName]?.mobile;
      let pxDesktop = getSettings.value[settingName]?.desktop;
      if (pxMobile) list.push(paddingType + '-' + pxMobile);
      if (pxDesktop && pxDesktop !== pxMobile)
        list.push('desktop:' + paddingType + '-' + pxDesktop);

      if (
        paddingType === 'px' &&
        getSettings.value['_fs' + columnName + '_screenPaddingOff']?.desktop
      ) {
        let disablePaddingClass = getSettings.value._colsReversed.desktop
          ? 'desktop:pl-0'
          : 'desktop:pr-0';
        list.push(disablePaddingClass);
      }

      return list;
    };

    const wrapperStyles = computed(() => {
      return getSettings.value?._bgApplyTo === 'section'
        ? getImageStyles.value
        : '';
    });

    const aspectClasses = computed(() => {
      return getRespClasses('ratio', 'aspect-');
    });

    const aspectTag = computed(() => {
      return aspectClasses.value?.length ? 'div' : false;
    });

    const contentClasses = computed(() => {
      const list = [];
      list.push(...getRespClasses('template', 'tpl-'));
      list.push(...getPaddingClasses('pt', 'block'));
      list.push(...getPaddingClasses('pb', 'block'));
      list.push(...getPaddingClasses('px', 'block'));

      const tplMobile = getSettings.value?.template?.mobile;
      const tplDesktop = getSettings.value?.template?.desktop;
      const colsMobile = ['default'].includes(tplMobile) ? 1 : 2;
      const colsDesktop = ['default'].includes(tplDesktop) ? 1 : 2;
      list.push('tpl-cols-' + colsMobile);
      if (colsMobile !== colsDesktop)
        list.push('tpl-cols-' + colsDesktop + '--dt');

      const isRTLmobile = tplMobile.includes('banner right');
      const isRTLdesktop = tplDesktop.includes('banner right');
      if (isRTLmobile) list.push('rtl');
      if (isRTLdesktop && !isRTLmobile) list.push('rtl--dt');
      if (!isRTLdesktop && isRTLmobile) list.push('ltr--dt');

      if (tplMobile.includes('slim banner')) list.push('tpl-banner-slim');
      if (tplDesktop !== tplMobile && tplDesktop.includes('slim banner'))
        list.push('tpl-banner-slim--dt');

      if (tplMobile.includes('wide banner')) list.push('tpl-banner-wide');
      if (tplDesktop !== tplMobile && tplDesktop.includes('wide banner'))
        list.push('tpl-banner-wide--dt');

      if (!props.sectionData?.banner) list.push('py-0');
      return list;
    });

    const contentStyles = computed(() => {
      return getSettings.value?._bgApplyTo === 'content'
        ? getImageStyles.value
        : '';
    });

    const bannerColClasses = computed(() => {
      const list = [];
      list.push(...getRespClasses('fsBanner', 'tpl-', true));
      list.push(...getRespClasses('_colsReversed', 'col-reversed'));
      list.push(...getPaddingClasses('px', 'banner'));
      return list;
    });

    const contentColClasses = computed(() => {
      let list = [];
      list.push(...getRespClasses('fsContent', 'tpl-', true));
      list.push(...getRespClasses('_colsReversed', 'col-reversed'));
      list.push(...getPaddingClasses('px', 'content'));
      return list;
    });

    return {
      getSettings,
      wrapperStyles,
      contentClasses,
      contentStyles,
      aspectTag,
      aspectClasses,
      bannerColClasses,
      contentColClasses,
      goToLink,
      getLink
    };
  }
});
</script>

<style lang="scss" scoped>
//@import '@/diptyqueTheme/assets/styles/templates/blockWrapper.scss';
</style>
