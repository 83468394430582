










import type { ComputedRef } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import {
  computed,
  defineComponent,
  onMounted,
  watch
} from '@nuxtjs/composition-api';

import { useGmoPayment } from '~/diptyqueTheme/modules/gmo-payment/composables/useGmoPayment';
import type { PaymentToken } from '~/diptyqueTheme/modules/gmo-payment/composables/useGmoPayment/types';

export default defineComponent({
  name: 'GMOMethodsProvider',
  emits: ['error'],
  setup(_props, { emit }) {
    const {
      loadCustomerPaymentTokens,
      vaultCards,
      loading,
      deleting,
      error,
      deletePaymentToken
    } = useGmoPayment();

    onMounted(async () => {
      await loadCustomerPaymentTokens();
    });

    const paymentMethods = computed(() => {
      return vaultCards.value.map((method: PaymentToken) => ({
        ...method,
        id: method.publicHash
      }));
    });

    const deletePaymentMethod = async (id: string) => {
      await deletePaymentToken(id);
    };

    watch(error, () => emit('error'), { deep: true });

    return { loading, deleting, deletePaymentMethod, paymentMethods };
  }
});
