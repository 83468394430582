





























import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import type { Country, OrderAddress } from '~/modules/GraphQL/types';
import { usePostcode } from '~/diptyqueTheme/composable/usePostcode';
import { usePhoneMask } from '~/diptyqueTheme/composable/usePhoneMask';
import { useStore } from '~/composables';

export default defineComponent({
  name: 'OrderInformationAddress',
  props: {
    address: {
      type: Object as PropType<OrderAddress>,
      required: true
    },
    countries: {
      type: Array as PropType<Country[]>,
      required: true
    }
  },
  setup(props) {
    const { setMask } = usePhoneMask();

    const { isHkMoCode } = usePostcode();
    const { isJpStore } = useStore();

    const countryName = computed(() => {
      return props.countries?.find(
        (country) => country.id === props.address.country_code
      )?.full_name_locale;
    });

    return {
      countryName,
      isHkMoCode,
      setMask,
      isJpStore
    };
  }
});
