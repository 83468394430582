










import { computed, PropType, ref } from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';

import { getBundleProducts } from '~/modules/catalog/product/getters/productGetters';
import { WithTypename } from '~/modules/catalog/product/types';
import { BundleProduct } from '~/modules/GraphQL/types';
export default defineComponent({
  name: 'BundleProducts',
  components: {
    BundleProductCard: () =>
      import(
        '~/modules/catalog/product/components/product-types/bundle/parts/BundleProductCard.vue'
      )
  },
  props: {
    product: {
      type: Object as PropType<WithTypename<BundleProduct>>,
      required: true
    }
  },

  setup(props) {
    const bundleProducts = computed(() => getBundleProducts(props.product));
    const filteredBundles = computed(() => {
      if (bundleProducts.value?.length) {
        return bundleProducts.value.filter(
          (bundle) => bundle?.options.length > 0
        );
      }
    });
    return {
      filteredBundles
    };
  }
});
