
































































import {
  computed,
  defineComponent,
  ref,
  useAsync,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import VaimoProductCard from 'organisms/VaimoProductCard.vue';

import { useImage, useProduct } from '~/composables';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';
import { useAddToCart } from '~/helpers/cart/addToCart';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'VaimoProductGrid',
  components: {
    VaimoProductCard
  },
  props: {
    productsSku: {
      required: true,
      type: Array
    },
    showModel: {
      required: false,
      type: Boolean,
      default: false
    },
    productsList: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { app } = useContext();
    const { getViewItemListDetails, getSelectItemDetails } =
      useGoogleTagManager();
    const { getMagentoImage } = useImage();
    const { getProductList, loading: productsLoading } = useProduct();
    const { isInCart } = useAddToCart();

    const productGroups = computed(() => {
      const _productsSKU = props.productsSku.filter((p) => p);
      if (typeof _productsSKU[0] === 'string') {
        return {
          groupTitle: 'Unnamed group',
          sku: _productsSKU
        };
      }
      return _productsSKU;
    });

    const activeGroup = ref();
    const activeGroupTitle = computed(() => activeGroup.value?.groupTitle);
    const activeGroupSKU = computed(() => activeGroup.value?.sku);
    const setActiveGroup = function (group) {
      activeGroup.value = group;
    };

    watch(activeGroup, async () => {
      if (activeGroupSKU.value.length) {
        await getProductsBySku(activeGroupSKU.value);

        if (products.value) {
          app.$gtm.push(getViewItemListDetails(products.value, 'slider'));
        }
      }
    });

    const products = ref();
    const getProductsBySku = async (sku_array: string[]) => {
      if (props.productsList?.length) {
        products.value = props.productsList;
        return;
      }
      try {
        const data = await getProductList({
          filter: {
            sku: {
              in: sku_array
            }
          }
        });
        products.value = data?.items.sort((item1, item2) => {
          const index1 = sku_array.indexOf(item1.sku);
          const index2 = sku_array.indexOf(item2.sku);
          return index1 - index2;
        });
      } catch (e) {
        console.error(e);
      }
    };
    const getProductLink = (product: Product): string => {
      return `${productGetters.getSlug(product, product?.categories?.[0])}`;
    };

    useAsync(async () => {
      setActiveGroup(productGroups.value[0]);
    });

    const onGtmSelectItem = (product: Product, index: number, event): void => {
      // Prevents GTM event from firing when clicking on the add to cart button
      if (event.target.closest('.vaimo-product-card__add-to-cart')) {
        return;
      }

      app.$gtm.push(getSelectItemDetails(product, index, 'slider'));
    };

    return {
      productGetters,
      customProductGetters,
      productGroups,
      activeGroupTitle,
      setActiveGroup,
      products,
      productsLoading,
      getProductLink,
      getMagentoImage,
      onGtmSelectItem,
      isInCart
    };
  }
});
