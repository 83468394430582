

























import {useContext, watch} from '@nuxtjs/composition-api';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import EditorialPushItem from 'templates/sections/EditorialPush/EditorialPushItem.vue';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';

export default defineComponent({
  name: 'VaimoMenuPush',
  components: {
    EditorialPushItem
  },
  props: {
    menuData: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { refs }) {
    const {
      app: { $gtm }
    } = useContext();
    const megaMenuMobileStore = useMegaMenuMobileStore();
    const getSliderItems = computed<object[]>(() => {
      const items = props.menuData?.megaMenu?.sliderCollection?.items ?? [];
      return items.filter((slide) => slide);
    });

    const closeSidebar = (item) => {
      if (item.visualLink) {
        megaMenuMobileStore.hide();
      }
    };

    /* toggleFocusOnLinks - function handles accessibility issue where hidden links in slides have focusable links
       This happens because slider is preloaded to the page */

    const toggleFocusOnLinks = (menuOpen) => {
      const carousel = refs.carousel as any;
      const visibleLinks = carousel.$el.querySelectorAll('.slick-slide:not(.slick-cloned) a.sf-link');
      const hiddenLinks = carousel.$el.querySelectorAll('.slick-slide .vaimo-banner a.sf-link');
      if (menuOpen) {
        visibleLinks.forEach(link => {
          link.setAttribute('tabindex', '0');
        });
      } else {
        hiddenLinks.forEach(link => {
          link.setAttribute('tabindex', '-1');
        });
      }
    }

    watch(() => megaMenuMobileStore.open, (menuOpen) => { toggleFocusOnLinks(menuOpen) })

    const getClickNavDetails = (menuItemName) => {
      $gtm.push({
        event: 'clickNav',
        mainLevel: menuItemName
      })
    }

    return {
      getSliderItems,
      closeSidebar,
      toggleFocusOnLinks,
      megaMenuMobileStore,
      getClickNavDetails
    };
  }
});
