






import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref
} from '@nuxtjs/composition-api';

import { useTransitionStore } from './stores/transitionStore';

export default defineComponent({
  name: 'LoadWhenVisible',
  props: {
    options: {
      type: Object,
      default: () => ({
        rootMargin: '0px 0px 100px 0px'
      })
    }
  },
  setup(props) {
    const isVisible = ref(false);
    const root = ref(null);
    const transitionStore = useTransitionStore();

    let observer = null;

    const handlePopstate = () => {
      transitionStore.setLoadInstantly(true);
    };

    const handleBeforeUnload = () => {
      transitionStore.setLoadInstantly(false);
    };

    onMounted(() => {
      window.addEventListener('popstate', handlePopstate);
      window.addEventListener('beforeunload', handleBeforeUnload);

      const OBSERVER_TIMEOUT = 10;
      setTimeout(() => {
        if (
          !transitionStore.loadInstantly &&
          process.browser &&
          'IntersectionObserver' in window
        ) {
          observer = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio <= 0) return;
            observer.unobserve(root.value);
            isVisible.value = true;
          }, props.options);
        } else {
          isVisible.value = true;
        }

        if (observer && root.value) {
          observer.observe(root.value);
        }
      }, OBSERVER_TIMEOUT);
    });

    onUnmounted(() => {
      if (observer && root.value) {
        observer.unobserve(root.value);
      }
      window.removeEventListener('popstate', handlePopstate);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    });

    return {
      root,
      isVisible
    };
  }
});
