

























import { defineComponent, onBeforeMount } from '@nuxtjs/composition-api';
import { SfLoader, SfSelect } from '@storefront-ui/vue';

import { useConfig } from '~/composables';
import { useXmas } from '~/diptyqueTheme/modules/xmas/composables/useXmas';

export default defineComponent({
  name: 'XmasForm',
  components: {
    SfSelect,
    SfLoader
  },
  setup(props, { root }) {
    const {
      getMaxBagsCount,
      getChosenBagsCount,
      updateQty,
      chosenBagsCount,
      maxBagsCount
    } = useXmas();
    const { config } = useConfig();

    onBeforeMount(async () => {
      root.$on('reload-xmas-form', async () => {
        await getChosenBagsCount();
        await getMaxBagsCount();
      });

      await getChosenBagsCount();
      await getMaxBagsCount();
    });

    const updateBagsQty = async (event) => {
      await updateQty(event.target.value);
    };

    return {
      config,
      updateBagsQty,
      chosenBagsCount,
      maxBagsCount
    };
  }
});
