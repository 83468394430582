


























































import {
  defineComponent,
  PropType,
  computed,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useAddToCart } from '~/helpers/cart/addToCart';
import type { Product } from '~/modules/catalog/product/types';
import { CategoryInterface } from '~/modules/catalog/category/types';
import useCart from '~/modules/checkout/composables/useCart';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';

export default defineComponent({
  name: 'VaimoCrossSellProduct',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
  },
  props: {
    product: {
      required: true,
      type: Object as PropType<Product>
    },
    withLoadCart: Boolean
  },
  setup(props) {
    const { addItemToCart, loading } = useAddToCart();
    const { app } = useContext();
    const { cart } = useCart();
    const router = useRouter();

    const addProductToCart = (product) => {
      addItemToCart(product);
    };

    const getProductQuantity = computed(() => {
      return cart.value?.items?.find(
        (item) => item.product.uid === props.product.uid
      )?.quantity;
    });

    const goToProduct = async (product) => {
      const link = `${productGetters.getSlug(
        product as Product,
        product?.categories?.[0] as CategoryInterface
      )}`;

      await router.push(app.localePath(link));
    };

    let modelsQuantity = props.product?.revamp_related_products?.length - 1;

    if (modelsQuantity < 1) {
      modelsQuantity = 0;
    }

    const getModelsQuantity = computed(
      () => modelsQuantity  || 0
    );

    return {
      addProductToCart,
      customProductGetters,
      getModelsQuantity,
      getProductQuantity,
      goToProduct,
      loading,
      productGetters,
      validateFraction
    };
  }
});
