


























import {
  computed,
  defineComponent,
  onMounted,
  useContext,
  ref
} from '@nuxtjs/composition-api';
import {Country, useStore} from '~/composables';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import { lsGet, lsSet, lsRemove } from '~/diptyqueTheme/composable/useLocalStorage';
import { usePostcode } from '~/diptyqueTheme/composable/usePostcode';
import { usePhoneMask } from '~/diptyqueTheme/composable/usePhoneMask';
import useShipping from '~/modules/checkout/composables/useShipping';
import useBilling from '~/modules/checkout/composables/useBilling';

export default defineComponent({
  name: 'EditAddress',
  components: {
    VaimoButton: () =>
      import('~/diptyqueTheme/components/atoms/VaimoButton.vue'),
    VaimoHeading: () =>
      import('~/diptyqueTheme/components/atoms/VaimoHeading.vue')
  },
  props: {
    addressType: {
      required: false,
      type: String,
      default: 'shipping-address'
    }
  },
  setup(props) {
    const address = ref(null);
    const { getCountriesFromLocalStorage } = useCountriesStore();
    const { isHkMoCode } = usePostcode();
    const countries = ref<Country[] | null>([]);

    const { setMask } = usePhoneMask();
    const { app } = useContext();
    const storeCode = computed(() => app.i18n.localeProperties.code);
    const { isJpStore } = useStore();
    const { loading: isShippingLoading } = useShipping();
    const { loading: isBillingLoading } = useBilling();

    const checkRegion = (region) => {
      if (typeof region === 'object' && region?.region && region?.region_code) {
        return true;
      } else if (typeof region === 'string') {
        return region;
      }
    };
    const prepareAddressString = computed(() => {
      if (countries.value.length && address.value) {
        const {
          firstname,
          lastname,
          street,
          postcode,
          city,
          country,
          country_code,
          telephone,
          apartment,
          region
        } = address.value;
        const countryCode = country_code || country?.code;
        const countryRegion = region?.name || region?.region || region;
        const getPhone = checkRegion(countryRegion)
          ? setMask(telephone)
          : telephone;
        const fullCountry = countries.value?.find(
          (c) => c.id === countryCode
        )?.full_name_locale;
        let addr = '';

        if (Array.isArray(street)) {
          addr = street.join(' ');
        } else if (apartment) {
          addr = street + ' ' + apartment;
        } else {
          addr = street;
        }

        if (isJpStore.value) {
          const availableRegions = countries.value?.find(
            (c) => c.id === countryCode
          )?.available_regions || [];
          const jpRegion = availableRegions.find(
            (item) => item.code === region
          )?.name;
          return `${lastname} ${firstname}, ${postcode} ${
            jpRegion || countryRegion
          } ${city} ${addr}, ${telephone}`;
        }

        if (checkRegion(countryRegion)) {
          return `${firstname} ${lastname}, ${addr},  ${
            postcode && !isHkMoCode(countryCode) ? postcode : ''
          } ${city}, ${
            countryRegion && !isHkMoCode(countryCode) ? countryRegion + ',' : ''
          } ${fullCountry}, ${getPhone}`;
        }

        return `${firstname} ${lastname}, ${addr}, ${
          postcode && !isHkMoCode(countryCode) ? postcode : ''
        } ${city}, ${fullCountry}, ${getPhone}`;
      }
    });
    const preparedHeading = computed(() =>
      props.addressType === 'shipping-address'
        ? 'Delivery Address'
        : 'Billing Address'
    );

    const checkLoading = async () => {
      // Wait until isShippingLoading && isBillingLoading becomes false
      if (!isShippingLoading.value && !isBillingLoading.value) {
        countries.value = getCountriesFromLocalStorage('all_countries');
        const vsfCheckoutData = lsGet('vsf-checkout');
        address.value =
          props.addressType === 'billing'
            ? vsfCheckoutData[props.addressType]['billingDetails']
            : vsfCheckoutData[props.addressType];
      } else {
        // eslint-disable-next-line no-magic-numbers
        setTimeout(checkLoading, 100); // Check again after 100 milliseconds
      }
    };

    onMounted(async () => {
      setTimeout(async () => {
        await checkLoading();
        // eslint-disable-next-line no-magic-numbers
      }, 2500);
    });

    return {
      prepareAddressString,
      preparedHeading
    };
  }
});
