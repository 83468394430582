






















































































import {
  computed,
  defineComponent,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import { useNewsletter, useStore } from '~/composables';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';
import { emailRegex } from '~/diptyqueTheme/helpers/regexes';

extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});

extend('required', {
  ...required,
  message: 'This field is required'
});

export default defineComponent({
  name: 'VaimoNewsletterSubscription',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoIcon: () => import('atoms/VaimoIcon.vue'),
    SubscriptionLegalNotice: () =>
      import('organisms/SubscriptionLegalNotice.vue'),
    SfInput,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    hideHeader: Boolean,
    label: String,
    forceShowSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    countdownEmail: Boolean,
    submitButtonText: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { updateSubscription, loading } = useNewsletter();
    const {
      app: { i18n, $gtm }
    } = useContext();
    const storeCode = i18n.localeProperties.code;
    const isStoreDe = storeCode === 'de_eu';
    const subscribeStatus = ref('');
    const subscribeStatusMsg = ref('');
    const expanded = ref(props.forceShowSubmit);
    const subscribeStatusDE = ref(false);
    const { isJpStore } = useStore();
    const showForm = computed(() => {
      if (!isStoreDe) return true;
      return !subscribeStatusDE.value;
    });

    const handleEmailInputClick = () => {
      if (subscribeStatus.value !== 'success') {
        expanded.value = true;
      }
      subscribeStatus.value = '';
    };

    const handleSubscribe = async (email, invalid = true) => {
      if (invalid) {
        return;
      }
      const result = await updateSubscription({ email: email });

      if (result === 'SUBSCRIBED') {
        subscribeStatus.value = 'success';
        subscribeStatusMsg.value = i18n.t(
          'Your email address has been saved'
        ) as string;
        expanded.value = false;
      } else if (result === null) {
        subscribeStatus.value = 'failed';
        subscribeStatusMsg.value = i18n.t(
          'Your email address is already registered'
        ) as string;
      } else if (result === 'NOT_ACTIVE') {
        subscribeStatus.value = 'success';
        subscribeStatusMsg.value = i18n.t('Email sent') as string;
        if (isStoreDe) {
          subscribeStatusDE.value = true;
        }
      }
    };

    const getTermsOfServiceLinks = computed(() => {
      return termsOfServiceLinks[i18n.locale];
    });

    const getClickFooterNewsletterDetails = () => {
      $gtm.push({
        event: props.countdownEmail
          ? 'clickCountdownNewsletter'
          : 'clickFooterNewsletter'
      });
    };

    const inputLabel = computed(() => {
      if (props.label === undefined) {
        return i18n.t('Enter your email address');
      } else if (props.label) {
        return props.label;
      }
      return '';
    });

    return {
      handleSubscribe,
      getTermsOfServiceLinks,
      handleEmailInputClick,
      subscribeStatus,
      subscribeStatusMsg,
      loading,
      expanded,
      getClickFooterNewsletterDetails,
      showForm,
      isStoreDe,
      isJpStore,
      inputLabel
    };
  },

  data() {
    return {
      email: ''
    };
  }
});
