

















import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import VaimoRadio from 'molecules/VaimoRadio.vue';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  name: 'SortWrapper',
  components: {
    VaimoRadio
  },
  props: {
    sortBy: {
      required: false,
      default: () => ({ nosto_personalized: 'ASC' }),
      type: Object
    }
  },
  emits: ['reloadProducts'],
  setup(props, { emit }) {
    const selectedSort = ref('');
    const uiHelpers = useUiHelpers();

    const isSelected = computed(() => {
      const selected = (getTransformedSortOptions.value ?? []).find(
        (selectedValue) => selectedValue.value === selectedSort.value
      );

      return selected.value ?? '';
    });

    const getTransformedSortOptions = computed(() => {
      return props.sortBy?.options.reduce((memo, cur) => {
        const label = cur.label.replace('Sort: ', '');
        memo.push({
          label,
          value: cur.value
        });
        return memo;
      }, []);
    });

    const applySort = (sortType: string) => {
      selectedSort.value = sortType;
      uiHelpers.changeSorting(selectedSort.value, false);
      emit('reloadProducts');
    };

    const resetSorting = () => {
      selectedSort.value = '';
      uiHelpers.changeSorting(selectedSort.value, false);
      emit('reloadProducts');
    };

    return {
      getTransformedSortOptions,
      applySort,
      resetSorting,
      isSelected,
      selectedSort
    };
  }
});
