

































































import {
  defineComponent,
  ref,
  onBeforeMount,
  useContext
} from '@nuxtjs/composition-api';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useNewsletter } from '~/composables';

export default defineComponent({
  name: 'AccountSubscriptions',
  components: {
    AccountHeading: () => import('organisms/account/parts/AccountHeading.vue'),
    VaimoCheckbox: () => import('molecules/VaimoCheckbox.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoBenefits: () => import('organisms/VaimoBenefits.vue'),
  },
  setup() {
    const customerStore = useCustomerStore();
    const { updateSubscription, loading } = useNewsletter();
    const { app } = useContext();

    const isEmailType = ref(false);
    const isSmsType = ref(false);
    const isPostType = ref(false);
    const errorMessage = ref('');

    const handleSubscribe = async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const { email, mobile } = customerStore.user;
      if (!mobile && isSmsType.value) {
        errorMessage.value = app.i18n.t(
          'Add your phone number for SMS subscription!'
        ) as string;
        setTimeout(() => {
          errorMessage.value = '';
        }, 5000);
        return;
      }
      await updateSubscription({
        email: email,
        customQuery: {
          subscribeEmailToNewsletter: 'subscriptionMutation',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          metadata: {
            optin_mail: isPostType.value,
            optin_sms: isSmsType.value,
            optin_newsletter: isEmailType.value
          }
        }
      });
    };

    onBeforeMount(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const { optin_mail, optin_sms, optin_newsletter } = customerStore.user;
      isEmailType.value = optin_newsletter;
      isSmsType.value = optin_sms;
      isPostType.value = optin_mail;
    });

    const selectType = (type) => {
      if (type === 'email') {
        isEmailType.value = !isEmailType.value;
      } else if (type === 'sms') {
        isSmsType.value = !isSmsType.value;
      } else if (type === 'post') {
        isPostType.value = !isPostType.value;
      }
    };
    return {
      isEmailType,
      isSmsType,
      isPostType,
      handleSubscribe,
      selectType,
      errorMessage,
      loading
    };
  }
});
