























import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TableSection',
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const getTableClass = computed<string>(() => {
      const tableType: string = props.sectionData?.type;
      const classMap = {
        'Table - Highlighted Column': 'column-highlighted',
        'Table - Highlighted Row': 'row-highlighted',
        'Table - Simple': 'default'
      };

      return classMap[tableType] || 'default';
    });

    const getTableItems = computed<[{ [key: string]: string }]>(
      () => props.sectionData?.itemsCollection?.items || []
    );

    const getTableItem = (
      index: number,
      tableItem: { [key: string]: string }
    ): string => {
      return `longValue${index + 1}` === 'longValue1'
        ? tableItem.longValue
        : tableItem[`longValue${index + 1}`];
    };

    const getTableHeading = computed<string>(
      () => props.sectionData?.subHeading || ''
    );

    const getTableColsQuantity = computed<number>(() => {
      let quantity = 0;

      for (const item of getTableItems.value) {
        if (item.longValue5) {
          quantity = 5;
          break;
        } else if (item.longValue4 && quantity <= 4) {
          quantity = 4;
        } else if (item.longValue3 && quantity <= 3) {
          quantity = 3;
        } else if (item.longValue2 && quantity <= 2) {
          quantity = 2;
        } else if (item.longValue) {
          quantity = 1;
        }
      }

      return quantity;
    });

    return {
      getTableClass,
      getTableItem,
      getTableHeading,
      getTableColsQuantity,
      getTableItems
    };
  }
});
