



























import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import VaimoSectionTemplate from 'molecules/VaimoSectionTemplate.vue';

export default defineComponent({
  name: 'ArticlePush',
  components: {
    VaimoSectionTemplate,
    VaimoSlider: () => import('molecules/VaimoSlider.vue'),
    ItemStandard: () => import('./parts/ItemStandard.vue'),
    ItemMajor: () => import('./parts/ItemMajor.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const {
      app: { i18n }
    } = useContext();

    const getPageData = (page) => {
      const teaser =
        page.teaser ||
        page.sectionsCollection?.items.find(
          (item) => item.__typename.toLowerCase() === 'heroheader'
        );

      if (!teaser) return null;

      return {
        sys: {
          id: page.sys?.id
        },
        heading: teaser.heading || '',
        headingLink: teaser.headingLink || '',
        descr: teaser.descr || '',
        ctaLinkText: i18n.t('Read article'),
        ctaLinkLink: '/c/' + page.slug + '.html',
        visual: {
          vis: teaser.visual?.vis || teaser.visual,
          visD: teaser.visual?.visD || teaser.visualD,
          link: '/c/' + page.slug + '.html',
          labels: page.labels || [],
          ratio: '1:1',
          ratioD: '1:1',
          pos: 'Top',
          posD:
            teaser.visual?.posD &&
            teaser.visual.posD !== 'Default for current module'
              ? teaser.visual.posD
              : (teaser.visualPosD || 'Left')
        }
      };
    };

    const getPushItemData = (item) => {
      const posD =
        item.visual?.posD && item.visual.posD !== 'Default for current module'
          ? item.visual.posD
          : 'Left';

      if (item.visual) {
        item.visual.ratio = '1:1';
        item.visual.ratioD = '1:1';
        item.visual.pos = 'Top';
        item.visual.posD = posD;
        if (item.labels) {
          item.visual.labels = item.labels;
        }
      }
      return item;
    };

    const getItems = computed(() => {
      return (
        props.sectionData.itemsCollection?.items
          .map((item) => {
            const type = item.__typename.toLowerCase();
            const isFlexitem =
              type === 'flexitem' && item.type === 'Brand Story Push Item';
            if (type === 'page') {
              return getPageData(item);
            } else if (isFlexitem) {
              return getPushItemData(item);
            }
            return null;
          })
          .filter((p) => p) || []
      );
    });

    const isMajorType = computed(() => {
      return getItems.value?.length === 1;
    });

    const itemMajorData = computed(() => {
      if (!isMajorType.value) return null;
      return {
        heading: props.sectionData.heading || '',
        headingLink: props.sectionData.headingLink || '',
        descr: props.sectionData.descr || '',
        buttonCollection: props.sectionData.buttonCollection,
        visual: getItems.value?.[0]?.visual,
        data: getItems.value?.[0]
      };
    });

    return {
      itemMajorData,
      getItems
    };
  }
});
