





























































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useLink } from '~/diptyqueTheme/composable/useLink';

export default defineComponent({
  name: 'DesktopOneItem',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    Labels: () => import('./Labels.vue')
  },
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const getItem = computed(() => props.data.itemsCollection?.items?.[0]);
    const { normalizeLink } = useLink();

    return {
      normalizeLink,
      getItem
    };
  }
});
