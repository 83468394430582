





























import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import VaimoSlider from 'molecules/VaimoSlider.vue';

import { ProductInterface, useProduct } from '~/composables';
import { useAlternativeVisual } from '~/diptyqueTheme/composable';
import type { Product } from '~/modules/catalog/product/types';
import type { Visual } from '~/diptyqueTheme/types/contentful';
export default defineComponent({
  name: 'BoxComposition',
  components: {
    VaimoSlider,
    VaimoHeading: () =>
      import('~/diptyqueTheme/components/atoms/VaimoHeading.vue'),
    BoxCompositionCard: () =>
      import('templates/sections/BundleProducts/parts/BoxCompositionCard.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getProductList } = useProduct();
    const {
      app: { $vsf, i18n }
    } = useContext();
    const { applyAltVisuals } = useAlternativeVisual();
    const products = ref<ProductInterface[] | null>(null);
    const ctfProductsSKU = computed(() => {
      const products = props.sectionData?.itemsCollection?.items?.filter(
        (item) => item?.__typename.toLowerCase() === 'productitem'
      );
      if (products?.length) {
        return products.map((product) => product.sku);
      }
      return null;
    });

    const getHeading = computed(() => props.sectionData?.heading || '');
    const getDescription = computed(() => props.sectionData?.descr || '');
    const altVisuals = computed(
      () => props.sectionData?.altvisCollection?.items ?? []
    );

    const getProducts = async () => {
      if (!ctfProductsSKU.value) {
        return false;
      }

      try {
        const data = await getProductList({
          filter: {
            ['sku']: {
              in: ctfProductsSKU.value
            }
          },
          pageSize: 100
        });
        const dataItems = data?.items.map((product) => {
          const item = props.sectionData.itemsCollection?.items?.find(
            (item) => item.sku === product.sku
          );
          if (item) {
            //@ts-ignore
            product.ctfProductItem = item;
          }
          return product;
        });
        products.value = dataItems.sort((item1, item2) => {
          const index1 = ctfProductsSKU.value.indexOf(item1.sku);
          const index2 = ctfProductsSKU.value.indexOf(item2.sku);
          return index1 - index2;
        }) as ProductInterface[];
        products.value = applyAltVisuals(
          products.value as Product[],
          altVisuals.value as Visual[]
        );
      } catch (error) {
        console.error('BoxComposition getProduct()', error);
      }
    };

    useFetch(async () => {
      await getProducts();
    });

    return {
      ctfProductsSKU,
      getDescription,
      products,
      getHeading
    };
  }
});
