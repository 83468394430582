

































import { computed, defineComponent, onBeforeUnmount } from '@nuxtjs/composition-api';
import VaimoProducts from 'organisms/VaimoProducts.vue';
import VaimoHeading from 'atoms/VaimoHeading.vue';
import VaimoButton from 'atoms/VaimoButton.vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'CategoryCollectionProducts',
  components: {
    VaimoHeading,
    VaimoProducts,
    VaimoButton
  },
  props: {
    heading: {
      required: false,
      type: String,
      default: ''
    },
    productsSku: {
      required: true,
      type: Array
    }
  },
  setup(props) {
    const { toggleCollectionSidebar, hideCollectionSidebar } = useUiState();
    const LAYER_BREAKPOINT = 8;
    const SHORT_LIST_SIZE = 4;
    const items = computed(() => props.productsSku);
    const getCondensedList = (items: any, size: number) => {
      return items.slice(0, size);
    }

    const hasLayerLogic = computed(
      () => props.productsSku.length >= LAYER_BREAKPOINT
    );
    const skuList = computed(() => {
      return hasLayerLogic.value ? getCondensedList(items.value, SHORT_LIST_SIZE) : items.value;
    });
    const skuListUnsorted = computed(() => {
      return hasLayerLogic.value ? getCondensedList(items.value, SHORT_LIST_SIZE) : items.value;
    });


    const openLayer = () => {
      toggleCollectionSidebar();
    };

    onBeforeUnmount(() => {
      hideCollectionSidebar();
    });

    return {
      skuList,
      skuListUnsorted,
      hasLayerLogic,
      openLayer
    };
  }
});
