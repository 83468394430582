var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vaimo-collected-products detailed-cart"},[_c('div',{staticClass:"detailed-cart__main"},[_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.totalItems)?_c('div',{key:"detailed-cart",staticClass:"collected-product-list"},[_c('transition-group',{attrs:{"name":"sf-fade","tag":"div"}},[(_vm.isItemAdding && _vm.isMiniCart)?_c('div',{key:"loader"},[(_vm.isItemAdding)?_c('SfLoader',{staticClass:"mt-base",attrs:{"loading":_vm.isItemAdding}}):_vm._e()],1):_vm._l((_vm.products),function(product){return _c('VaimoCollectedProduct',{key:_vm.cartGetters.getItemSku(product),staticClass:"collected-product sf-collected-product--detailed",class:{
              'offert-product': _vm.isOffertProduct(_vm.getProductPrice(product))
            },attrs:{"is-virtual-product":_vm.isVirtualCartItem(product),"tabindex":"0","image":_vm.cartGetters.getItemImage(product),"title":_vm.cartGetters.getItemName(product),"link":_vm.getProductLink(product),"regular-price":_vm.validateFraction(_vm.getProductPrice(product)),"additional-data":product.additional_data,"special-price":_vm.cartGetters.productHasSpecialPrice(product)
                ? _vm.cartGetters.getItemPrice(product).special &&
                  _vm.validateFraction(_vm.cartGetters.getItemPrice(product).special)
                : '',"has-more-actions":false},on:{"click:remove":function($event){return _vm.removeItemFromCart(product)}},nativeOn:{"click":function($event){_vm.removeIfOne(
                { product: product, qty: _vm.cartGetters.getItemQty(product) },
                $event
              )}},scopedSlots:_vm._u([{key:"details",fn:function(){return [(_vm.isValidProductVariantName(product))?_c('div',{staticClass:"vaimo-collected-product__model"},[_vm._v("\n                "+_vm._s(product.product.variant_name[0].label)+"\n              ")]):_vm._e(),_vm._v(" "),(_vm.isSampleProduct(product))?_c('div',{staticClass:"vaimo-collected-product__label"},[_c('VaimoBadge',[_vm._v(" "+_vm._s(_vm.$t('Free'))+" ")])],1):_vm._e(),_vm._v(" "),(_vm.getIsPreOrder(product))?_c('div',[_c('VaimoBadge',[_vm._v("\n                  "+_vm._s(_vm.$t(_vm.getPreOrderLabel(product)))+"\n                ")])],1):_vm._e(),_vm._v(" "),(
                  _vm.isVirtualCartItem(product) &&
                  _vm.booxiLifeTimeData.isAutoCloseEnabled &&
                  _vm.getBooxiLifetime(product)
                )?_c('VaimoBooxiLifeTimeLabel',{attrs:{"lifetime":_vm.getBooxiLifetime(product)}}):_vm._e()]},proxy:true},{key:"engraving",fn:function(){return [(_vm.isEngraving(product) && !_vm.isSampleProduct(product))?_c('div',{staticClass:"vaimo-collected-product__model"},[_c('VaimoEngravingCard',{attrs:{"product":product}})],1):_vm._e()]},proxy:true},{key:"input",fn:function(){return [_c('VaimoQuantitySelector',{staticClass:"sf-quantity-selector--transparent",class:{
                  'sample-product': _vm.isSampleProduct(product)
                },attrs:{"disabled":_vm.loading,"qty":_vm.cartGetters.getItemQty(product)},on:{"input":function($event){_vm.updateQuantity({ product: product, quantity: Number($event) })}}})]},proxy:true}],null,true)})})],2)],1):_vm._e(),_vm._v(" "),(!_vm.isMiniCart && _vm.getLoadingFlag)?_c('VaimoLoader',{attrs:{"loading":true}}):_vm._e(),_vm._v(" "),(_vm.isMiniCart && _vm.getLoadingFlag)?_c('div',[_c('SfLoader',{staticClass:"mt-base",attrs:{"loading":true}})],1):(!_vm.totalItems && !_vm.initialLoading)?_c('div',{key:"empty-cart",staticClass:"empty-cart"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$dompurify(_vm.$t('Your Shopping Bag is empty')))}}),_vm._v(" "),_c('div',{staticClass:"empty-cart__button"},[_c('VaimoButton',{attrs:{"variant":"default"},nativeOn:{"click":function($event){return _vm.continueShopping.apply(null, arguments)}}},[_vm._v("\n            "+_vm._s(_vm.$t('Continue shopping'))+"\n          ")])],1)]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }