




import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import placeholderTypes from '~/diptyqueTheme/config/placeholderTypes';
import CategoryCollectionProducts from 'templates/category/parts/CategoryCollectionProducts.vue';

export default defineComponent({
  name: 'PlaceholderSection',
  components: {
    CategoryCollectionProducts,
    ProductSlider: () => import('templates/sections/ProductSlider/index.vue')
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  setup(props) {
    const { app: { i18n } } = useContext();
    const placeholderType = computed(() => {
      return (
        props.sectionData?.__typename === 'Placeholder' &&
        props.sectionData?.type
      );
    });

    const isType = (type) => {
      return placeholderType.value === type;
    };

    const component = computed(() => {
      if (isType(placeholderTypes.MAGENTO_COLLECTION_PRODUCTS)) {
        return 'CategoryCollectionProducts';
      }
      if (isType(placeholderTypes.MAGENTO_UPSELL_PRODUCTS)) {
        return 'ProductSlider';
      }
    });

    const params = computed(() => {
      if (isType(placeholderTypes.MAGENTO_COLLECTION_PRODUCTS)) {
        return {
          heading: props.sectionData?.heading || '',
          'products-sku': props.config?.products || []
        };
      }
      if (isType(placeholderTypes.MAGENTO_UPSELL_PRODUCTS)) {
        if (props.config.pdpUpsellProducts.length) {
          return {
            uniqueKey: 'Upsell products',
            sectionData: {
              heading: i18n.t('Explore the Collection'),
              productGroupsCollection: {
                items: [
                  {
                    groupTitle: '',
                    nostoSlot: null,
                    sku: props.config.pdpUpsellProducts.length ? props.config.pdpUpsellProducts.map(el => el.sku) : [],
                    type: 'list of sku'
                  }
                ]
              }
            },
            config: {}
          };
        }

        return {};
      }
    });

    return {
      component,
      params
    };
  }
});
