






































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
import VaimoIcon from 'atoms/VaimoIcon.vue';

import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { useSelectedPaymentMethodStore } from '~/diptyqueTheme/stores/selectedPaymentMethod';
import { useSelectedShippingMethodStore } from '~/diptyqueTheme/stores/selectedShippingMethod';
import CartPreview from '~/modules/checkout/components/CartPreview.vue';
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';

export default defineComponent({
  name: 'OrderSummaryWithTopBar',
  components: {
    CartPreview,
    VaimoIcon,
    SfLoader
  },
  setup() {
    const { cart, loading } = useCart();
    const selectedShippingMethodStore = useSelectedShippingMethodStore();
    const selectedPaymentMethodStore = useSelectedPaymentMethodStore();
    const listIsHidden = ref(false);
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));

    return {
      validateFraction,
      cart,
      listIsHidden,
      totalItems,
      totals,
      loading,
      isTotalsUpdating: computed(
        () =>
          selectedShippingMethodStore.isTotalsUpdating ||
          selectedPaymentMethodStore.isTotalsUpdating
      )
    };
  }
});
