<template>

<LazyHydrate when-visible>
  <component
    :is="componentDisplay"
    v-bind="{ sectionData: componentData, isLastItem: isLastItem, mainLevelName }"
  />
  </LazyHydrate>
</template>

<script>
import {
  defineComponent,
  computed,
  useFetch
} from '@nuxtjs/composition-api';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  name: 'Menu',
  components: {
    LazyHydrate,
    MenuListDisplay: () => import('organisms/menu/displays/List.vue'),
    MenuBlocksDisplay: () => import('organisms/menu/displays/Blocks.vue')
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    },
    entryId: {
      type: String
    },
    isLastItem: {
      required: false,
      type: Boolean,
      default: false
    },
    mainLevelName: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { query, queryResponse } = useContentfulGQL('menuById');

    useFetch(async () => {
      if (props.entryId) await query('getMenuBlockById', { id: props.entryId });
    });

    const componentData = computed(() => {
      const dataFromContentful = queryResponse.value?.menuCollection?.items[0];
      return dataFromContentful ? dataFromContentful : props.sectionData;
    });

    const componentDisplay = computed(() => {
      const displayMap = {
        'default list': 'MenuListDisplay',
        'emphasised list': 'MenuListDisplay',
        'default blocks': 'MenuBlocksDisplay'
      };
      return displayMap[componentData.value?.display] || 'MenuListDisplay';
    });

    return {
      componentDisplay,
      componentData
    };
  }
});
</script>

<style scoped></style>
