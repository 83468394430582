


























import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'LinkInternalNavigation',
  components: {
    SfLink
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const getLinks = computed(() => {
      return props.sectionData.linkItemsCollection?.items.filter(
        (item) => item
      );
    });

    return {
      normalizeLink,
      getLinks
    };
  }
});
