<template>
    <div
      ref="ornement"
      class="vaimo-ornement"
      :class="{ 'vaimo-ornement--single-line': !isMultiLine }"
    >
        <div
          class="px-[20px] m-and-l:px-[60px]"
            :class="{ 'animate': isVisible }"
        >
            <div
              class="line"
            ></div>
            <div
              v-if="isMultiLine"
              class="line"
            ></div>
        </div>
    </div>
</template>

  <script>
  import {
    defineComponent,
    ref,
    computed
  } from '@nuxtjs/composition-api';
  import { useIntersectionObserver } from '@vueuse/core'

  export default defineComponent({
    name: 'VaimoOrnement',
    props: {
      variant: {
        type: String,
        required: false,
        default: "multi-line",
        validator: (value) =>
          ['multi-line', 'single-line'].includes(
          value.toLowerCase()
        )
      },
    },
    setup(props) {
        const ornement = ref(null);
        const isVisible = ref(false);
        const isMultiLine = computed(() => props.variant === "multi-line");

        useIntersectionObserver(
            ornement,
            ([{isIntersecting, target}], observerElement) => {
                if(isIntersecting) {
                    isVisible.value = true
                    observerElement.unobserve(target);
                }
            }
        )

      return {
        ornement,
        isMultiLine,
        isVisible
      };
    }
  });
  </script>

  <style lang="scss" scoped>
    @import '@/diptyqueTheme/assets/styles/atoms/VaimoOrnement';
  </style>
