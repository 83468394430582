
















































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { computed } from '@vue/composition-api';

import { useNewsletter } from '~/composables';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';

export default defineComponent({
  name: 'SubscriptionSection',
  components: {
    SfInput,
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  setup() {
    const {
      app: { i18n }
    } = useContext();
    const storeCode = i18n.localeProperties.code;
    const isStoreDe = storeCode === 'de_eu';
    const { updateSubscription } = useNewsletter();
    const email = ref<string>('');
    const statusMessage = ref<string>('');
    const status = ref<string>('');
    const subscribeStatusDE = ref(false);
    const showForm = computed(() => {
      if (!isStoreDe) return true;
      return !subscribeStatusDE.value;
    });

    const isStatusInvalid = computed<boolean>(() => {
      return status.value === 'error' || status.value === 'failed';
    });

    const submitForm = async () => {
      if (email.value) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email.value)) {
          status.value = 'error';
          return;
        }
        const result = await updateSubscription({ email: email.value });

        if (result === 'SUBSCRIBED') {
          status.value = 'success';
          statusMessage.value = i18n.t(
            'Your email address has been saved'
          ) as string;
        } else if (result === null) {
          status.value = 'failed';
          statusMessage.value = i18n.t(
            'Your email address is already registered'
          ) as string;
        } else if (result === 'NOT_ACTIVE') {
          status.value = 'success';
          statusMessage.value = i18n.t('Email sent') as string;
          if (isStoreDe) {
            subscribeStatusDE.value = true;
          }
        }
      }
    };

    const getTermsOfServiceLinks = computed(() => {
      return termsOfServiceLinks[i18n.locale];
    });

    return {
      email,
      isStatusInvalid,
      statusMessage,
      status,
      submitForm,
      showForm,
      getTermsOfServiceLinks,
      isStoreDe
    };
  }
});
