




















import {
  defineComponent,
  onMounted,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import userSubscriptionConfirmation from "~/diptyqueTheme/customQueries/magento/userSubscriptionConfirmation";
import { useApi, useUiNotification } from "~/composables";

export default defineComponent({
  name: "ConfirmSubscription",
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
  },
  // eslint-disable-next-line consistent-return
  middleware({ redirect, route }) {
    if (!route.query.id && !route.query.code) {
      return redirect('/');
    }
  },
  setup() {
    const route = useRoute();
    const { id:subscriberId, code:subscriberCode }  = route.value.query;
    const { send: sendNotification } = useUiNotification();
    const { query } = useApi();
    const { i18n } = useContext();

    const subscribeUser = async (id, code) => {
      try {
        const { data } = await query(userSubscriptionConfirmation, { id, code });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (data?.confirmSubscription?.success) {
          sendNotification({
            id: Symbol('subscription_success'),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            message: i18n.t(data.confirmSubscription.message) as string,
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'Subscription success',
          });
          return;
        } else {
          sendNotification({
            id: Symbol('subscription_error'),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            message: i18n.t(data.confirmSubscription.message) as string,
            type: 'danger',
            icon: 'cross',
            persist: false,
            title: 'Subscription error',
          });
          return;
        }
      } catch (e) {
        console.error(e);
      }
    };

    onMounted(async () => {
      if ( subscriberId && subscriberCode) {
        await subscribeUser( +subscriberId, subscriberCode );
      }
    });

    return {}
  }
})
