


















































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import { useConfig, useStore, useUiNotification } from '~/composables';
import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import VaimoHeading from '~/diptyqueTheme/components/atoms/VaimoHeading.vue';
import VaimoGiftMessageForm from '~/diptyqueTheme/components/organisms/VaimoGiftMessage/VaimoGiftMessageForm.vue';
import VaimoGiftMessageFormHK from '~/diptyqueTheme/components/organisms/VaimoGiftMessage/VaimoGiftMessageFormHK.vue';
import { useGiftMessage } from '~/diptyqueTheme/composable/useGiftMessage';
import useCart from '~/modules/checkout/composables/useCart';
import { useCartStore } from '~/modules/checkout/stores/cart';

export default defineComponent({
  name: 'VaimoCartGiftMessage',
  components: {
    VaimoGiftMessageForm,
    VaimoGiftMessageFormHK,
    VaimoHeading,
    VaimoButton
  },
  setup() {
    const {
      app: { i18n }
    } = useContext();
    const { config } = useConfig();
    const { cart } = useCart();
    const { updateGiftMessage, loading, error } = useGiftMessage();
    const { send: sendNotification } = useUiNotification();
    const cartStore = useCartStore();
    const { isJpStore } = useStore();

    const messageCharacterLimit = computed(
      () => config.value?.giftmessage_giftmessagelimit_giftmessagecharlimit
    );

    const isGiftMessageFormOpened = ref(true);
    const toggleGiftMessageForm = () => {
      isGiftMessageFormOpened.value = !isGiftMessageFormOpened.value;
    };

    const isSeparateGiftMessage = computed(
      () => config.value.giftmessage_giftmessagelimit_giftmessage_separate
    );

    // Allows gift messages at the order level. Possible values: 1 (Yes) and 0 (No).
    // Configuration path: sales/gift_options/allow_order
    const isGiftMessageAllowed = computed(
      () => config.value?.allow_order === '1' || false
    );

    type GiftMessageFormFields = {
      from: string;
      to: string;
      message: string;
    };

    const giftMessageForm = ref<GiftMessageFormFields>({
      from: '',
      to: '',
      message: ''
    });

    const getGiftMessageText = computed(() => {
      if (isJpStore.value) {
        return giftMessageForm.value.message.replace(/\n/g, '<br/>');
      }

      if (giftMessageForm.value.message) {
        return giftMessageForm.value.message;
      }
    });

    watch(cart, async (updatedCart) => {
      if (updatedCart?.gift_message) {
        isGiftMessageFormOpened.value = false;
        giftMessageForm.value = {
          from: '',
          to: '',
          message: updatedCart?.gift_message?.message || ''
        };
      }
    });

    /*
     * We are sending empty "from" and "to" fields to the backend,
     * as making these fields optional or removing them is challenging(but not impossible) on the backend side.
     * TODO: Make these fields optional or remove them on the backend side.
     * */
    const onGiftMessageFormSubmit = async (
      form: GiftMessageFormFields,
      invalid
    ) => {
      if (loading.value || invalid) {
        return;
      }

      const cartId = cart.value?.id;
      await updateGiftMessage(cartId, { ...form });

      if (error.value.updateGiftMessage) {
        giftMessageForm.value = {
          from: '',
          to: '',
          message: ''
        };
        isGiftMessageFormOpened.value = true;
        sendNotification({
          id: Symbol('gift_message_error'),
          title: i18n.t('Add a message') as string,
          message: i18n.t(
            'Something went wrong while adding a gift message.'
          ) as string,
          type: 'danger',
          icon: 'error',
          persist: false
        });
      } else {
        giftMessageForm.value = { ...form };
        isGiftMessageFormOpened.value = false;
        sendNotification({
          id: Symbol('gift_message_success'),
          title: i18n.t('Add a message') as string,
          message: i18n.t('The gift message was successfully added.') as string,
          type: 'success',
          icon: 'check',
          persist: false
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        cartStore.cart.gift_message = { ...form };
      }
    };

    onMounted(() => {
      nextTick(() => {
        if (cart.value?.gift_message) {
          isGiftMessageFormOpened.value = false;
          giftMessageForm.value = {
            from: '',
            to: '',
            message: cart.value?.gift_message?.message || ''
          };
        }
      });
    });

    const specialStore = computed(() => {
      return ['en_hk', 'zh_hk', 'ja_jp'].indexOf(config.value.store_code) >= 0;
    });

    return {
      loading,
      giftMessageForm,
      isGiftMessageAllowed,
      onGiftMessageFormSubmit,
      isGiftMessageFormOpened,
      toggleGiftMessageForm,
      isSeparateGiftMessage,
      getGiftMessageText,
      messageCharacterLimit,
      specialStore,
      isJpStore
    };
  }
});
