















import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { computed, defineComponent } from '@nuxtjs/composition-api';
import MediaBanner from 'organisms/MediaBanner/MediaBanner.vue';
import type { Image, Media } from 'organisms/MediaBanner/models';
import type { SectionDataProp } from 'templates/sections/ListenToTheScent/models';

import { useScreenSize } from '~/diptyqueTheme/composable';
import type { Asset } from '~/diptyqueTheme/types/contentful';
import { DEFAULT_OPTION } from '~/diptyqueTheme/types/contentful/constants';

export default defineComponent({
  name: 'ListenToTheScent',
  components: { MediaBanner },
  props: {
    sectionData: {
      required: true,
      type: Object as PropType<SectionDataProp>
    }
  },
  setup(props) {
    const { isDesktop } = useScreenSize();

    const audio = computed<Media | null>(() => {
      if (!props.sectionData.audio) {
        return null;
      }

      return {
        src: props.sectionData.audio.url,
        contentType: props.sectionData.audio.contentType
      };
    });

    const visual = computed<Asset | undefined>(() =>
      isDesktop.value && props.sectionData.visual?.visD
        ? props.sectionData.visual?.visD
        : props.sectionData.visual?.vis
    );

    const video = computed<Media | null>(() => {
      if (!visual.value?.contentType.startsWith('video/')) {
        return null;
      }

      return {
        src: visual.value.url,
        contentType: visual.value.contentType
      };
    });

    const image = computed<Image | null>(() => {
      if (!visual.value?.contentType.startsWith('image/')) {
        return null;
      }

      return {
        src: visual.value.url,
        alt: visual.value.fields.title
      };
    });

    const isDesktopComputed = computed(() =>
      isDesktop.value === null ? true : isDesktop.value
    );

    const visualPosition = computed(() => {
      return isDesktopComputed.value && props.sectionData.visual?.posD
        ? props.sectionData.visual?.posD
        : props.sectionData.visual?.pos;
    });

    const visualRatio = computed(() => {
      const ratio =
        isDesktop.value && props.sectionData.visual?.ratioD
          ? props.sectionData.visual?.ratioD
          : props.sectionData.visual?.ratio;

      return ratio === DEFAULT_OPTION ? '1:1' : ratio;
    });

    return {
      audio,
      video,
      image,
      visualPosition,
      visualRatio,
      visual
    };
  }
});
