






































import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'Testimony__desktopDeclination1',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    declinationData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getBannerData } = useDeclination(props.declinationData);
    const getVisualPosition = computed(() => {
      const positionMap = {
        'Visual on the right for desktop': 'right',
        'Visual on the left for desktop': 'left'
      };

      return positionMap[props.declinationData.visualPosition] || 'right';
    });

    const containerClasses = computed(() => {
      return {
        'px-0': !getBannerData.value?.src,
        'col-start-2': !getBannerData.value?.src,
        'text-center': props.declinationData.center?.includes('desktop'),
        'text-left': !props.declinationData.center?.includes('desktop')
      };
    });

    return {
      containerClasses,
      getVisualPosition,
      getBannerData
    };
  }
});
