





























































































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import { useLink } from '~/diptyqueTheme/composable/useLink';

export default defineComponent({
  name: 'CommitmentPush',
  components: {
    VaimoSlider: () => import('molecules/VaimoSlider.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    DesktopOneItem: () => import('./parts/DesktopOneItem.vue'),
    MobileOneItem: () => import('./parts/MobileOneItem.vue'),
    Labels: () => import('./parts/Labels.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const getItems = computed(() => props.sectionData.itemsCollection?.items || []);
    const isOneItem = computed(() => getItems.value.length === 1);
    const { normalizeLink } = useLink();
    const { isDesktop } = useScreenSize();

    return {
      getItems,
      isDesktop,
      isOneItem,
      normalizeLink
    };
  }
});
