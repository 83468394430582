<template>
  <div class="vaimo-engrave__container" :class="variant">
    <SfButton
      class="vaimo-engrave"
      :class="getButtonClasses"
      v-on="$listeners"
      @keypress.enter="$listeners"
    >
      <template>
        <div class="w-full vaimo-engrave__wrapper">
          <VaimoIcon
            v-if="icon"
            :icon="icon"
            class="engrave-icon"
            :label="icon"
          />
          <span class="vaimo-engrave__text">
            <slot />
          </span>
          <VaimoIcon
            v-if="variant == 'default'"
            icon="chevronright"
            class="engrave-icon-arrow"
            label="chevronright"
          />
        </div>
      </template>
    </SfButton>
  </div>
</template>

<script>
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'EngravingInfoPleat',
  components: {
    SfButton,
    VaimoSidebar: () => import('organisms/VaimoSidebar.vue')
  },
  props: {
    engravingConfig: {
      type: [Object, null],
      default: null
    },
    variant: {
      required: false,
      type: String,
      default: 'default',
      validator: (value) => ['default', 'lite'].includes(value)
    },
    fullWidth: {
      required: false,
      type: Boolean,
      default: false
    },
    icon: {
      required: false,
      type: String,
      default: ''
    },
    block: Boolean,
    text: {
      required: false,
      type: String,
      default: ''
    }
  },

  setup(props) {
    const { i18n } = useContext();
    const locale = i18n.localeProperties?.code;

    const variants = ['default', 'lite'];

    const variantClasses = computed(() => {
      return variants.reduce((memo, variant) => {
        const variantClass = variant.replace(/ /g, '-');
        memo[`vaimo-engrave__${variantClass}`] = props.variant === variant;
        return memo;
      }, {});
    });

    const getButtonClasses = computed(() => {
      return {
        'w-full': props.fullWidth,
        ...variantClasses.value
      };
    });

    return {
      variantClasses,
      getButtonClasses,
      locale
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/diptyqueTheme/assets/styles/organisms/EngravingInfoPleat';
</style>
