












































































































































































































import {
  SfInput,
  SfButton,
  SfSelect,
  SfCheckbox,
} from '@storefront-ui/vue';
import {required, min, oneOf, max} from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  ref,
  watch,
  computed,
  defineComponent,
  onBeforeMount,
} from '@nuxtjs/composition-api';
import omitDeep from 'omit-deep';
import { PropType } from 'vue';
import { CountryCodeEnum, useCountrySearch } from '~/composables';
import type { Countries, Country, UseCountrySearchParams } from '~/composables';
import addressGetter from '~/modules/customer/getters/addressGetter';
import type { TransformedCustomerAddress } from '~/modules/customer/composables/types';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('max', {
  ...max,
  message: '30 characters maximum'
});

extend('oneOf', {
  ...oneOf,
  message: 'Invalid country',
});

export default defineComponent({
  name: 'AddressForm',

  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      default: () => ({
        apartment: '',
        city: '',
        country_code: '' as CountryCodeEnum,
        firstname: '',
        lastname: '',
        postcode: '',
        region: {
          region_code: '',
          region_id: 0,
        },
        street: '',
        telephone: '',
        default_shipping: false,
        default_billing: false,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { load: loadCountries, search: searchCountry } = useCountrySearch();

    const form = ref<TransformedCustomerAddress | null>(null);

    const countries = ref<Countries[]>([]);
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const country = ref<Country | null>(null);
    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const updateCountry = async (params: UseCountrySearchParams) => {
      country.value = await searchCountry(params);
      form.value.region = {
        // let region SfSelect know it should display initial state
        ...(regionInformation.value.length > 0 ? { region_id: null } : {}),
      };
    };

    watch(() => props.address, () => {
      form.value = {
        apartment: props.address.apartment,
        city: props.address.city,
        country_code: props.address.country_code,
        firstname: props.address.firstname,
        lastname: props.address.lastname,
        postcode: props.address.postcode,
        region: {
          region_code: '',
          region_id: null,
          ...props.address.region,
        },
        street: props.address.street,
        telephone: props.address.telephone,
        default_shipping: props.address.default_shipping || false,
        default_billing: props.address.default_billing || false,
      } as TransformedCustomerAddress;
    }, { immediate: true });

    const submitForm = () => {
      const regionId = regionInformation.value.find((r) => r.abbreviation === form.value.region.region_code)?.id;
      if (regionId) {
        form.value.region.region_id = regionId;
      }

      emit('submit', {
        form: omitDeep(form.value, ['__typename']),
        // TODO: Handle Error
        onError: () => {},
      });
    };

    onBeforeMount(async () => {
      countries.value = await loadCountries();
      if (props.address.country_code) {
        country.value = await searchCountry({ id: props.address.country_code });
      }
    });

    return {
      form,
      submitForm,
      countriesList,
      regionInformation,
      updateCountry,
    };
  },
});
