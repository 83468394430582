









































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
import axios from 'axios';

import { useStore, useUiNotification } from '~/composables';
import { useApi } from '~/composables/useApi';
import cancelOrderMutation from '~/diptyqueTheme/customQueries/magento/cancelOrderMutation';
import reorderMutation from '~/diptyqueTheme/customQueries/magento/reorderMutation';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import useCart from '~/modules/checkout/composables/useCart';
import orderGetters from '~/modules/checkout/getters/orderGetters';

export default defineComponent({
  name: 'AccountOrder',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    OrderInformationAddress: () =>
      import('organisms/account/parts/order/OrderInformationAddress.vue'),
    OrderSingleProduct: () =>
      import('organisms/account/parts/order/OrderSingleProduct.vue'),
    SfLoader
  },
  props: {
    order: { type: Object, required: true },
    open: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['changeBlockVisibility'],
  setup(props, { emit }) {
    const { app } = useContext();
    const { isJpStore } = useStore();
    const { query, mutate } = useApi();
    const { load } = useCart();
    const { send: sendNotification, notifications } = useUiNotification();
    const reorderItemsFlag = ref(false);
    const countriesStore = useCountriesStore();
    const cancelOrderFlag = ref(false);
    const isCancelled = ref(false);
    const reorderItems = async (order) => {
      reorderItemsFlag.value = true;
      try {
        const { data } = await query(reorderMutation, {
          orderNumber: order.order_number
        });
        //@ts-ignore
        const errors = data.reorderItems?.userInputErrors;

        if (errors?.length) {
          errors.forEach((reorderError, i) => {
            if (reorderError.code === 'NOT_SALABLE') {
              sendNotification({
                icon: 'error',
                id: Symbol(`re_order_error-${i}`),
                message: reorderError.message,
                persist: false,
                title: 'Reorder error',
                type: 'danger'
              });
            }
          });
        }

        await load();

        if (!errors?.length) {
          sendNotification({
            id: Symbol('re-order-items'),
            message: app.i18n.t(
              'Products successfully added to your cart'
            ) as string,
            type: 'success',
            persist: false,
            title: app.i18n.t('Products added') as string
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        reorderItemsFlag.value = false;
      }
    };

    const downloadInvoice = async (url: string, order_number: number) => {
      const { state } = app.context.$vsf.$magento.config;
      const userToken = state.getCustomerToken();
      if (url && order_number) {
        try {
          const response = await axios.get(url, {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(response.data);
          a.download = `invoice_${order_number}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } catch (e) {
          console.error(e, 'downloadInvoice error');
        }
      }
    };

    const cancelOrder = () => {
      sendNotification({
        id: Symbol('cancel-order-confirmation'),
        message: app.i18n.t(
          'Are you sure you want to cancel this order?'
        ) as string,
        type: 'info',
        persist: true,
        action: {
          text: app.i18n.t('OK') as string,
          onClick: async () => {
            notifications.value[0].dismiss();
            await handleCancelOrder();
          }
        }
      });
    };

    const handleCancelOrder = async () => {
      cancelOrderFlag.value = true;
      try {
        const { data, errors } = await mutate(cancelOrderMutation, {
          order_id: props.order.order_number
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (data?.cancelOrder) {
          isCancelled.value = true;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          props.order.status = data?.cancelOrder.order_status;
          sendNotification({
            id: Symbol('cancel-order-error'),
            message: app.i18n.t('You canceled the order.') as string,
            type: 'success',
            persist: false,
            title: app.i18n.t('Cancel order') as string
          });
          return;
        }
        if (errors) {
          errors.forEach((error, i) =>
            sendNotification({
              icon: 'error',
              id: Symbol(`cancel-order-error-${i}`),
              message: error.message,
              persist: false,
              title: app.i18n.t('Cancel order error') as string,
              type: 'danger',
              area: 'top'
            })
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        cancelOrderFlag.value = false;
      }
    };

    const getItemThumbnail = (order): string =>
      order?.items?.[0]?.thumbnail ?? '';

    const getOrderTrackingNumber = (order): string | null =>
      order?.shipments?.[0]?.tracking?.[0]?.number;

    const changeVisibility = () => {
      emit('changeBlockVisibility', props.order.order_number);
    };

    const getDate = (orderDate) => {
      const local = app.i18n?.localeProperties?.iso ?? 'en-US';
      const isoLocal = local.replace(/_/g, '-');
      const date = new Date(orderDate);
      return date.toLocaleDateString(isoLocal, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    const countriesList = computed(() => countriesStore?.countries);

    onBeforeMount(async () => {
      if (!countriesStore.countries.length) {
        await countriesStore.loadCountriesList();
      }
    });

    return {
      orderGetters,
      getDate,
      getPrice: orderGetters.getPrice,
      getItemThumbnail,
      changeVisibility,
      getOrderTrackingNumber,
      reorderItemsFlag,
      reorderItems,
      downloadInvoice,
      isJpStore,
      countriesList,
      cancelOrderFlag,
      cancelOrder,
      isCancelled
    };
  }
});
