








































import { computed, defineComponent } from '@nuxtjs/composition-api';
import VaimoSlider from 'molecules/VaimoSlider.vue';

import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'BrandServices',
  components: {
    BrandServiceCard: () => import('./parts/BrandServiceCard.vue'),
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue'),
    VaimoSlider
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    // Rewrite Contentful default and undefined values to the values according to curent module
    const _sectionData = computed(() => {
      const sectionData = { ...props.sectionData };

      if (sectionData.visual) {
        for (let key in sectionData.visual) {
          if (sectionData.visual[key] === 'Default for current module') {
            sectionData.visual[key] = null;
          }
        }

        const option1 = sectionData.type.includes('Option 1');
        const optionPos = 'Top';
        const optionPosD = option1 ? 'Left' : 'Top';
        const optionRatio = option1 ? '5:6' : '4:3';
        const optionRatioD = option1 ? '3:4' : '4:3';

        sectionData.visual.pos = sectionData.visual.pos || optionPos;
        sectionData.visual.posD = sectionData.visual.posD || optionPosD;
        sectionData.visual.ratio = sectionData.visual.ratio || optionRatio;
        sectionData.visual.ratioD = sectionData.visual.ratioD || optionRatioD;
      }

      return sectionData;
    });

    const { isDesktop } = useDeclination(_sectionData.value);

    const getItems = computed(() => {
      const items = props.sectionData?.itemsCollection?.items.filter(
        (item) => item.__typename === 'Testimony'
      );
      return items || [];
    });

    const desktopQtyVisible = computed(() => {
      return ['Left', 'Right'].includes(_sectionData.value?.visual?.posD)
        ? 2
        : 4;
    });

    const descriptionAndCTAPosition = computed(() => {
      const { descriptionCtaPosition } = _sectionData.value;
      const positionMap = {
        'Below the visual': 'cta-description-bottom',
        'Above the visual': 'cta-description-top'
      };

      return positionMap[descriptionCtaPosition] || 'cta-description-top';
    });

    const brandServicesClasses = computed(() => {
      let classes = descriptionAndCTAPosition.value;
      // eslint-disable-next-line eqeqeq,no-empty
      if (getItems.value && getItems.value.length == 1) {
        return classes + ' one-item-section-tmp';
      }
      return classes;
    });

    const isDesktopVisualPositionLeftOrRight = computed(() => {
      return ['Left', 'Right'].includes(_sectionData.value?.visual?.posD);
    });

    return {
      _sectionData,
      desktopQtyVisible,
      isDesktop,
      getItems,
      isDesktopVisualPositionLeftOrRight,
      brandServicesClasses
    };
  }
});
