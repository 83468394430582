<template>
  <div class="vaimo-jp-addresses" data-cs-mask>
    <div class="first-last-name">
      {{ `${item.lastname} ${item.firstname}` }}
    </div>
    <div class="code-country">
      <span v-if="item.postcode">{{ `${item.postcode}` }}</span>
    </div>
    <div v-if="item.region && item.region.region" class="state">
      {{ item.region.region }}
    </div>
    <div class="city">
      {{ item.city }}
    </div>
    <div class="street">
      {{ `${item.street[0]}` }}
    </div>
    <div v-if="item.street[1]" class="additional_address">
      {{ `${item.street[1]}` }}
    </div>
    <div class="telephone">
      {{ item.telephone }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'VaimoJpAddresses',
  props: {
    item: {
      required: true,
      type: Object
    }
  }
});
</script>

<style scoped></style>
