<template>
  <div class="fees">
    <SfProperty
      :name="$t('Fees')"
      :value="validateFraction(getFeeAmount)"
      class="sf-property--full-width property"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfProperty } from '@storefront-ui/vue';

import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import useCart from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'Fees',
  components: {
    SfProperty
  },
  setup() {
    const { cart } = useCart();

    const getFeeAmount = computed(() => +cart.value?.prices?.fees.value);
    return {
      getFeeAmount,
      validateFraction
    };
  }
});
</script>

<style scoped></style>
