











import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useDeclination } from '@/diptyqueTheme/composable/useDeclination';
export default defineComponent({
  name: 'ProductBanner',
  components: {
    DesktopDeclination1: () => import('./declinations/desktopDeclination1.vue'),
    MobileDeclination1: () => import('./declinations/mobileDeclination1.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const declinationData = computed(() => props.sectionData);
    const { getDeclinationComponent, getDeclinationClass } = useDeclination(
      props.sectionData
    );

    return {
      getDeclinationComponent,
      getDeclinationClass,
      declinationData
    };
  }
});
