











import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';

import type { Engraving } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'EngravingLegalNotice',
  props: {
    productItems: {
      type: Array,
      default: () => []
    },
    showAlways: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const { app } = useContext();
    const isMacOS = computed(() => app.$device.isMacOS);
    const products = computed(() => props.productItems);

    const hasEngraving = computed(() => {
      return (
        products.value &&
        products.value.some(
          (product: { engraving?: Engraving }) => product?.engraving
        )
      );
    });

    return {
      isMacOS,
      hasEngraving
    };
  }
});
