
































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CountdownTimer',
  props: {
    countdownDate: {
      required: true,
      type: String
    },
    disableSeconds: {
      required: false,
      type: Boolean
    },
    oneLine: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['setExpiredFlag'],
  setup(props, { emit }) {
    const { i18n } = useContext();
    const targetDate = new Date(props.countdownDate);

    const daysText = i18n.t('Days');
    const hoursText = i18n.t('H');
    const minutesText = i18n.t('Min');
    const secondsText = i18n.t('Sec');
    const padStart = 2;
    const isTimeExpired = ref(false);

    interface TimeLeft {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    }

    const timeLeft = ref<TimeLeft>({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    });

    let timerInterval: ReturnType<typeof setInterval> | null = null;

    const formatCountdownPart = (part) => String(part).padStart(padStart, '0');

    const calculateDateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = targetDate.getTime() - now;

      if (difference > 0) {
        timeLeft.value = {
          // eslint-disable-next-line no-magic-numbers
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            // eslint-disable-next-line no-magic-numbers
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          // eslint-disable-next-line no-magic-numbers
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          // eslint-disable-next-line no-magic-numbers
          seconds: Math.floor((difference % (1000 * 60)) / 1000)
        };
      } else {
        timeLeft.value = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        };
        isTimeExpired.value = true;
        emit('setExpiredFlag', true);
        if (timerInterval) {
          clearInterval(timerInterval);
          timerInterval = null;
        }
      }
    };

    const formattedTime = computed(() => {
      const { days, hours, minutes, seconds } = timeLeft.value;
      const parts = [
        `${formatCountdownPart(days)} ${daysText}`,
        `${formatCountdownPart(hours)} ${hoursText}`,
        `${formatCountdownPart(minutes)} ${minutesText}`
      ];
      if (props.disableSeconds) {
        parts.push(`${formatCountdownPart(seconds)} ${secondsText}`);
      }
      return parts.join(' : ');
    });

    const formattedDays = computed(() => {
      return formatCountdownPart(timeLeft.value.days);
    });

    const formattedHours = computed(() => {
      return formatCountdownPart(timeLeft.value.hours);
    });

    const formattedMinutes = computed(() => {
      return formatCountdownPart(timeLeft.value.minutes);
    });

    const formattedSeconds = computed(() => {
      return formatCountdownPart(timeLeft.value.seconds);
    });

    const startTimer = () => {
      if (timerInterval) return; // Prevent multiple intervals
      // eslint-disable-next-line no-magic-numbers
      timerInterval = setInterval(calculateDateTimeLeft, 1000);
      calculateDateTimeLeft(); // Initial calculation
    };

    onMounted(() => {
      startTimer();
    });

    onUnmounted(() => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    });

    return {
      formattedTime,
      formattedDays,
      formattedHours,
      formattedMinutes,
      formattedSeconds,
      daysText,
      hoursText,
      minutesText,
      secondsText,
      isTimeExpired
    };
  }
});
