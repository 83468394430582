































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';

export default defineComponent({
  name: 'CampaignPush',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getBannerData } = useDeclination(props.sectionData);
    const { isDesktop } = useScreenSize();

    return {
      isDesktop,
      getBannerData
    };
  }
});
