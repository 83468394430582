var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { _vm.$emit('submit', _vm.formCopy); }), _vm.getClickRegisterDetails()}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"type":"email","name":"email","label":_vm.$t('Your email'),"placeholder":' '},model:{value:(_vm.formCopy.email),callback:function ($$v) {_vm.$set(_vm.formCopy, "email", $$v)},expression:"formCopy.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-firstName'),expression:"'login-modal-firstName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"type":"text","name":"first-name","label":_vm.$t('First Name'),"placeholder":' '},model:{value:(_vm.formCopy.firstName),callback:function ($$v) {_vm.$set(_vm.formCopy, "firstName", $$v)},expression:"formCopy.firstName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"type":"text","name":"last-name","label":_vm.$t('Last Name'),"placeholder":' '},model:{value:(_vm.formCopy.lastName),callback:function ($$v) {_vm.$set(_vm.formCopy, "lastName", $$v)},expression:"formCopy.lastName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"placeholder":' ',"type":"password","has-show-password":"","data-cs-mask":""},model:{value:(_vm.formCopy.password),callback:function ($$v) {_vm.$set(_vm.formCopy, "password", $$v)},expression:"formCopy.password"}})]}}],null,true)}),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('sign-up-newsletter'),expression:"'sign-up-newsletter'"}],staticClass:"form__element",attrs:{"label":_vm.$t('Sign Up for Newsletter'),"placeholder":' ',"name":"signupNewsletter"},model:{value:(_vm.formCopy.shouldSubscribeToNewsletter),callback:function ($$v) {_vm.$set(_vm.formCopy, "shouldSubscribeToNewsletter", $$v)},expression:"formCopy.shouldSubscribeToNewsletter"}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-create-account'),expression:"'login-modal-create-account'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"create-account","label":_vm.$t('I want to create an account'),"placeholder":' '},model:{value:(_vm.formCopy.isWillToCreateAccountConfirmed),callback:function ($$v) {_vm.$set(_vm.formCopy, "isWillToCreateAccountConfirmed", $$v)},expression:"formCopy.isWillToCreateAccountConfirmed"}})]}}],null,true)}),_vm._v(" "),(_vm.showRecaptcha)?_c('recaptcha'):_vm._e(),_vm._v(" "),_vm._t("error"),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"sf-button--full-width form__button",attrs:{"data-testid":"login-form-submit","type":"submit","disabled":_vm.loading || invalid}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Create an account')))])])],1)],2)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"action"},[_vm._v("\n    "+_vm._s(_vm.$t('or'))+"\n    "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-login-to-your-account'),expression:"'login-modal-login-to-your-account'"}],staticClass:"sf-button--text",on:{"click":function($event){return _vm.changeForm('login')}}},[_vm._v("\n      "+_vm._s(_vm.$t('login in to your account'))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }