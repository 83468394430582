<template>
  <SfBreadcrumbs
    v-if="preprocessedBreadcrumbs.length"
    class="breadcrumbs"
    :breadcrumbs="preprocessedBreadcrumbs"
  />
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';

import { useLink } from '~/diptyqueTheme/composable/useLink';

export default defineComponent({
  name: 'VaimoBreadcrumbs',
  components: {
    SfBreadcrumbs
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },

  setup(props) {
    const { normalizeLink } = useLink();
    const preprocessedBreadcrumbs = computed(() => {
      return props.breadcrumbs.map((b) => {
        b.link = normalizeLink(b.link);
        if (b.title && !b.text) {
          b.text = b.title;
        }
        return b;
      });
    });

    return {
      preprocessedBreadcrumbs
    };
  }
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
  --breadcrumbs-list-item-before-padding: 0;
  max-width: var(--container-max-width);
  margin: 0 auto;
  @apply p-md;

  @include for-screen-m-and-l {
    @apply px-lg;
  }

  .sf-breadcrumbs__breadcrumb {
    @apply links;
  }

  ::v-deep {
    .sf-breadcrumbs__list-item {
      @apply flex items-center;
      &:not(:last-child) {
        &:after {
          content: '';
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-left: 5px solid $gris;
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
  }

  .current {
    @apply underline;
  }
}
</style>
