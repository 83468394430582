











































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import VaimoSlider from 'molecules/VaimoSlider.vue';

import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import VaimoButton from 'atoms/VaimoButton.vue';

export default defineComponent({
  name: 'CommitmentPush2__mobileDeclination1',
  components: {
    VaimoButton,
    VaimoSlider,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue')
  },
  props: {
    declinationData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getBannerFunction } = useDeclination(props.declinationData);
    const items = computed(
      () => props.declinationData?.itemsCollection?.items || []
    );

    const getContainerClasses = (item) => {
      return {
        'text-center': item?.center?.includes('mobile'),
        'text-left': !item?.center?.includes('mobile')
      };
    };

    const isVisualAboveText = (item) => {
      return item?.visualPositionM?.includes('above');
    };

    return {
      items,
      getContainerClasses,
      isVisualAboveText,
      getBannerFunction
    };
  }
});
