



























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoQuickFilterBadge',
  props: {
    label: {
      required: false,
      type: String,
      default: ''
    },
    link: {
      required: false,
      type: String,
      default: ''
    },
    searchSuggestion: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['remove'],
  setup(_, { emit }) {
    const removeFilter = () => {
      emit('remove');
    };

    return { removeFilter };
  }
});
