




















import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ArticlePush',
  components: {
    ItemStandard: () =>
      import('templates/sections/ArticlePush/parts/ItemStandard.vue'),
    ItemMajor: () =>
      import('templates/sections/ArticlePush/parts/ItemMajor.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const {
      app: { i18n }
    } = useContext();

    const isMajorType = computed(() => {
      return props.sectionData.type === 'Article Push - Major';
    });

    const pageItems = computed(() => {
      return (
        props.sectionData.itemsCollection?.items
          .filter((item) => item.__typename.toLowerCase() === 'page')
          .map((page, i) => {
            const teaser = page.teaser || page.sectionsCollection?.items.find(
              (item) => item.__typename.toLowerCase() === 'heroheader'
            );

            if (!teaser) return null;

            return {
              sys: {
                id: page.sys?.id || 'page' + i
              },
              heading: teaser.heading || '',
              headingLink: teaser.headingLink || '',
              descr: teaser.descr || '',
              ctaLinkText: i18n.t('Read article'),
              ctaLinkLink: '/c/' + page.slug + '.html',
              visual: {
                vis: teaser.visual?.vis || teaser.visual,
                visD: teaser.visual?.visD || teaser.visualD,
                link: '/c/' + page.slug + '.html',
                ratio: '1:1',
                ratioD: '1:1',
                pos: 'Top',
                posD: 'Left'
              }
            };
          })
          .filter((p) => p) || []
      );
    });

    const sectionItems = computed(() => {
      return pageItems.value;
    });

    const getItems = computed(
      () =>
        (isMajorType.value
          ? sectionItems.value?.splice(1)
          : sectionItems.value) || []
    );

    const itemMajorData = computed(() => {
      if (!isMajorType.value) return null;
      return {
        heading: props.sectionData.heading || '',
        headingLink: props.sectionData.headingLink || '',
        descr: props.sectionData.descr || '',
        visual: sectionItems.value?.[0]?.visual,
        data: sectionItems.value?.[0]
      };
    });

    return {
      itemMajorData,
      pageItems,
      getItems
    };
  }
});
