<template>
  <div class="commitment-push-labels">
    <div v-for="label in labels" :key="label" class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CommitmentPushLabels',
  props: {
    labels: {
      required: true,
      type: Array
    }
  }
});
</script>

<style lang="scss" scoped>
.commitment-push-labels {
  display: flex;
  .label {
    padding: var(--spacer-2px) var(--spacer-2xs) 0;
    text-transform: uppercase;
    font-weight: 400;
    font-size: var(--font-size--sm);
    line-height: 135%;
    color: $gris_perle;
    background: #f8f8f8;
    margin-right: var(--spacer-sm);
    &:last-child {
      margin-right: 0
    }
  }
}
</style>
