<template>
  <div
    class="applepay-container__holder"
    style="-apple-pay-button-style: white"
  >
    <SfLoader v-if="loading" />
    <div
      ref="applePayContainer"
      id="applepay-container"
      data-cs-mask
      class="applepay-container"
      :class="classes"
      style="-apple-pay-button-style: white"
    ></div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  onBeforeMount,
  computed,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
import { useAdyen } from '~/integrations/adyen/composables/useAdyen';
import useShipping from '~/modules/checkout/composables/useShipping';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import useShippingProvider from '~/modules/checkout/composables/useShippingProvider';
import { useCartStore } from '~/modules/checkout/stores/cart';
import updateCartTotalsMutation from '~/diptyqueTheme/customQueries/magento/updateCartTotalsMutation';
import { useApi, useConfig, useUiNotification } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import { Logger } from '~/helpers/logger';
import {
  lsGet,
  lsSet,
  lsRemove
} from '~/diptyqueTheme/composable/useLocalStorage';
import { removeItem } from '~/helpers/asyncLocalStorage';
import { useUser } from '~/modules/customer/composables/useUser';
import useAddresses from '~/diptyqueTheme/modules/checkout/composables/useAddresses';
import { useNarVar } from '~/diptyqueTheme/composable/useNarvar';

export default defineComponent({
  name: 'AdyenApplePayPeymentProvider',
  emits: ['apple-pay-valid', 'apple-pay-started-initiation'],
  components: { SfLoader },
  props: {
    classes: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const {
      getPaymentMethods,
      getComponentsCheckout,
      error,
      orderDetails,
      submitPayment,
      submitAdditionalDetails
    } = useAdyen();
    const countriesStore = useCountriesStore();
    const { save: saveShippingProvider } = useShippingProvider();
    const applePayContainer = ref(null);
    const applePayComponent = ref(null);
    const { getAvailableShippingMethods } = useShipping();
    const cartStore = useCartStore();
    const { mutate } = useApi();
    const { mergeCartItems, setCart } = useCart();
    const { app } = useContext();
    const { config } = useConfig();
    const { save: saveAddresses } = useAddresses();
    const { send: sendNotification } = useUiNotification();
    const router = useRouter();
    const loading = ref(false);
    const { isAuthenticated } = useUser();
    const { getEstimatedDeliveryDates, estimationDeliveryDates, formatDate } =
      useNarVar();
    const shippingDetails = reactive({
      firstname: '',
      lastname: '',
      company: '',
      street: '',
      city: '',
      region: '',
      region_id: null,
      country_code: '',
      postcode: '',
      telephone: ''
    });
    const billingDetails = reactive({
      firstname: '',
      lastname: '',
      company: '',
      street: '',
      city: '',
      region: '',
      region_id: null,
      country_code: '',
      postcode: '',
      telephone: ''
    });
    const shippingMethods = ref([]);
    const isTaxDetailsEnabled = computed(
      () => config.value?.tax_display_is_tax_details_display ?? false
    );
    const cartId = computed(() => cartStore?.cart?.id ?? '');
    const userEmail = computed(() => cartStore?.cart?.email);
    const grandTotal = computed(
      () => cartStore?.cart?.prices?.grand_total?.value
    );
    const onSubmitState = ref(null);
    const onSubmitComponent = ref(null);
    const countriesList = computed(() => {
      return countriesStore.countries;
    });
    const timeToClose = 35000;
    const errors = ref({
      saveAddressesData: null,
      saveShippingAddressData: null,
      saveShippingMethod: null
    });
    const shippingResponse = ref({});
    const saveShippingMethodResult = ref(false);
    const updateTotalsResult = ref(null);

    const getError = (err) => err.response || err.data || err;

    const getSuccessRoute = (orderNumber) => {
      return app.localeRoute({
        name: 'thank-you',
        query: {
          order: orderNumber
        }
      });
    };

    const isApplePayExpressAvailable = (paymentMethodsResponse) => {
      if (!config.value?.is_apple_pay_enabled) {
        return false;
      }
      //abort initialisation in there is no applepay returned in scope of payment methods
      if (!paymentMethodsResponse.paymentMethods.find(({ type }) => type === 'applepay')) {
        return false;
      }
      return true;
    };

    // afterPay it's a callback function called after the payment is authorized and the order is placed.
    // Inside this callback, you can redirect the user to the order confirmation page and clear the cart.
    const afterPay = async ({ order }) => {
      lsSet('store_cart', {});
      lsRemove('savedShippingPostcode');
      app.$vsf.$magento.config.state.removeCartId();
      setCart(null);
      await removeItem('checkout');
      const thankYouRoute = getSuccessRoute(order.id);
      loading.value = false;
      await router.push(thankYouRoute);
      loading.value = false;
    };

    // Request error handling
    const onError = async ({ action, response, message = null }) => {
      console.error('onError ', action, response);
      let errorMessage = `${app.i18n.t(
        'Something went wrong. Please try again!'
      )}`;

      // eslint-disable-next-line no-magic-numbers
      if (response?.status === 403) {
        errorMessage = app.i18n.t('Configuration issues');
      }

      if (response?.message) {
        errorMessage = response.message;
      }

      if (message) {
        errorMessage = message;
      }

      sendNotification(
        {
          id: Symbol('adyen_apple_pay_error'),
          message: errorMessage,
          persist: false,
          type: 'danger',
          icon: 'cross'
        },
        timeToClose
      );
    };

    const onPaymentError = (message) => {
      console.error('onPaymentError', message);
      loading.value = false;
      applePayComponent.value.setStatus('ready');
    };
    // Called when the shopper has completed any additional action required by the payment method,
    // for example scanning a QR code or authenticating a payment with 3D Secure.
    const onAdditionalDetails = async (state, component) => {
      const orderId = orderDetails.value?.order?.id;
      loading.value = true;

      await submitAdditionalDetails({
        ...state.data,
        orderId
      });

      const submitAdditionalDetailsResponse = orderDetails.value;

      if (error.value.submitAdditionalDetails) {
        await onError({
          action: 'Submit Additional Details',
          error: error.value.submitAdditionalDetails
        });

        const errorMessage = app.i18n.t(
          '{message}. Please try again! Order ID: {orderId}',
          {
            message:
              error.value.submitAdditionalDetails?.data?.data?.message || '',
            orderId: orderId
          }
        );
        return onPaymentError(errorMessage);
      }

      responseHandler(submitAdditionalDetailsResponse, component, orderId);
    };

    // Saving Shipping address to quote to get Available shipping methods
    const saveShippingAddressData = async () => {
      try {
        errors.value.saveShippingAddressData = null;
        const response = await getAvailableShippingMethods({
          shippingDetails: shippingDetails
        });
        Logger.info('Shipping address is saved.');
        shippingResponse.value = response;
      } catch (err) {
        errors.value.saveShippingAddressData = getError(err);
        Logger.error('saveShippingAddressData', err);
        console.error('saveShippingAddressData', err);
      }
    };

    const saveShippingMethod = async (method) => {
      const shippingData = {
        carrier_code: method?.carrier_code,
        method_code: method?.method_code
      };

      try {
        errors.value.saveShippingMethod = null;
        const saveShippingMethodResponse = await saveShippingProvider({
          shippingMethod: shippingData,
          customQuery: {
            setShippingMethodsOnCart: 'customSetShippingMethodsOnCart'
          }
        });
        Logger.info('Shipping method is saved.');
        saveShippingMethodResult.value = saveShippingMethodResponse;
      } catch (e) {
        errors.value.saveShippingMethod = getError(e);
        Logger.error('saveShippingMethod', e);
        console.error(e);
      } finally {
        await updateTotals();
      }
    };

    // Saving Email, Shipping and Billing addresses to cart
    const saveAddressesData = async (addressesData) => {
      try {
        errors.value.saveAddressesData = null;
        await saveAddresses(addressesData);
        Logger.info('Address is saved.');
      } catch (err) {
        errors.value.saveAddressesData = getError(err);
        Logger.error('saveAddressData', err);
        console.error('saveAddressData', err);
      }
    };

    function getRegionByCountryAndCode(countryId, regionCode, countriesArray) {
      // Find the country by ID
      const country = countriesArray.find((c) => c.id === countryId);
      // Check if the country and available_regions exist
      if (country && country.available_regions) {
        // Find the region by code within the available_regions array
        const region = country.available_regions.find(
          (r) => r.code === regionCode || r.name === regionCode
        );

        // Return the region if found
        if (region) {
          return {
            region_code: region?.code,
            region: region?.name,
            region_id: region?.id
          };
        }
      } else {
        console.log(
          `Country with ID ${countryId} not found or has no available regions`
        );
      }

      // Return null if the country or region is not found
      return null;
    }

    const getShippingMethodObj = (shippingMethodId) => {
      let shippingMethod = {};

      if (shippingMethodId && shippingMethods.value.length > 0) {
        shippingMethod = shippingMethods.value.find(
          (item) => item?.method_code === shippingMethodId
        );
      }

      return shippingMethod;
    };

    const prepareTotalLineItems = (totals, shippingMethod = false) => {
      let totalsData = [];
      let subTotalExcludingTax = totals?.subtotal_excluding_tax?.value;
      let taxAmount = totals?.tax_amount;
      let discounts = totals?.discounts?.[0]?.amount?.value;
      let appliedTaxes = totals?.applied_taxes;
      let taxesList = false;

      if (
        isTaxDetailsEnabled.value &&
        appliedTaxes &&
        Array.isArray(appliedTaxes) &&
        appliedTaxes.length > 0
      ) {
        taxesList = appliedTaxes.map((item) => ({
          label: `${item?.rates[0]?.title} (${item?.rates[0]?.percent}%)`,
          type: 'final',
          amount: item?.amount?.value ? item?.amount?.value.toString() : ''
        }));
      }

      let shippingPrice = shippingMethod?.amount?.value;

      let shippingPriceWithTax = shippingMethod?.amount_incl_tax?.value;

      if (subTotalExcludingTax) {
        totalsData.push({
          label: app.i18n.t('Subtotal'),
          type: 'final',
          amount: subTotalExcludingTax.toString()
        });
      }

      if (taxAmount && taxAmount !== '0') {
        totalsData.push({
          label: app.i18n.t('Tax'),
          type: 'final',
          amount: taxAmount
        });
      }

      if (taxesList && Array.isArray(taxesList) && taxesList.length > 0) {
        totalsData = [...totalsData, ...taxesList];
      }

      if (shippingPrice || shippingPriceWithTax) {
        totalsData.push({
          label: app.i18n.t('Shipping price'),
          type: 'final',
          amount: shippingPrice
            ? shippingPrice.toString()
            : shippingPriceWithTax.toString()
        });
      }

      if (discounts) {
        totalsData.push({
          label: app.i18n.t('Discount'),
          type: 'final',
          amount: -discounts.toString()
        });
      }

      return totalsData;
    };

    const getShippingMethodDetail = (method) => {
      let detail = '';

      if (estimationDeliveryDates.value) {
        const methodTitle = method.method_title;
        const matchedKey = Object.keys(estimationDeliveryDates.value).find(
          (key) => methodTitle.toLowerCase().includes(key)
        );
        if (matchedKey) {
          detail = app.i18n.t('Estimated delivery date {date}', {
            date: formatDate(estimationDeliveryDates.value[matchedKey])
          });
        }
      }

      return detail;
    };

    const prepareNewSHippingMethods = (availableShippingMethods) => {
      let newShippingMethods = [];

      if (
        availableShippingMethods &&
        Array.isArray(availableShippingMethods) &&
        availableShippingMethods.length > 0
      ) {
        newShippingMethods = availableShippingMethods
          .filter((method) => method.method_code !== 'expressclickandcollect')
          .map((method) => ({
            label: method?.method_title,
            detail: getShippingMethodDetail(method),
            amount: method?.amount?.value,
            identifier: method?.method_code
          }));
      } else {
        console.error('No available shipping methods found.');
      }

      return newShippingMethods;
    };

    const createApplePayGrandTotal = (data) => {
      return {
        label: 'DIPTYQUE',
        type: 'final',
        amount: data?.grand_total?.value
          ? data?.grand_total?.value.toString()
          : ''
      };
    };

    const updateTotals = async () => {
      try {
        const { data } = await mutate(updateCartTotalsMutation, {
          input: {
            cart_id: cartId.value
          }
        });

        updateTotalsResult.value = data?.updateCartTotals?.cart;
        data?.updateCartTotals?.cart &&
          mergeCartItems(data?.updateCartTotals?.cart);
      } catch (e) {
        console.error('updateTotals error', e);
      }
    };

    const responseHandler = async (response, component, orderId = null) => {
      const errorMessage = app.i18n.t(
        'Something went wrong. Please try again!'
      );

      if (!response || Object.keys(response).length === 0) {
        return sendNotification({
          id: Symbol('adyen_apple_pay_error'),
          message: errorMessage,
          persist: false,
          type: 'danger',
          icon: 'cross'
        });
      }

      if (response.resultCode === 'Authorised') {
        return afterPay({
          ...orderDetails.value,
          ...(orderId ? { order: { id: orderId } } : {})
        });
      }

      if (response.message) {
        sendNotification({
          id: Symbol('adyen_apple_pay_error'),
          message:
            response.message === 'Unknown error'
              ? app.i18n.t('The payment is REFUSED')
              : response.message,
          persist: false,
          type: 'danger',
          icon: 'cross'
        });

        return;
      }

      await onError({ action: 'Response Handler', error: response });

      return onPaymentError(errorMessage);
    };

    const appleWasInitiated = (result) => {
      emit('apple-pay-valid', result);
    };

    let requiredContactFields = ['postalAddress', 'name', 'phone', 'email'];
    let shippingContact = {};

    if (isAuthenticated.value) {
      shippingContact = {
        emailAddress: userEmail.value
      };
    }

    const initAppePayComponent = async () => {
      const paymentMethodsData = await getPaymentMethods();

      // Handle api/adyen/getPaymentMethodsRequest errors
      if (error.value.getPaymentMethods || !paymentMethodsData) {
        appleWasInitiated(false);
        return;
      }

      const { paymentMethodsResponse, paymentMethodsExtraDetails } =
        paymentMethodsData;

      if (!isApplePayExpressAvailable(paymentMethodsResponse)) {
        appleWasInitiated(false);
        return;
      }

      const checkout = await getComponentsCheckout({
        onError,
        paymentMethodsResponse,
        paymentMethodsExtraDetails
      });

      applePayComponent.value = checkout.create('applepay', {
        requiredBillingContactFields: requiredContactFields,
        requiredShippingContactFields: requiredContactFields,
        shippingContact: shippingContact,
        onShippingContactSelected: async (resolve, reject, event) => {
          const { countryCode, locality, postalCode, administrativeArea } =
            event.shippingContact;
          const savedPostcode = lsGet('savedShippingPostcode');
          let shippingPostCode = postalCode;
          // eslint-disable-next-line no-magic-numbers
          if (savedPostcode && savedPostcode.indexOf(postalCode) !== -1) {
            shippingPostCode = savedPostcode;
          }
          let update = {};
          shippingDetails.country_code = countryCode;
          shippingDetails.city = locality;
          shippingDetails.postcode = shippingPostCode;

          const regionObject = getRegionByCountryAndCode(
            countryCode,
            administrativeArea,
            countriesList.value
          );

          shippingDetails.region = regionObject?.region_code;
          shippingDetails.region_id = regionObject?.region_id ?? null;

          let newShippingMethods = [];
          let newLineItems = [];
          let newTotalVal = {
            label: 'DIPTYQUE',
            type: 'final',
            amount: grandTotal.value
          };

          await saveShippingAddressData();

          if (errors.value.saveShippingAddressData) {
            await onError({
              action: 'Save Simple Shipping Address',
              error: errors.value.saveShippingAddressData
            });

            const errorMessage =
              errors.value.saveShippingAddressData?.data?.data?.message;

            reject('Save simple shipping address failed');

            return onPaymentError(errorMessage);
          }

          if (
            shippingResponse.value?.errors &&
            shippingResponse.value.errors?.[0]?.message
          ) {
            // eslint-disable-next-line no-undef
            const shippingInvalidMessage = new ApplePayError(
              'addressUnserviceable',
              undefined,
              shippingResponse.value.errors?.[0]?.message
            );

            resolve({
              newTotal: newTotalVal,
              errors: [shippingInvalidMessage]
            });

            return;
          }

          if (
            !shippingResponse.value ||
            Object.keys(shippingResponse.value).length === 0
          ) {
            // eslint-disable-next-line no-undef
            const shippingInvalidMessage = new ApplePayError(
              'addressUnserviceable',
              undefined,
              app.i18n.t('Something went wrong. Please try again!')
            );

            resolve({
              newTotal: newTotalVal,
              errors: [shippingInvalidMessage]
            });

            return;
          }

          if (
            !shippingResponse.value ||
            !shippingResponse.value.shipping_addresses ||
            shippingResponse.value.shipping_addresses.length === 0
          ) {
            // eslint-disable-next-line no-undef
            const shippingCountryInvalidMessage = new ApplePayError(
              'shippingContactInvalid',
              'country',
              app.i18n.t(
                'There are no shipping methods available for this country. We are sorry, please try with a different country.'
              )
            );

            resolve({
              newTotal: newTotalVal,
              errors: [shippingCountryInvalidMessage]
            });

            return;
          }

          if (
            !shippingResponse.value?.shipping_addresses?.[0]
              ?.available_shipping_methods &&
            !shippingResponse.value?.shipping_addresses?.[0]
              ?.available_shipping_methods.length > 0
          ) {
            // eslint-disable-next-line no-undef
            const shippingAddressInvalidMessage = new ApplePayError(
              'shippingContactInvalid',
              undefined,
              app.i18n.t(
                'There are no delivery methods available for your address. Please check your information and try again.'
              )
            );

            resolve({
              newTotal: newTotalVal,
              errors: [shippingAddressInvalidMessage]
            });

            return;
          }

          if (
            shippingResponse.value &&
            Object.keys(shippingResponse.value).length > 0
          ) {
            shippingMethods.value =
              shippingResponse.value?.shipping_addresses[0]?.available_shipping_methods;
            newShippingMethods = prepareNewSHippingMethods(
              shippingResponse.value?.shipping_addresses[0]
                ?.available_shipping_methods
            );
            newTotalVal = createApplePayGrandTotal(
              shippingResponse.value?.prices
            );
            newLineItems = prepareTotalLineItems(
              shippingResponse.value?.prices
            );

            if (shippingMethods.value && shippingMethods.value.length > 0) {
              await saveShippingMethod(shippingMethods.value[0]);
            }
          }

          if (errors.value.saveShippingMethod) {
            await onError({
              action: 'Save Shipping Method',
              error: errors.value.saveShippingMethod
            });

            const errorMessage =
              errors.value.saveShippingMethod?.data?.data?.message;

            reject('Save shipping method failed');

            return onPaymentError(errorMessage);
          }

          update = {
            newTotal: newTotalVal,
            newLineItems: newLineItems,
            newShippingMethods: newShippingMethods
          };

          resolve(update);
        },
        onShippingMethodSelected: async (resolve, reject, event) => {
          const { shippingMethod } = event;
          let newTotalVal = {};
          let newLineItems = [];
          let saveShippingProviderResult = null;
          const shippingMethodObj = getShippingMethodObj(
            shippingMethod?.identifier
          );
          const shippingData = {
            carrier_code: shippingMethodObj?.carrier_code,
            method_code: shippingMethod?.identifier
          };

          if (
            !(
              saveShippingMethodResult.value &&
              saveShippingMethodResult.value?.carrier_code ===
                shippingData.carrier_code &&
              saveShippingMethodResult.value?.method_code ===
                shippingData.method_code
            )
          ) {
            await saveShippingMethod(shippingData);
          }

          if (updateTotalsResult.value) {
            newTotalVal = createApplePayGrandTotal(
              updateTotalsResult.value?.prices
            );
            newLineItems = prepareTotalLineItems(
              updateTotalsResult.value?.prices,
              updateTotalsResult.value?.shipping_addresses?.[0]
                ?.selected_shipping_method
            );
          }

          if (errors.value.saveShippingMethod) {
            await onError({
              action: 'Save Shipping Method',
              error: errors.value.saveShippingMethod
            });

            const errorMessage =
              errors.value.saveShippingMethod?.data?.data?.message;

            reject('Save shipping method failed');

            return onPaymentError(errorMessage);
          }

          if (!newTotalVal?.amount && saveShippingProviderResult === null) {
            console.error('onShippingMethodSelected newTotalVal is empty');
            reject('onShippingMethodSelected newTotalVal is empty');
          }

          const update = {
            newTotal: newTotalVal,
            newLineItems: newLineItems
          };

          resolve(update);
        },
        onSubmit: (state, component) => {
          onSubmitState.value = state;
          onSubmitComponent.value = component;
        },
        onAuthorized: async (resolve, reject, event) => {
          // Getting User's Shipping and Billing data
          const shippingContact = event.payment.shippingContact;
          const billingContact = event.payment.billingContact;

          // Preparing Shipping and Billing addresses data to save in quote
          const billingRegionObject = getRegionByCountryAndCode(
            billingContact?.countryCode,
            billingContact?.administrativeArea,
            countriesList.value
          );

          let shippingData = {
            firstname: shippingContact.givenName,
            lastname: shippingContact.familyName,
            street: shippingContact.addressLines,
            telephone: shippingContact.phoneNumber,
            postcode: shippingContact.postalCode
          };

          if (
            Array.isArray(shippingData.street) &&
            shippingData.street.length > 0 &&
            Array.isArray(shippingData.street[0]) &&
            shippingData.street[0].length > 0
          ) {
            // Check if the first item is an array and not an empty array
            shippingData.street = shippingData.street[0];
          }

          billingDetails.firstname = billingContact.givenName;
          billingDetails.lastname = billingContact.familyName;
          billingDetails.street = billingContact.addressLines;
          billingDetails.city = billingContact.locality;
          billingDetails.region = billingRegionObject?.region_code;
          billingDetails.region_id = billingRegionObject?.region_id ?? null;
          billingDetails.country_code = billingContact.countryCode;
          billingDetails.postcode = billingContact.postalCode;
          billingDetails.telephone = shippingContact.phoneNumber;
          billingDetails.save_in_address_book = false;

          const addressesDetails = {
            email: shippingContact.emailAddress,
            shipping_address: { ...shippingData },
            billing_address: { ...billingDetails }
          };

          if (onSubmitState.value?.isValid) {
            loading.value = true;
            applePayComponent.value.setStatus('loading');
            lsSet('savedShippingPostcode', shippingContact.postalCode);

            // Saving Email, Shipping and Billing addresses to the quote
            await saveAddressesData({
              addressesDetails: addressesDetails
            });

            if (errors.value.saveAddressesData) {
              await onError({
                action: 'Save Addresses Data',
                error: errors.value.saveAddressesData
              });

              reject({
                status: 'STATUS_FAILURE'
              });

              const errorMessage =
                errors.value.saveAddressesData?.data?.data?.message;

              return onPaymentError(errorMessage);
            }

            // Creating an order
            await submitPayment(onSubmitState.value?.data);

            if (error.value.submitPayment) {
              const errorMessage =
                error.value.submitPayment?.data?.data?.message ??
                error.value.submitPayment?.data?.message;

              await onError({
                action: 'Submit Payment',
                error: error.value.submitPayment,
                message: errorMessage
              });

              reject({
                status: 'STATUS_FAILURE'
              });

              return onPaymentError(errorMessage);
            }

            if (orderDetails.value?.resultCode === 'Authorised') {
              resolve({
                status: 'STATUS_SUCCESS'
              });
              loading.value = true;
              return afterPay({
                ...orderDetails.value
              });
            }

            reject({
              status: 'STATUS_FAILURE'
            });

            let errorMessage = `${app.i18n.t(
              'Something went wrong. Please try again!'
            )} ${app.i18n.t('The payment is REFUSED')}`;

            await onError({
              action: 'Response Handler',
              error: orderDetails.value,
              message: errorMessage
            });

            return onPaymentError(errorMessage);
          }
        }
      });

      if (app.$device.isSafari) {
        applePayComponent.value
          .isAvailable()
          .then(() => {
            emit('apple-pay-started-initiation');
            applePayComponent.value.mount(applePayContainer.value);
            appleWasInitiated(true);
          })
          .catch((e) => {
            // Apple Pay isn't available.
            console.error('Apple Pay isn"t available', e);
            appleWasInitiated(false);
          });
      }
    };

    onBeforeMount(async () => {
      if (!countriesStore.countries.length) {
        await countriesStore.loadCountriesList();
      }
    });

    onMounted(async () => {
      if (!cartId.value) {
        return;
      }

      await getEstimatedDeliveryDates(cartId.value);
      await initAppePayComponent();
    });

    return {
      applePayContainer,
      loading
    };
  }
});
</script>

<style lang="scss" scoped>
.applepay-container__holder {
  position: relative;

  .sf-loader {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &__overlay {
      opacity: 0.8;
    }
  }

  ::v-deep {
    button,
    [type='button'] {
      -webkit-appearance: var(--appearance) !important;
      -apple-pay-button-type: plain;
      -apple-pay-button-style: inherit;
      border-radius: 0;
      width: 100%;
      height: 40px;
      margin: 19px 0;
      position: relative;

      &:before {
        content: '';
        display: inline-flex;
        height: 48px;
        position: absolute;
        width: 100%;
        border: 1px solid #000;
        border-bottom-width: 3px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .main-applepay {
    @include for-mobile {
      display: none;
    }
  }
}
</style>
