





































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import VaimoIcon from 'atoms/VaimoIcon.vue';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import {
  customerPasswordRegExp,
  invalidPasswordMsg
} from '~/modules/customer/helpers/passwordValidation';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value)
});

export default defineComponent({
  name: 'VaimoPasswordInput',
  components: {
    VaimoIcon,
    SfInput,
    ValidationProvider
  },
  props: {
    rules: {
      required: false,
      type: String,
      default: 'required|password'
    },
    value: {
      type: String,
      default: ''
    },
    providerName: {
      required: false,
      type: String,
      default: 'password'
    },
    label: {
      required: false,
      type: String,
      default: 'password'
    },
    placeholder: {
      required: false,
      type: String,
      default: ' '
    },
    autocomplete: {
      required: false,
      type: String,
      default: 'on'
    },
    onChange: {
      required: false,
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {}
    },
    withInfoText: Boolean,
    infoText: {
      required: false,
      type: String,
      default: 'Your password must contain at least 8 characters'
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const passwordFieldType = ref('password');
    // eslint-disable-next-line max-len
    const changePasswordInputType = () => {
      passwordFieldType.value =
        passwordFieldType.value === 'text' ? 'password' : 'text';
    };

    const localVmodel = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('input', newValue);
      }
    });

    return {
      localVmodel,
      changePasswordInputType,
      passwordFieldType
    };
  }
});
