var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-summary"},[(_vm.products)?_c('div',{staticClass:"order-summary__products"},_vm._l((_vm.products),function(productItem){return _c('div',{key:productItem.product.uid,staticClass:"product-card-xs",attrs:{"product":productItem.product}},[_c('div',{staticClass:"product__img-wrapper"},[(productItem.product.thumbnail.url)?_c('VaimoBanner',{attrs:{"src":productItem.product.thumbnail.url,"alt":productItem.product.name,"ratio":"4:5","max-optimized-width":100,"source":"magento","loading":'lazy'}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"product__main-content"},[_c('p',{staticClass:"product-name"},[_vm._v("\n          "+_vm._s(productItem.product.name)+"\n        ")]),_vm._v(" "),(productItem.product.preorder)?_c('p',[_c('VaimoBadge',[_vm._v(_vm._s(_vm.$t(productItem.product.preorderStockNotice)))])],1):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"product-attributes"},[(
              productItem.product.capacity &&
              productItem.product.capacity.length
            )?_c('li',{staticClass:"product-attribute"},[_vm._v("\n            "+_vm._s(productItem.product.capacity &&
              productItem.product.capacity.length &&
              productItem.product.capacity[0].label)+"\n          ")]):_vm._e()]),_vm._v(" "),(_vm.isEngraving(productItem) && !_vm.isSampleProduct(productItem))?_c('div',{staticClass:"vaimo-collected-product__model"},[_c('VaimoEngravingCard',{attrs:{"product":productItem,"hide-edit":true}})],1):_vm._e(),_vm._v(" "),(
            _vm.isVirtualCartItem(productItem) &&
            _vm.booxiLifeTimeData.isAutoCloseEnabled &&
            _vm.getBooxiLifetime(productItem)
          )?_c('VaimoBooxiLifeTimeLabel',{staticClass:"vaimo-collected-product__model",attrs:{"lifetime":_vm.getBooxiLifetime(productItem)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"product-details"},[_c('ul',{staticClass:"product-qnty"},[(productItem.quantity)?_c('li',{staticClass:"product-qnty__item"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('Quantity'))+": ")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v(_vm._s(productItem.quantity))])]):_vm._e()]),_vm._v(" "),(_vm.productGetters.getPrice(productItem.product).regular)?_c('div',{staticClass:"product-price"},[_vm._v("\n            "+_vm._s(_vm.validateFraction(_vm.getProductPrice(productItem)))+"\n          ")]):_vm._e()])],1)])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"totals__wrapper"},[_c('Totals',{attrs:{"only-total":!_vm.isDesktop,"show-all":_vm.isDesktop,"with-shipping-and-sub-total":!_vm.isDesktop}})],1),_vm._v(" "),_c('EngravingLegalNotice',{staticClass:"order-summary__info",attrs:{"product-items":_vm.products}}),_vm._v(" "),_c('CouponCode',{attrs:{"coupon-id":"coupon-cart-preview"}}),_vm._v(" "),(_vm.isCheckout && !_vm.isCheckoutPaymentPage && !_vm.isCheckoutSuccessPage)?_c('div',{staticClass:"order-summary__bottom"},[_c('VaimoButton',{staticClass:"edit-cart-link",attrs:{"variant":"link","label-centered":""},nativeOn:{"click":function($event){_vm.$router.push(_vm.localePath('/cart'))}}},[_vm._v("\n      "+_vm._s(_vm.$t('Edit my cart'))+"\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }