<template>
  <BlockWrapper class="vaimo-banner" :section-data="bannerSectionData" />
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import BlockWrapper from '~/diptyqueTheme/components/templates/BlockWrapper.vue';

export default defineComponent({
  name: 'VaimoBanner',
  components: {
    BlockWrapper
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const bannerSectionData = computed(() => {
      return {
        mobileBlockSettings: {
          ratio: props.sectionData?.mobileBlockSettings?.bannerRatio || '3:4',
          width: 'section',
          background: props.sectionData?.banner
        },
        desktopBlockSettings: {
          ratio: props.sectionData?.desktopBlockSettings?.bannerRatio || '3:4',
          width: 'section',
          background: props.sectionData?.banner
        }
      };
    });

    return {
      bannerSectionData
    };
  }
});
</script>

<style scoped></style>
