





















import { SfRadio } from '@storefront-ui/vue';
import { ref, onMounted, defineComponent } from '@nuxtjs/composition-api';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';
import { useSelectedPaymentMethodStore } from "~/diptyqueTheme/stores/selectedPaymentMethod";
import useCart from "~/modules/checkout/composables/useCart";

export default defineComponent({
  name: 'NpPaymentProvider',
  components: {
    SfRadio,
  },
  emits: ['status'],
  setup(_props, { emit }) {
    const paymentMethods = ref<AvailablePaymentMethod[]>([]);
    const { load, save } = usePaymentProvider();
    const selectedPaymentMethodStore = useSelectedPaymentMethodStore();
    const { load: loadCart } = useCart();
    const allowPaymentMethods = ['veriteworks_cod'];

    onMounted(async () => {
      const data = await load();
      paymentMethods.value = data.filter((item) => {
        return allowPaymentMethods.includes(item.code);
      });
    });

    const definePaymentMethods = async (paymentMethodCode: string) => {
      try {
        const data = await save({
          paymentMethod: {
            code: paymentMethodCode,
          },
        });
        paymentMethods.value = data?.filter((item) => {
          return allowPaymentMethods.includes(item.code);
        });

        selectedPaymentMethodStore.setPaymentMethod(paymentMethodCode);
        emit('status', paymentMethodCode);
      } catch (e) {
        console.error(e);
      } finally {
        selectedPaymentMethodStore.isTotalsUpdating = true;
        await loadCart();
        selectedPaymentMethodStore.isTotalsUpdating = false;
      }
    };

    return {
      paymentMethods,
      selectedPaymentMethodStore,
      definePaymentMethods,
    };
  },
});
