<template>
  <div class="vaimo-transparency-modal">
    <SfModal
      title="transparency modal"
      :visible="!!transparencyModalSrc"
      @close="setTransparencyModalSrc('')"
    >
      <iframe
        :src="transparencyModalSrc"
        name="transparency"
        class="transparency"
      ></iframe>
    </SfModal>
  </div>
</template>

<script>
import { defineComponent, watch } from '@vue/composition-api';
import { SfModal } from '@storefront-ui/vue';
import { useUiState } from "~/composables";

export default defineComponent({
  name: 'VaimoTransparencyModal',
  components: {
    SfModal
  },
  setup() {
    const { transparencyModalSrc, setTransparencyModalSrc } = useUiState();

    return {
      transparencyModalSrc,
      setTransparencyModalSrc
    };
  }
});
</script>

<style lang="scss" scoped>
.vaimo-transparency-modal {
  ::v-deep {
    .sf-modal {
      .sf-overlay {
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(1px);
        z-index: 100;
      }
      &__container {
        filter: drop-shadow(0px -4px 30px rgba(172, 172, 172, 0.15));
        z-index: 100;
        height: 90%;
        width: 650px;
        @include for-mobile {
          height: 90dvh;
          max-width: min(90vw, 500px);
          margin: var(--spacer-md) auto 0;
        }
        .sf-modal__close,
        .sf-bar__icon {
          margin: var(--spacer-md) var(--spacer-base);
          display: inline-block;
          border-radius: 50%;
          padding: 3px;
          background-color: $blanc;
          position: absolute;
          top: 0;
          right: 0;
          @include for-mobile {
            margin: var(--spacer-md) var(--spacer-sm);
          }
          svg {
            path {
              fill: $noir;
            }
          }
        }
      }
      &__bar {
        max-height: 0;
        .sf-bar__title {
          display: none;
        }
      }
      &__content {
        padding: 0;
        height: 100%;
        .transparency {
          height: 100%;
          width: 100%;
          border-width: 2px;
          border-style: inset;
          border-color: initial;
          border-image: initial;
        }
      }
    }
  }
}
</style>
