











































































































import {
  defineComponent,
  ref,
  onBeforeMount,
  computed,
  useRoute,
  useContext
} from '@nuxtjs/composition-api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { min, oneOf, required } from 'vee-validate/dist/rules';
import { SfInput, SfButton, SfSelect, SfTextarea } from '@storefront-ui/vue';
import reviewGetters from '~/modules/review/getters/reviewGetters';
import userGetters from '~/modules/customer/getters/userGetters';
import { useReview } from '~/modules/review/composables/useReview';
import { useUser } from '~/modules/customer/composables/useUser';
import type { ProductReviewRatingMetadata } from '~/modules/GraphQL/types';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters'
});

extend('oneOf', {
  ...oneOf,
  message: 'Invalid country'
});
const BASE_FORM = (id) => ({
  nickname: '',
  ratings: {},
  sku: id,
  summary: '',
  text: ''
});

export default defineComponent({
  name: 'ProductAddReview',
  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfTextarea,
    ValidationProvider,
    ValidationObserver
  },
  emits: ['add-review'],
  setup(_, { emit }) {
    const route = useRoute();
    const {
      params: { id }
    } = route.value;

    // @ts-ignore
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha
    );
    const { loading, loadReviewMetadata, error } = useReview();
    const { isAuthenticated, user } = useUser();

    const reviewSent = ref(false);

    const form = ref(BASE_FORM(id));

    const metadata = ref<ProductReviewRatingMetadata[]>([]);

    const ratingMetadata = computed(() =>
      reviewGetters.getReviewMetadata([...metadata.value])
    );

    const formSubmitValue = computed(() => {
      const nickname = isAuthenticated.value
        ? userGetters.getFullName(user.value)
        : form.value.nickname;

      const ratings = Object.keys(form.value.ratings).map((key) => ({
        id: key,
        value_id: `${form.value.ratings[key]}`
      }));

      return {
        ...form.value,
        nickname,
        ratings,
        recaptchaToken: ''
      };
    });

    const submitForm = (reset: () => void) => async () => {
      if (
        !(
          formSubmitValue.value.ratings[0].value_id ||
          formSubmitValue.value.ratings[0].id ||
          formSubmitValue.value.nickname ||
          formSubmitValue.value.summary ||
          formSubmitValue.value.sku ||
          formSubmitValue.value.text
        )
      )
        return;
      try {
        if (isRecaptchaEnabled.value) {
          $recaptcha.init();
        }

        if (isRecaptchaEnabled.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          formSubmitValue.value.recaptchaToken = recaptchaToken;
        }

        reviewSent.value = true;

        emit('add-review', formSubmitValue.value);

        reset();

        if (isRecaptchaEnabled.value) {
          $recaptcha.reset();
        }
      } catch {
        reviewSent.value = false;
      }
    };

    onBeforeMount(async () => {
      metadata.value = await loadReviewMetadata();
    });

    return {
      error,
      form,
      formSubmitValue,
      isAuthenticated,
      loading,
      ratingMetadata,
      reviewSent,
      submitForm,
      isRecaptchaEnabled
    };
  }
});
