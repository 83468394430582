






import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoGrid',
  components: {},
  props: {},
  setup(props) {
    return {};
  }
});
