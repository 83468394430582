










































import {
  defineComponent,
  onMounted,
  useContext
} from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';

import { useConfig, useStore, useUiState } from '~/composables';
import type { LocaleConfig } from '~/composables/useStore/useStore';
import { useIPData } from '~/diptyqueTheme/composable/useIPData';
import { redirectConfig } from '~/diptyqueTheme/config/countriesRedirectConfig';
import { isCookieExist, setCookie } from '~/diptyqueTheme/helpers/cookieHelper';
import type { GeolocationCountryInterface } from '~/diptyqueTheme/stores/countries/types';

export default defineComponent({
  name: 'VaimoRedirectModal',
  components: {
    SfModal,
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  setup() {
    const {
      app: { i18n }
    } = useContext();
    const { isRedirectModalOpen, toggleRedirectModal } = useUiState();
    const { handleStoreSwitchAndRedirect } = useStore();
    const { userCountry } = useIPData();
    const { config } = useConfig();
    const { app: { i18n: { locales } } = { i18n: { locales: [] } } } =
      useContext();

    const COOKIE_KEY = 'vsf-redirect-modal-showed';
    const ONE_SECOND = 1000;

    const getTargetStore = (country: string): GeolocationCountryInterface => {
      return redirectConfig[country] || redirectConfig.default;
    };

    const targetStore = getTargetStore(userCountry.value);

    const calculateExpirationDate = (lifetimeInSeconds: number): Date => {
      return new Date(Number(new Date()) + lifetimeInSeconds * ONE_SECOND);
    };

    const handleRedirect = (currentStore: string) => {
      if (shouldRedirect(currentStore, targetStore)) {
        toggleRedirectModal();
      }
    };

    const shouldRedirect = (
      currentStore: string,
      targetStore: GeolocationCountryInterface
    ): boolean => {
      if (
        !currentStore ||
        !targetStore.redirectTo ||
        isCookieExist(COOKIE_KEY)
      ) {
        return false;
      }

      const { availableStores, redirectTo } = targetStore;

      if (availableStores?.length) {
        return !availableStores.includes(currentStore);
      }

      return currentStore !== redirectTo;
    };

    const closePopupHandler = (): void => {
      toggleRedirectModal();
      setCookie(
        COOKIE_KEY,
        'true',
        calculateExpirationDate(config.value.web_cookie_cookie_lifetime)
      );
    };

    const redirectToStore = async () => {
      const targetStoreCode = locales.find(
        (locale: LocaleConfig) => locale.code === targetStore?.redirectTo
      );

      closePopupHandler();
      await handleStoreSwitchAndRedirect(targetStoreCode);
    };

    onMounted(() => handleRedirect(i18n.localeProperties.code));

    return {
      isRedirectModalOpen,
      redirectToStore,
      storeToRedirect: String(i18n.t(`storeName.${targetStore.store}`)),
      onClose: closePopupHandler,
      websiteName: String(
        i18n.t(`storeName.${i18n.localeProperties?.websiteName}`)
      )
    };
  }
});
