





















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'LegalPush',
  components: {
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    SfLink
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();

    const contentText = computed(() => {
      const items = props.sectionData?.itemsCollection?.items || [];
      return items
        .filter((item) => item)
        .map((item: any, index: number) => {
          const prefix = '*'.repeat(index + 1) + ' ';
          // Strings starting with "######" will be converted into <h6> by VaimoTruncatedText
          return '###### ' + prefix + (item.longValue || item.value);
        })
        .join('\n');
    });

    return {
      normalizeLink,
      contentText
    };
  }
});
