










































import {
  computed,
  defineComponent,
  ref,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import LazyHydrate from 'vue-lazy-hydration';

import { useMegaMenuMobileStore } from '@/diptyqueTheme/stores/megaMenuMobile';
import { useUiState } from '~/composables';
import { useLink } from '~/diptyqueTheme/composable';
import { useThemeUiState } from '~/diptyqueTheme/composable/useThemeUiState';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import type { NavItem } from '~/diptyqueTheme/types/components';

export default defineComponent({
  name: 'VaimoMobileMenu',
  components: {
    LazyHydrate,
    VaimoHeaderSearch: () =>
      import('organisms/header/mobile/VaimoHeaderSearch.vue'),
    VaimoMenuLayerMobile: () =>
      import('organisms/header/mobile/VaimoMenuLayerMobile.vue'),
    VaimoHeaderNavigation: () =>
      import('organisms/header/mobile/VaimoHeaderNavigation.vue'),
    VaimoAvailableStores: () =>
      import('organisms/VaimoStoreSwitcher/VaimoAvailableStores.vue')
  },
  scrollToTop: true,
  props: {
    visible: Boolean
  },
  emits: ['hide'],

  setup() {
    const { isSearchOpen, toggleSearchState } = useUiState();
    const ctx = useContext();
    const $router = useRouter();
    const { normalizeLink, isInternal } = useLink();
    const megaMenuMobileStore = useMegaMenuMobileStore();
    const isMobileMenuVisible = computed(() => megaMenuMobileStore.open);
    const { isMobileStoreSwitcherOpen, closeMobileStoreSwitcher } =
      useThemeUiState();
    const { query, queryResponse: megaMenuEntries } =
      useContentfulGQL('byIdMobileMenu');
    const currentNav = ref<NavItem>({} as NavItem);
    const isHiding = ref<boolean>(false);

    const changeNav = (item: NavItem) => {
      currentNav.value = item;
      const menuLayers = getMenuLayersList.value.filter(
        (layer) => layer.id === item.id
      );
      const menuLayerHasContent = !!menuLayers[0]?.data?.filter(
        (block) => block
      ).length;
      if (menuLayerHasContent) {
        megaMenuMobileStore.isMenuLayersVisible = true;
      } else {
        megaMenuMobileStore.open = false;
        megaMenuMobileStore.isMenuLayersVisible = false;
        if (item.link && item.link !== '#') {
          isInternal(item.link)
            ? $router.push(normalizeLink(item.link))
            : (window.location.href = item.link);
        }
      }
    };

    const hideMenuLayer = (): void => {
      megaMenuMobileStore.isMenuLayersVisible = false;
    };

    const getMenuLayerVisibility = computed(
      () => megaMenuMobileStore.isMenuLayersVisible
    );

    const megaMenuEntry = computed(() => {
      return { megaMenu: megaMenuEntries.value?.megaMenuCollection?.items[0] };
    });

    const getMenuLayersList = computed<NavItem[]>(() => {
      const items =
        megaMenuEntry.value?.megaMenu?.layersCollection?.items ?? [];

      return items.reduce((memo, cur) => {
        if (cur && cur.layerContentCollection?.items?.length) {
          memo.push({
            id: cur.sys?.id,
            data: [...cur.layerContentCollection.items]
          });
        }
        return memo;
      }, []);
    });

    onSSR(async () => {
      await query('getMegaMenu', {
        type: 'Header',
        storeview: ctx.app.i18n.localeProperties.storeview
      });
    });

    const handleSearchClose = () => {
      megaMenuMobileStore.hide();
      megaMenuMobileStore.isMenuLayersVisible = false;
      closeMobileStoreSwitcher();
      if (isSearchOpen.value) {
        toggleSearchState();
      }
    };

    return {
      changeNav,
      hideMenuLayer,
      getMenuLayerVisibility,
      isMobileStoreSwitcherOpen,
      closeMobileStoreSwitcher,
      isMobileMenuVisible,
      currentNav,
      isHiding,
      megaMenuEntry,
      getMenuLayersList,
      isSearchOpen,
      handleSearchClose
    };
  }
});
