
















































































































import {
  ref,
  onMounted,
  defineComponent,
  computed,
  useContext
} from '@nuxtjs/composition-api';
import {
  SfRadio,
  SfImage
} from '@storefront-ui/vue';
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';
import { useGmoPayment } from '../composables/useGmoPayment';
import { StoredPaymentMethod, Config } from '../composables/useGmoPayment/types';
import { useSelectedPaymentMethodStore } from "~/diptyqueTheme/stores/selectedPaymentMethod";
import useMakeOrder from "~/modules/checkout/composables/useMakeOrder";
import usePaymentProvider from "~/modules/checkout/composables/usePaymentProvider";
import useCart from "~/modules/checkout/composables/useCart";

export default defineComponent({
  name: 'GmoPaymentProvider',
  components: {
    SfRadio,
    SfImage,
    CreditCardForm: () => import('./CreditCardForm.vue')
  },
  props: {
    beforePay: {
      type: Function
    },
    afterPay: {
      type: Function
    },
    onError: {
      type: Function
    }
  },
  emits: ['status'],
  setup(props, { emit }) {
    const { i18n } = useContext();
    const { load: loadCart } = useCart();
    const { make, error } = useMakeOrder();
    const { save } = usePaymentProvider();
    const paymentConfig = ref<Config>(null);
    const paymentMethod = ref<AvailablePaymentMethod>(null);
    const storedPaymentMethods = ref<StoredPaymentMethod[]>([]);
    const { loadPaymentMethods, paymentMethodsData } = useGmoPayment();
    const selectedPaymentMethodStore = useSelectedPaymentMethodStore();

    const CcVaultFormRefs = ref([]);
    const CcFormRef = ref(null);

    const isGmoGroupChecked = computed(() =>
      selectedPaymentMethodStore.selectedPaymentMethodCode &&
      selectedPaymentMethodStore.selectedPaymentMethodCode.startsWith('np_gmo_payment_credit_card')
    );

    onMounted(async () => {
      await loadPaymentMethods();
      paymentConfig.value = paymentMethodsData.value?.config;
      paymentMethod.value = paymentMethodsData.value?.paymentMethods?.[0] ?? null;
      storedPaymentMethods.value = paymentMethodsData.value?.storedPaymentMethods;
    });

    const definePaymentMethods = async (paymentMethodCode: string) => {
      try {
        selectedPaymentMethodStore.setPaymentMethod(paymentMethodCode);
        emit('status', paymentMethodCode);

        await save({
          paymentMethod: {
            code: paymentMethodCode,
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        selectedPaymentMethodStore.isTotalsUpdating = true;
        await loadCart();
        selectedPaymentMethodStore.isTotalsUpdating = false;
      }
    };

    const getVaultConfig = (details) => {
      return {
        ...paymentConfig.value,
        paymentDetails: details,
      };
    }

    const triggerGmoPayment = async () => {
      if (selectedPaymentMethodStore.selectedPaymentMethodCode.startsWith('np_gmo_payment_credit_card_vault')) {
        const vaultId = selectedPaymentMethodStore.selectedPaymentMethodCode.replace('np_gmo_payment_credit_card_vault_', '');
        const refIdx = CcVaultFormRefs.value?.findIndex(item => item.cardId == vaultId);
        await CcVaultFormRefs.value?.[refIdx]?.useGmoPayment();
      } else {
        await CcFormRef.value.useGmoPayment();
      }
    }

    const beforeSavePayment = () => {
      // @ts-ignore
      props.beforePay();
    }

    const afterSavePayment = async (data) => {
      if (data) {
        const order = await make();
        if (order) {
          // @ts-ignore
          return props.afterPay(order);
        }
        if (error) {
          // @ts-ignore
          return props.onError(error.value?.make?.message);
        }
      }
      // @ts-ignore
      return props.onError(i18n.t('GMO Payment Error Credit Card Common Message'));
    }

    const isShowContent = (method) => {
      return selectedPaymentMethodStore.selectedPaymentMethodCode && (selectedPaymentMethodStore.selectedPaymentMethodCode == method);
    }

    const resetPaymentMethod = () => {
      definePaymentMethods('np_gmo_payment_credit_card');
    }

    return {
      paymentMethod,
      storedPaymentMethods,
      selectedPaymentMethodStore,
      paymentConfig,
      definePaymentMethods,
      isGmoGroupChecked,
      resetPaymentMethod,
      isShowContent,
      triggerGmoPayment,
      getVaultConfig,
      CcVaultFormRefs,
      CcFormRef,
      afterSavePayment,
      beforeSavePayment
    };
  },
});
