var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vaimo-available-stores",class:{ 'has-back-button': _vm.showBackButton }},[(_vm.showBackButton)?_c('div',{staticClass:"text-base font-normal uppercase flex items-center border-b border-solid border-[#e6e5e5] pb-md pl-md cursor-pointer",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.closeMobileStoreSwitcher()},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.closeMobileStoreSwitcher()}}},[_c('div',{staticClass:"icon flex back-button--icon"},[_c('VaimoIcon',{attrs:{"icon":"arrowleft","size":12,"label":_vm.$t('Languages')}})],1),_vm._v("\n    "+_vm._s(_vm.$t('Languages'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('SfList',{staticClass:"available-stores-list",attrs:{"role":"list","aria-roledescription":"Country & Languages list"}},_vm._l((_vm.continentsList),function(continent){return _c('SfListItem',{key:continent.id,staticClass:"continent-group",attrs:{"role":"listitem"}},[_c('VaimoHeading',{staticClass:"continent-label",attrs:{"heading-level":"h4","heading":continent.label,"aria-label":continent.label}}),_vm._v(" "),(_vm.availableStoresByContinentId(continent.id).length)?_c('SfList',{staticClass:"stores"},[_vm._l((_vm.availableStoresByContinentId(
            continent.id
          )),function(website,index){return [(website.stores.length)?_c('SfListItem',{key:index,staticClass:"website-group"},[_c('VaimoHeading',{staticClass:"website-name",attrs:{"heading-level":"h5","heading":website.websiteName,"aria-label":website.websiteName}}),_vm._v(" "),(website.stores.length)?_c('SfList',{staticClass:"stores"},_vm._l((website.stores),function(store,storeIndex){return _c('SfListItem',{key:storeIndex,staticClass:"store"},[_c('a',{attrs:{"href":"/","title":String(_vm.$t(store.languageLabel)),"aria-label":String(_vm.$t(store.languageLabel))},on:{"click":function($event){$event.preventDefault();_vm.handleStoreSwitchAndRedirect(store),
                      _vm.getClickChangeLanguageDetails(store)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();_vm.handleStoreSwitchAndRedirect(store),
                      _vm.getClickChangeLanguageDetails(store)}}},[_c('span',{staticClass:"store--name",class:{
                      active:
                        store.code === _vm.currentStoreCode &&
                        _vm.checkForAmericanStore(store)
                    }},[_vm._v("\n                    "+_vm._s(_vm.$t(store.languageLabel))+"\n                  ")]),_vm._v(" "),(
                      store.code === _vm.currentStoreCode &&
                      _vm.checkForAmericanStore(store)
                    )?_c('VaimoIcon',{staticClass:"checkmark-dot",attrs:{"icon":"checkdot","size":12,"label":_vm.$t(store.languageLabel)}}):_vm._e()],1)])}),1):_vm._e()],1):_vm._e()]}),_vm._v(" "),(continent.id === 'AS')?_c('SfListItem',{key:99,staticClass:"website-group"},[_c('VaimoHeading',{staticClass:"website-name",attrs:{"heading-level":"h5","heading":_vm.$t('China')}}),_vm._v(" "),_c('SfList',{staticClass:"stores"},[_c('SfListItem',{key:999,staticClass:"store"},[_c('a',{attrs:{"href":"https://www.diptyque-cn.com/"}},[_c('span',{staticClass:"store--name"},[_vm._v("\n                  "+_vm._s(_vm.$t('Chinese'))+"\n                ")])])])],1)],1):_vm._e()],2):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }