var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form gift-message__wrapper"},[(_vm.title)?_c('VaimoHeading',{staticClass:"gift-message__heading",attrs:{"heading-level":"h3","heading":_vm.title,"animated-heading":false}}):_vm._e(),_vm._v(" "),_c('ValidationObserver',{staticClass:"form-container",class:{ 'form--expanded': _vm.isFormExpanded },scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form gift-message-form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () {
          _vm.$emit('submit', _vm.formCopy, invalid);
        })}}},[_c('ValidationProvider',{attrs:{"rules":_vm.getRules({
            en_hk: 'chars|doubleQuotes',
            zh_hk: 'chars|doubleQuotes',
            ja_jp: 'chars',
            default: 'chars|latin'
          }),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('SfTextarea',{directives:[{name:"e2e",rawName:"v-e2e",value:('gift-message-form-message'),expression:"'gift-message-form-message'"}],staticClass:"vaimo-textarea textarea--with-label gift-message-form__message",attrs:{"name":"message","label":_vm.$t('Your message'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":' ',"maxlength":_vm.maxLength,"cols":60,"rows":10},model:{value:(_vm.formCopy.message),callback:function ($$v) {_vm.$set(_vm.formCopy, "message", $$v)},expression:"formCopy.message"}}),_vm._v(" "),_c('div',{staticClass:"gift-message-form__counter"},[_c('span',[_vm._v(_vm._s(_vm.remainingCharacters))])])]}}],null,true)}),_vm._v(" "),_c('XmasForm'),_vm._v(" "),_c('VaimoButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('gift-message-form-submit'),expression:"'gift-message-form-submit'"}],staticClass:"gift-message-form__submit-button",attrs:{"variant":"default","type":"submit","disabled":_vm.isLoading,"label-centered":true,"full-width":false,"data-testid":"gift-message-form-submit"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Confirm message')))])]),_vm._v(" "),_vm._t("error")],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }