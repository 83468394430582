























import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'Callout',
  components: {
    SfLink
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup() {
    const { normalizeLink } = useLink();

    return {
      normalizeLink
    };
  }
});
