import { render, staticRenderFns } from "./BundleProductStickyBar.vue?vue&type=template&id=127ba0f9&scoped=true&"
import script from "./BundleProductStickyBar.vue?vue&type=script&lang=ts&"
export * from "./BundleProductStickyBar.vue?vue&type=script&lang=ts&"
import style0 from "./BundleProductStickyBar.vue?vue&type=style&index=0&id=127ba0f9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127ba0f9",
  null
  
)

export default component.exports