var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"reset-password"}},[_c('VaimoHeading',{staticClass:"text-center",attrs:{"heading":_vm.$t('Reset Password'),"heading-level":"h1"}}),_vm._v(" "),(!_vm.isPasswordChanged)?_c('div',{staticClass:"mt-3md"},[_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.setNewPassword)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"label":_vm.$t('E-mail'),"placeholder":' ',"type":"email","name":"email","autocomplete":"email","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VaimoPasswordInput',{attrs:{"label":_vm.$t('Password'),"provider-name":"current_password","autocomplete":"new-password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VaimoPasswordInput',{attrs:{"label":_vm.$t('Confirm password'),"provider-name":"confirmed_password"},model:{value:(_vm.form.repeatPassword),callback:function ($$v) {_vm.$set(_vm.form, "repeatPassword", $$v)},expression:"form.repeatPassword"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"sf-input"},[_c('div',{staticClass:"sf-input__error-message"},[(_vm.passwordMatchError || _vm.forgotPasswordError.setNew)?_c('div',[_vm._v("\n              "+_vm._s(_vm.passwordMatchError || _vm.forgotPasswordError.setNew.message)+"\n            ")]):(_vm.noSuccess)?_c('div',[_vm._v("\n              "+_vm._s(_vm.errorMessage || _vm.$t('Something went wrong!'))+"\n            ")]):_vm._e()])]),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('VaimoButton',{staticClass:"mt-sm",attrs:{"variant":"primary","type":"submit","label-centered":true,"disabled":invalid || _vm.forgotPasswordLoading}},[_vm._v("\n          "+_vm._s(_vm.$t('Save Password'))+"\n        ")])],1)]}}],null,false,328798827)})],1):_c('div',{staticClass:"flex flex-col items-center"},[_c('p',{staticClass:"text-center mt-base"},[_vm._v(_vm._s(_vm.$t('Password Changed')))]),_vm._v(" "),_c('VaimoButton',{staticClass:"mt-3md",attrs:{"type":"link","link":"/","variant":"default","label-centered":""}},[_vm._v("\n      "+_vm._s(_vm.$t('Back to home'))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }