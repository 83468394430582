



















































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfButton, SfCallToAction, SfImage } from '@storefront-ui/vue';
import { ImageModifiers } from '@nuxt/image';

const ExtendedCallToAction = {
  ...SfCallToAction,
  computed: undefined,
};

export default defineComponent({
  name: 'CallToAction',
  components: {
    SfButton,
    SfImage,
  },
  extends: ExtendedCallToAction,
  props: {
    imageHeight: {
      type: [Number, String],
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    imageTag: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: '',
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
  },
});
