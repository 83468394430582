<template>
  <date-picker
    :value="model"
    :value-type="getDateFormat"
    :format="getDateFormat"
    :lang="getDatePickerLang"
    :append-to-body="appendToBody"
    :disabled-date="disabledDate"
    :placeholder="getDateFormat"
    :input-class="inputClass"
    :popup-style="popupStyle"
    data-cs-mask
    @focusout.native="focusNext"
    @input="input; $emit('update:model', $event)"
  >
    <template #input="slotProps">
      <slot name="input" v-bind="slotProps" />
    </template>
    <template #icon-calendar="slotProps">
      <slot name="icon-calendar" v-bind="slotProps" />
    </template>
  </date-picker>
</template>

<script>
import { useDatePicker } from '~/diptyqueTheme/composable/useDatePicker';

export default {
  name: 'VaimoDatePickerWrapper',
  props: {
    model: {
      type: String,
      required: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    disabledDate: {
      type: Function,
      default: (date) => {
        return date >= new Date(new Date().setDate(new Date().getDate()));
      }
    },
    inputClass: {
      type: String,
      default: 'date-input'
    },
    popupStyle: {
      type: Object,
      default: () => ({ top: '56px' })
    },
    focusNext: {
      type: Function,
      default: () => {}
    },
    input: {
      type: Function,
      default: () => {}
    }
  },
  setup() {
    const { getDatePickerLang, getDateFormat } = useDatePicker();

    return {
      getDatePickerLang,
      getDateFormat
    }
  }
};
</script>
