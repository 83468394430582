



















































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ImageModifiers } from '@nuxt/image';
import { SfButton, SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'HeroSection',
  components: {
    SfButton,
    SfImage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: [Number, String],
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    imageTag: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
  },
});
