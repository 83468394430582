<template>
  <component
    :is="getDeclinationComponent"
    :declination-data="declinationData"
    class="institutional-push"
    :class="getDeclinationClass"
  />
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useDeclination } from '@/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'InstitutionalPush',
  components: {
    MobileDeclination1: () => import('./declinations/mobileDeclination1.vue'),
    MobileDeclination2: () => import('./declinations/mobileDeclination2.vue'),
    DesktopDeclination1: () => import('./declinations/desktopDeclination1.vue'),
    DesktopDeclination2: () => import('./declinations/desktopDeclination2.vue'),
    DesktopDeclination3: () => import('./declinations/desktopDeclination3.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const declinationData = computed(() => props.sectionData);
    const { getDeclinationComponent, getDeclinationClass } = useDeclination(
      props.sectionData
    );

    return {
      getDeclinationComponent,
      getDeclinationClass,
      declinationData
    };
  }
});
</script>

<style scoped></style>
