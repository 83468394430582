
































import { useContext, watch } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  onMounted,
  ref
} from '@vue/composition-api';

import { useSelectedShippingMethodStore } from '~/diptyqueTheme/stores/selectedShippingMethod';
import useCart from '~/modules/checkout/composables/useCart';
import {useStore} from "~/composables";
import { useJpDeliveryDate } from '~/diptyqueTheme/composable/useJpDeliveryDate';

export default defineComponent({
  name: 'EditShipping',
  components: {
    VaimoButton: () =>
      import('~/diptyqueTheme/components/atoms/VaimoButton.vue'),
    VaimoHeading: () =>
      import('~/diptyqueTheme/components/atoms/VaimoHeading.vue')
  },
  setup() {
    const { cart, load } = useCart();
    const { isJpStore } = useStore();
    const { i18n } = useContext();
    const {
      jpCartDeliveryDate,
      jpCartDeliveryTime,
    } = useJpDeliveryDate();
    const selectedShippingMethodStore = useSelectedShippingMethodStore();
    const method = ref(null);
    const methodString = computed(() => {
      if (method.value?.method_title && !isJpStore.value) {
        return `${i18n.t(method.value.carrier_title)}: ${
          method.value.method_title
        }`;
      }
      if (method.value?.method_title && isJpStore.value) {
        return `${i18n.t(method.value.carrier_title)}`;
      }
    });

    watch(
      () => selectedShippingMethodStore.shippingTitle,
      (newValue) => {
        method.value = newValue;
      },
      { deep: true }
    );

    onMounted(async () => {
      // always load cart to get new updated data
      await load();
      jpCartDeliveryDate.value = cart.value?.vw_delivery_date_info?.delivery_date;
      jpCartDeliveryTime.value = cart.value?.vw_delivery_date_info?.delivery_time;

      method.value = selectedShippingMethodStore.shippingTitle;
    });

    return {
      methodString,
      isJpStore,
      jpCartDeliveryDate,
      jpCartDeliveryTime
    };
  }
});
