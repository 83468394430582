<template>
  <div
    v-if="items.length"
    class="goodtoknow-panel px-section"
    @click="handleClick($event)"
  >
    <VaimoHeading
      v-if="sectionData.heading"
      :heading="sectionData.heading"
      :heading-link="sectionData.headingLink"
      heading-level="h2"
      class="text-center mb-3md px-md desktop:px-lg"
    />

    <VaimoSlider
      v-if="areItemsShown && declination === 'slider'"
      :autoplay-speed="5000"
      :items-length="items.length"
      :desktop-quantity-visible="1"
      :slides-to-scroll="1"
      :class="getDeclinationClasses"
      :infinite="true"
      :autoplay="true"
      class="mt-0"
      show-dots
    >
      <div v-for="(item, n) in items" :key="'item' + n">
        <VaimoGoodToKnow v-bind="item" />
      </div>
    </VaimoSlider>

    <div v-else-if="areItemsShown && declination === 'cards'" :class="getDeclinationClasses">
      <div v-for="(item, n) in items" :key="'item' + n">
        <VaimoGoodToKnow v-bind="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useUiState } from "~/composables";

export default defineComponent({
  name: 'GoodToKnowPanel',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoSlider: () => import('molecules/VaimoSlider.vue'),
    VaimoGoodToKnow: () => import('molecules/VaimoGoodToKnow.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    config: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  setup(props) {
    const declination = computed(() => {
      const declinations = {
        'Option 1: Carousel': 'slider',
        'Option 2: Cards': 'cards'
      };
      return declinations[props.sectionData.mVar] || 'slider';
    });
    const { setTransparencyModalSrc } = useUiState();
    const anchorForIframe = 'transparency';

    function replaceShortcodes(text) {
      const regex = /%(\w+)%/g;
      const descrHasShortcode = text?.match(regex) ?? false;
      if (!descrHasShortcode) return text;

      const data = props.config?.shortcodeSource;
      if (!data) return false;

      let dataHasNoRequiredValue = false;
      const replacedText = text.replace(regex, (match, key) => {
        if (typeof data[key] === 'string') {
          return data[key];
        }
        dataHasNoRequiredValue = true;
        return match;
      });

      return dataHasNoRequiredValue ? false : replacedText;
    }

    const items = computed(() => {
      const items =
        props.sectionData.itemsCollection?.items
          .map((item) => {
            return {
              image: item?.image?.url,
              title: item?.title,
              description: replaceShortcodes(item?.descr),
              link: item?.link,
              label: item?.label
            };
          })
          .filter((item) => {
            return item.image && item.title && item.description;
          }) || [];

      return items;
    });

    const handleClick = (e) => {
      if (e.target?.nodeName === 'A' && e.target?.href?.includes(anchorForIframe)) {
        e.preventDefault();
        setTransparencyModalSrc(e.target.href);
      }
      if (e.target.offsetParent?.nodeName === 'A' && e.target.offsetParent?.href?.includes(anchorForIframe)) {
        e.preventDefault();
        setTransparencyModalSrc(e.target.offsetParent.href);
      }
    }

    const getDeclinationClasses = computed(() => {
      const rearrangeColsFringe = 5;
      return [
        'declination-' + declination.value,
        items.value.length < rearrangeColsFringe
          ? 'less-than-five'
          : 'five-or-more'
      ];
    });

    const areItemsShown = computed(() => {
      return items.value?.length;
    });

    return {
      declination,
      getDeclinationClasses,
      items,
      handleClick,
      areItemsShown
    };
  }
});
</script>

<style scoped lang="scss">
.goodtoknow-panel {
  .declination-slider {
    @apply mx-auto max-w-full;
    max-width: var(--container-sm-inter-1);
  }

  .declination-cards {
    @apply flex justify-center flex-wrap m-sm-neg;

    & > div {
      @apply p-sm w-full;
    }

    @include for-screen-m-and-l {
      &.less-than-five {
        @apply flex-nowrap;
      }

      &.five-or-more > div {
        max-width: calc(100% / 3);
      }
    }
  }

  ::v-deep {
    .slick-slide {
      height: inherit;
      & > div,
      & > div > div,
      & > div > div > div {
        height: 100%;
      }
    }
    .slick-slider {
      @apply px-0 overflow-x-visible;
      .slick-list {
        @apply mx-xs-neg;
        overflow: hidden !important;
      }
    }
  }
}
</style>
