























































































































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  useContext,
  useRoute,
  watch
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import VaimoPhone from 'organisms/VaimoPhone.vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { max, required } from 'vee-validate/dist/rules';
import VaimoDatePickerWrapper from 'atoms/helper/VaimoDatePickerWrapper.vue';
import VaimoTooltip from 'atoms/VaimoTooltip.vue';
import { useConfig, useStore } from '~/composables';
import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import VaimoCheckbox from '~/diptyqueTheme/components/molecules/VaimoCheckbox.vue';
import VaimoPasswordInput from '~/diptyqueTheme/components/organisms/VaimoPasswordInput.vue';
import { useDatePicker } from '~/diptyqueTheme/composable/useDatePicker';
import {
  lsGet,
  lsRemove,
  lsSet
} from '~/diptyqueTheme/composable/useLocalStorage';
import { usePhoneMask } from '~/diptyqueTheme/composable/usePhoneMask';
import { useRules } from '~/diptyqueTheme/composable/useRules';
import { formatDate } from '~/diptyqueTheme/helpers/dateFormatter';
import { formatJapaneseDate } from '~/diptyqueTheme/helpers/formatJapaneseDate';
import {
  charsRegex,
  doubleQuotesRegex,
  emailRegex,
  hiraganaRegex,
  kanaRegex,
  katakanaRegex,
  nameRegex,
  phoneRegex
} from '~/diptyqueTheme/helpers/regexes';
import { useUser } from '~/modules/customer/composables/useUser';
import LegalLinks from 'organisms/LegalLinks.vue';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('max', {
  ...max,
  message: '10 characters maximum'
});

extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});

extend('phone', {
  validate(value) {
    return {
      valid: phoneRegex.test(value.replace(/\D/g, ''))
    };
  }
});

extend('name', {
  message: 'Use only alphabetic letters',
  validate(value) {
    return {
      valid: nameRegex.test(value)
    };
  }
});

extend('doubleQuotes', {
  message: '" is a forbidden character',
  validate(value) {
    return {
      valid: doubleQuotesRegex.test(value)
    };
  }
});

extend('chars', {
  message: 'Only these characters',
  validate(value) {
    return {
      valid: charsRegex.test(value)
    };
  }
});

extend('validate-katakana', {
  message: 'Please enter in the full-width katakana.',
  validate(value) {
    return {
      valid: katakanaRegex.test(value)
    };
  }
});

extend('validate-hiragana', {
  message: 'Please use Hiragana only in this field.',
  validate(value) {
    return {
      valid: hiraganaRegex.test(value)
    };
  }
});

extend('validate-kana', {
  message: 'Please use full width kana only in this field.',
  validate(value) {
    return {
      valid: kanaRegex.test(value)
    };
  }
});

export default defineComponent({
  name: 'AccountRegistrationForm',
  components: {
    LegalLinks,
    SfInput,
    VaimoCheckbox,
    VaimoButton,
    VaimoPasswordInput,
    ValidationProvider,
    ValidationObserver,
    VaimoPhone,
    VaimoDatePickerWrapper,
    VaimoTooltip
  },
  emits: ['phoneInputInit'],
  props: {
    customerData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    headerVisibility: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup(props, { refs, emit }) {
    const ctx = useContext();
    const storeCode = ctx.app.i18n.localeProperties.code;
    const isStoreDe = storeCode === 'de_eu';
    const { config } = useConfig();
    const { getNameKanaRules } = useStore();
    const { register, error } = useUser();
    const { getRules } = useRules();
    const route = useRoute();
    const {
      mask: phoneMask,
      maxLength: phoneLength,
      placeholder: phonePlaceholder,
      clearMask
    } = usePhoneMask();
    const routePath = computed(() => route.value.path);
    const { isUsCaStore, isJpStore } = useStore();
    const form = reactive({
      firstname: props.customerData?.firstname ?? null,
      lastname: props.customerData?.lastname ?? null,
      firstnamekana: props.customerData?.firstnamekana ?? null,
      lastnamekana: props.customerData?.lastnamekana ?? null,
      date_of_birth: null,
      email: props.customerData?.email ?? null,
      password: null,
      mobile: props.customerData?.mobile ?? '',
      confirmed_password: null,
      optin_mail: false,
      optin_sms: false,
      optin_newsletter: false,
      newsletterConfirmation: false,
      newsletterError: false,
      activateNlValidation: false
    });
    const isPhoneValid = ref(true);

    const nameKanaRules = getNameKanaRules();

    const onDateInput = (event) => {
      form.date_of_birth = isJpStore.value
        ? formatJapaneseDate(event)
        : formatDate(event);
    };

    watch(
      () => config.value?.is_subscribed,
      () => {
        form.optin_newsletter = config.value?.is_subscribed ?? false;
      },
      { immediate: true }
    );

    const onSubscriptionSelect = (type) => {
      form[type] = !form[type];
      form['activateNlValidation'] = isStoreDe && form['optin_newsletter'];
      setDataToLs(type);
    };

    const getNewsletterConfirmation = (newsletterConfirmation) => {
      form[newsletterConfirmation] = !form[newsletterConfirmation];
    };

    const newsletterValidation = (newsletterConfirmation) => {
      if (!form['optin_newsletter'] || !form['activateNlValidation']) {
        return true;
      }
      return form['newsletterConfirmation'];
    };

    const disableDatesAfterCurrent = (date) => {
      return date >= new Date(new Date().setDate(new Date().getDate()));
    };

    const isInfoBlockVisible = ref(false);
    const showHideInfoBlock = () => {
      isInfoBlockVisible.value = !isInfoBlockVisible.value;
    };
    const handleClickOutside = () => {
      if (isInfoBlockVisible.value) {
        isInfoBlockVisible.value = false;
      }
    };

    const handleRegistration = async (invalid, newsletterConfirmation) => {
      const newsletterValid = newsletterValidation(newsletterConfirmation);
      if (newsletterValid) {
        form['newsletterError'] = false;
        if (invalid) {
          return false;
        }
        if (!isPhoneValid.value) {
          return;
        }
        await register({
          user: {
            ...form,
            date_of_birth:
              form.date_of_birth?.split('/').reverse().join('-') || '',
            mobile: clearMask(form.mobile)
          }
        });
        if (!error.value.register) {
          lsRemove('registration_form_data');
        }
      } else {
        form['newsletterError'] = true;
      }
    };

    const checkPassword = computed(() => {
      return form.password !== form.confirmed_password;
    });

    const getClickRegisterDetails = () => {
      ctx.app.$gtm.push({
        event: 'clickAccountCreation'
      });
    };

    const isThankYouPage = computed(() => {
      return routePath.value.includes('checkout/thank-you');
    });

    const focusNext = () => {
      const nextInput = refs.mobile as any;
      nextInput.$el.querySelector('input').focus();
    };

    const onAlreadyHaveAccount = () => {
      emit('onAlreadyHaveAccount');
    };

    const setDataToLs = (key) => {
      let lsData = lsGet('registration_form_data') || {};
      lsData = { ...lsData, [key]: form[key] };
      lsSet('registration_form_data', lsData);
    };

    const phoneValidHandler = (value) => {
      isPhoneValid.value = value;
    };

    onMounted(() => {
      const lsData = lsGet('registration_form_data');
      if (lsData && Object.keys(lsData)?.length) {
        for (let key in lsData) {
          form[key] = lsData[key];
        }
      }
    });

    const onPhoneInputInit = () => emit('phoneInputInit');

    return {
      form,
      phoneMask,
      phoneLength,
      phonePlaceholder,
      onDateInput,
      setDataToLs,
      onAlreadyHaveAccount,
      showHideInfoBlock,
      isInfoBlockVisible,
      handleClickOutside,
      handleRegistration,
      checkPassword,
      onSubscriptionSelect,
      getNewsletterConfirmation,
      newsletterValidation,
      disableDatesAfterCurrent,
      getClickRegisterDetails,
      isStoreDe,
      isThankYouPage,
      getRules,
      focusNext,
      isUsCaStore,
      isJpStore,
      config,
      nameKanaRules,
      isPhoneValid,
      phoneValidHandler,
      onPhoneInputInit
    };
  }
});
