
























































import { SfLink } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useSection } from '~/diptyqueTheme/composable/useSection';

export default defineComponent({
  name: 'MenuBlocksDisplay',
  components: {
    BlockWrapper: () => import('templates/BlockWrapper.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    RatioWrapper: () => import('templates/RatioWrapper.vue'),
    SfLink
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { headingData, getContentAlignClasses, getBlockElementClasses } = useSection(
      props.sectionData
    );

    const itemsCollectionData = computed(() => {
      if (!props.sectionData.itemsCollection.items) return [];
      return props.sectionData.itemsCollection.items.filter((el) => el);
    });

    const checkItemImage = (item) => item?.image?.url;
    const getItemTitle = (item) => item.title || 'Image';

    return {
      getContentAlignClasses,
      headingData,
      itemsCollectionData,
      checkItemImage,
      getItemTitle,
      elemClasses: getBlockElementClasses
    };
  }
});
