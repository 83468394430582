













































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  ssrRef,
  useFetch
} from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';

import { useUiHelpers, useUiState } from '~/composables';
import SectionsList from '~/diptyqueTheme/components/templates/SectionsList.vue';
import { useScreenSize } from '~/diptyqueTheme/composable';
import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';
import placeholderTypes from '~/diptyqueTheme/config/placeholderTypes';
import { useFacet } from '~/diptyqueTheme/modules/catalog/category/composables/useFacet';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import type { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'CategoryCollection',
  components: {
    CategoryCollectionSidebar: () =>
      import('templates/category/parts/CategoryCollectionSidebar.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoBreadcrumbs: () => import('atoms/VaimoBreadcrumbs.vue'),
    SectionsList
  },
  transition: 'fade',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    categoryData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const activeCategory = computed(() => props.categoryData);
    const { addTags } = useCache();
    const products = ssrRef<ProductInterface[]>([]);
    const collectionBlocks = ssrRef([]);
    const { search } = useFacet();
    const uiHelpers = useUiHelpers();
    const productsSKUs = ssrRef([]);
    const productsSKUsUnsorted = ssrRef([]);
    const activeCategoryName = computed(() => activeCategory.value?.name ?? '');
    const { toggleCollectionSidebar, isCollectionSidebarOpen } = useUiState();
    const { isDesktop } = useScreenSize();
    const LAYER_BREAKPOINT = 8;
    const categoryStore = useCategoryStore();
    const { sectionList, getSectionList } = useContentfulPage('collection');
    const megaMenuMobileState = useMegaMenuMobileStore();

    const getSections = async (collectionId) => {
      await getSectionList('collection', String(collectionId || 0));

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      collectionBlocks.value = sectionList.value?.blocks || [];
    };

    const categoryUid = activeCategory.value?.uid;
    const categoryId = activeCategory.value?.id;
    const categoryProducts = activeCategory.value?.products?.items;

    useFetch(async () => {
      await Promise.all([
        search({
          ...uiHelpers.getFacetsFromURL(),
          category_uid: categoryUid,
          itemsPerPage: 200
        }),
        getSections(categoryId)
      ]);

      products.value = categoryProducts;
      productsSKUs.value = products.value.map(({ sku }) => sku);
      productsSKUsUnsorted.value = products.value.map(({ sku }) => sku);

      const tags = [{ prefix: CacheTagPrefix.View, value: 'category' }];
      const productTags = products.value.map((product) => ({
        prefix: CacheTagPrefix.Product,
        value: product.uid
      }));

      addTags([...tags, ...productTags]);
    });

    const compoundSectionList = computed(() => {
      let sectionList;
      const placeholderExist = collectionBlocks.value?.some((block) => {
        if (block) {
          return block.__typename === 'Placeholder';
        }
        return false;
      });

      const placeholder = {
        __typename: 'Placeholder',
        type: 'Magento: Collection page products'
      };

      sectionList = placeholderExist
        ? [...collectionBlocks.value]
        : [placeholder, ...collectionBlocks.value];

      return sectionList;
    });

    const isPanelFixedEdge = 250; //px
    const isPanelFixed = ref(false);
    const handleScroll = () => {
      if (!isPanelFixed.value && scrollY >= isPanelFixedEdge) {
        isPanelFixed.value = true;
      } else if (isPanelFixed.value && scrollY < isPanelFixedEdge) {
        isPanelFixed.value = false;
      }
    };

    const fixedPanelHeight = 54;
    const setBottomPadding = (value) => {
      if (isDesktop.value) return false;
      document.getElementById('__layout').style.paddingBottom = value + 'px';
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      setBottomPadding(fixedPanelHeight);
      categoryStore.categoryType = 'collection';
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      setBottomPadding(0);
      categoryStore.categoryType = null;
    });

    const sectionListConfig = computed(() => {
      return {
        Placeholder: {
          products: productsSKUs.value
        }
      };
    });

    const defaultPlaceholderData = {
      type: placeholderTypes.MAGENTO_COLLECTION_PRODUCTS,
      __typename: 'Placeholder'
    };

    const goToCollection = () => {
      if (productsSKUs.value?.length > LAYER_BREAKPOINT) {
        toggleCollectionSidebar();
      } else {
        document
          .querySelector('.category-collection-products')
          .scrollIntoView({ behavior: 'smooth' });
      }
    };

    const isFixedPanelVisible = computed(
      () =>
        isDesktop.value ||
        !(isCollectionSidebarOpen.value || megaMenuMobileState.open)
    );

    return {
      productsSKUs,
      productsSKUsUnsorted,
      activeCategoryName,
      sectionListConfig,
      collectionBlocks,
      compoundSectionList,
      defaultPlaceholderData,
      isPanelFixed,
      goToCollection,
      isFixedPanelVisible
    };
  }
});
