



































import { computed, defineComponent } from '@nuxtjs/composition-api';
import VaimoSectionTemplate from 'molecules/VaimoSectionTemplate.vue';

export default defineComponent({
  name: 'ItemMajor',
  components: {
    VaimoSectionTemplate,
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const data = computed(() => props.item?.data || {});

    return {
      data
    };
  }
});
