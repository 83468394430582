



















import { defineComponent } from '@nuxtjs/composition-api';

import { useUiState } from '~/composables';

export default defineComponent({
  name: 'VaimoContentLayer',
  components: {
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoSidebar: () => import('organisms/VaimoSidebar.vue')
  },

  setup() {
    const {
      closeContentLayer,
      isContentLayerOpen,
      contentLayerContent,
      contentLayerTitle
    } = useUiState();

    return {
      closeContentLayer,
      isContentLayerOpen,
      contentLayerContent,
      contentLayerTitle
    };
  }
});
