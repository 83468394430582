var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.heading || _vm.subHeading)?_c('SfHeading',{staticClass:"vaimo-heading",class:Object.assign({}, _vm.getSubHeadingPositions),attrs:{"heading":_vm.heading,"heading-level":_vm.headingLevel,"heading-style":_vm.headingStyle,"sub-heading":_vm.subHeading,"sub-heading-level":_vm.subHeadingLevel,"sub-heading-style":_vm.subHeadingStyle,"sub-heading-position":_vm.subHeadingPosition},scopedSlots:_vm._u([{key:"title",fn:function(){return [(!_vm.animatedHeading)?_c(_vm.headingLevel,{tag:"component",staticClass:"vaimo-heading__title",class:Object.assign({}, _vm.headingLevelsClasses,
        _vm.headingStylesClasses,
        _vm.headingClasses)},[_c('VaimoSpyWrapper',{staticClass:"reset-styles",attrs:{"tag":_vm.headingLinkComponent,"link":_vm.normalizeLink(_vm.headingLink),"aria-label":_vm.headingText}},[_vm._v("\n        "+_vm._s(_vm.headingText)+"\n      ")])],1):_c(_vm.headingLevel,{ref:"headingEl",tag:"component",staticClass:"vaimo-heading__title",class:Object.assign({}, _vm.headingLevelsClasses,
        _vm.headingStylesClasses,
        {animate: _vm.isVisible}),domProps:{"innerHTML":_vm._s(_vm.headingText)}})]},proxy:true},{key:"description",fn:function(){return [(!_vm.animatedDescription && _vm.subHeading)?_c(_vm.subHeadingLevel,{tag:"component",staticClass:"vaimo-heading__subtitle",class:Object.assign({}, _vm.subHeadingLevelsClasses,
        _vm.subHeadingStylesClasses)},[_vm._v("\n      "+_vm._s(_vm.subTitleText)+"\n    ")]):(_vm.subHeading)?_c(_vm.subHeadingLevel,{ref:"subtitleEl",tag:"component",staticClass:"vaimo-heading__subtitle",class:Object.assign({}, _vm.subHeadingLevelsClasses,
        _vm.subHeadingStylesClasses,
        {animate: _vm.isVisible}),domProps:{"innerHTML":_vm._s(_vm.subTitleText)}}):_vm._e()]},proxy:true}],null,false,1087738681)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }