









































































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import { useLink } from '~/diptyqueTheme/composable/useLink';
import articleSectionDeclinations from '~/diptyqueTheme/config/articleSectionDeclinations';

export default defineComponent({
  name: 'ArticleSection',
  components: {
    VaimoSlider: () => import('molecules/VaimoSlider.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    DesktopOneItem: () => import('./parts/DesktopOneItem.vue'),
    MobileOneItem: () => import('./parts/MobileOneItem.vue'),
    Labels: () => import('./parts/Labels.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const getItems = computed(() => {
      return props.sectionData.articlesCollection?.items
        .filter((i) => i)
        .map((item) => {
          item.ctaLink = normalizeLink(item.ctaLink);
          return item;
        });
    });
    const isOneItem = computed(() => getItems.value?.length === 1);
    const { isDesktop } = useScreenSize();

    const declination = computed(() => {
      const declinations = {
        [articleSectionDeclinations.MAJOR]: 'major',
        [articleSectionDeclinations.STANDARD]: 'standard'
      };
      return declinations[props.sectionData.mVar];
    });

    return {
      getItems,
      isDesktop,
      isOneItem,
      normalizeLink,
      declination
    };
  }
});
