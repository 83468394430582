


















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoInformationPleats',
  props: {
    pleats: {
      required: true,
      type: Array
    },
    skipLastBorder: {
      type: Boolean,
      default: true
    }
  },
  emits: ['onPleatClick'],
  setup(_, { emit }) {
    const onPleatClick = (pleat) => {
      emit('onPleatClick', pleat);
    };

    return {
      onPleatClick
    };
  }
});
