<template>
  <component
    :is="componentDisplay"
    v-bind="{ sectionData: componentData, isLastItem: isLastItem, blockName }"
  />
</template>

<script>
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

export default defineComponent({
  name: 'FooterMenu',
  components: {
    FooterMenuListDisplay: () => import('organisms/menu/displays/FooterList.vue'),
    MenuBlocksDisplay: () => import('organisms/menu/displays/Blocks.vue')
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    },
    entryId: {
      type: String
    },
    isLastItem: {
      required: false,
      type: Boolean,
      default: false
    },
    blockName: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { query, queryResponse } = useContentfulGQL('menuById');

    useFetch(async () => {
      if (props.entryId) await query('getMenuBlockById', { id: props.entryId });
    });

    const componentData = computed(() => {
      const dataFromContentful = queryResponse.value?.menuCollection?.items[0];
      return dataFromContentful ? dataFromContentful : props.sectionData;
    });

    const componentDisplay = computed(() => {
      const displayMap = {
        'default list': 'FooterMenuListDisplay',
        'emphasised list': 'FooterMenuListDisplay',
        'default blocks': 'MenuBlocksDisplay'
      };
      return displayMap[componentData.value?.display] || 'FooterMenuListDisplay';
    });

    return {
      componentDisplay,
      componentData
    };
  }
});
</script>

<style scoped></style>
