




































import { computed, defineComponent, onMounted } from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';
import phoneInputCountries from '~/diptyqueTheme/config/phoneInputCountries';
export default defineComponent({
  name: 'VaimoPhoneInput',
  props: {
    onInput: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {}
    },
    showDialCodeInSelection: {
      required: false,
      type: Boolean,
      default: true
    },
    showDialCodeInList: {
      required: false,
      type: Boolean,
      default: true
    },
    showFlags: {
      required: false,
      type: Boolean,
      default: true
    },
    showSearchBox: {
      required: false,
      type: Boolean,
      default: true
    },
    required: {
      required: false,
      type: Boolean,
      default: true
    },
    autocomplete: {
      required: false,
      type: String,
      default: ''
    },
    value: {
      required: true,
      type: [String, null]
    },
    isMalformed: {
      required: false,
      type: Boolean,
      default: false
    },
    errorMessage: {
      required: false,
      type: [String, null],
      default: 'This field is required'
    }
  },
  emits: ['input', 'init', 'onValidate', 'onFocus'],
  setup(props, { emit }) {
    const { config } = useConfig();

    const getCountryCode = computed<string>(() => {
      // All european stores have general_country_default as NL.
      const isEuropeanStore = config.value?.general_country_default === 'NL';
      if (isEuropeanStore) {
        return 'US';
      }
      return config.value?.general_country_default;
    });

    const localVmodel = computed({
      get() {
        const plus = props.value && !props.value.includes('+') ? '+' : '';
        return plus + props.value;
        // return props.value;
      },
      set(newValue) {
        emit('input', newValue);
      }
    });

    const onValidate = (event) => {
      emit('onValidate', event);
    };
    const onFocus = () => {
      emit('onFocus');
    };

    onMounted(() => {
      emit('init');
    });

    return {
      localVmodel,
      getCountryCode,
      phoneInputCountries,
      onValidate,
      onFocus
    };
  }
});
