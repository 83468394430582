


























































import {
  defineComponent,
  ref,
  onBeforeMount,
  computed,
  inject,
  nextTick,
  onUnmounted
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoQuickFilterNav',
  props: {
    productsAmount: {
      required: false,
      default: 0,
      type: Number
    },
    removableFilters: {
      required: true,
      type: Array
    },
    showMore: Boolean
  },
  emits: ['changeVisibility', 'clearFilters', 'showMoreFilters'],
  setup(_, { emit }) {
    const { selectedFilters } = inject('UseFiltersProvider');
    const changeFilterVisibility = () => emit('changeVisibility');
    const clearFilters = () => emit('clearFilters');
    const showMoreFilters = () => emit('showMoreFilters');
    const wrapperHeight = ref<number>(0);
    const resizeObserver = ref(null);
    const selectedFiltersWrapper = ref(null);

    const getWrapperHeight = computed(() => wrapperHeight.value >= 70);
    const getSelectedFiltersLength = computed(() => {
      return Object.values(selectedFilters.value).reduce(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        (memo, cur) => memo + cur.length,
        0
      );
    });

    onBeforeMount(() => {
      nextTick(() => {
        selectedFiltersWrapper.value = document.querySelector(
          '.selected-filters-wrapper'
        );
        resizeObserver.value = new ResizeObserver(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          wrapperHeight.value = selectedFiltersWrapper.value.offsetHeight;
        });
        resizeObserver.value.observe(selectedFiltersWrapper.value);
      });
    });

    onUnmounted(() => {
      if (resizeObserver.value) {
        resizeObserver.value.unobserve(selectedFiltersWrapper.value);
      }
    });

    return {
      changeFilterVisibility,
      getSelectedFiltersLength,
      clearFilters,
      getWrapperHeight,
      showMoreFilters
    };
  }
});
