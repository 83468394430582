






































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import { useUiState } from '~/composables/useUiState';
import { useEngraving } from '~/diptyqueTheme/composable/useEngraving';

export default defineComponent({
  name: 'VaimoEngravingCard',
  components: {
    VaimoSidebar: () => import('organisms/VaimoSidebar.vue'),
    EngravingLayer: () => import('organisms/product/EngravingLayer.vue')
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    hideEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { i18n } = useContext();
    const locale = i18n.localeProperties?.code;
    const { openEngravingInfo, hideEngravingInfo, isEngravingInfoOpen } =
      useUiState();
    const storeCode = i18n.localeProperties.code;

    const showMore = ref(false);
    const { getProductEngravingConfig, engravingConfig, error, loading } =
      useEngraving();

    onMounted(async () => {
      if (!props.hideEdit) {
        await getProductEngravingConfig({
          sku: props.product.product.sku,
          store_code: storeCode
        });
      }
    });

    const engravingTemplates = computed(() => engravingConfig.value?.template);

    const engravingData = computed(() => props.product.engraving);

    const engravingPositionLabel = computed(
      () => engravingData.value?.template.position.label
    );

    const engravingText = computed(() => engravingData.value?.engraving_text);

    const combinedEngravingText = computed(() => {
      const text = engravingText.value;
      if (text) {
        try {
          const engravingObj = JSON.parse(text);
          const line1 = engravingObj.line1 || '';
          const line2 = engravingObj.line2 || '';
          return line2 ? `${line1} ${line2}` : line1;
        } catch {
          return '';
        }
      }
      return '';
    });

    const engravingFontLabel = computed(() => engravingData.value?.font.label);

    const engravingColorLabel = computed(
      () => engravingData.value?.color.label
    );

    const showEngravingInfoLayer = ref(false);

    const goToEngravingInfo = (id) => {
      if (id === props.product.id) {
        showEngravingInfoLayer.value = true;
        openEngravingInfo();
      } else {
        showEngravingInfoLayer.value = false;
      }

      openEngravingInfo();
    };
    const visibilityState = computed(() => {
      return isEngravingInfoOpen.value;
    });

    watch(
      () => visibilityState.value,
      (newValue: boolean) => {
        if (newValue === false) {
          showEngravingInfoLayer.value = false;
        }
      }
    );

    return {
      locale,
      showMore,
      error: error.value.getProductEngravingConfig,
      loading,
      showEngravingInfoLayer,
      engravingData,
      engravingTemplates,
      engravingConfig,
      engravingPositionLabel,
      engravingText,
      combinedEngravingText,
      engravingFontLabel,
      engravingColorLabel,
      goToEngravingInfo,
      openEngravingInfo,
      hideEngravingInfo,
      isEngravingInfoOpen
    };
  }
});
