













































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import { getPrice } from '~/modules/catalog/product/getters/productGetters';

export default defineComponent({
  name: 'BoxSliderCard',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoQuantitySelector: () => import('organisms/VaimoQuantitySelector.vue')
  },
  props: {
    product: {
      required: true
    },
    selectedItems: {
      required: true,
      type: Array as PropType<{ id: string; quantity: number }[]>
    },
    numberOfSets: {
      required: true,
      type: Number
    },
    fieldsVisibilityArr: {
      required: true,
      type: Array as PropType<string[]>
    },
    isMaxAdded: Boolean
  },
  emits: ['updateQty'],
  setup(props, { emit }) {
    const updateProductQty = (option, qty) => {
      emit('updateQty', {
        id: option.uid,
        quantity: qty,
        price: getProductPrice(option.product)
      });
    };

    const getProductPrice = (product) => {
      if (product) {
        return getPrice(product).regular || 0;
      }
    };

    const getProductName = (product) => {
      if (props.fieldsVisibilityArr.includes('Name 2')) {
        return product.product.name;
      }
      return product.product?.identity_name || product.product?.name;
    };

    const getProductPlpDescription = (product) => {
      if (props.fieldsVisibilityArr.includes('PLP Description')) {
        return product.product.plp_description;
      }
      return '';
    };
    const getProductHoverImage = (product) => {
      if (!product || !product['hover_on_card']) {
        return null;
      }
      return product['hover_on_card'];
    };

    const getQuantity = (id) => {
      const currentProduct = props.selectedItems.find(
        (bundle) => bundle.id === id
      );
      return currentProduct?.quantity || 0;
    };

    return {
      updateProductQty,
      getQuantity,
      getProductHoverImage,
      getProductName,
      getProductPlpDescription
    };
  }
});
