<template>
  <div class="vw-cod-fee">
    <SfProperty
      :name="$t('Cash On Delivery Fee')"
      :value="validateFraction(getAmount)"
      class="sf-property--full-width property"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfProperty } from '@storefront-ui/vue';

import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import useCart from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'CodFee',
  components: {
    SfProperty
  },
  setup() {
    const { cart } = useCart();

    const getAmount = computed(() => +cart.value?.prices?.vw_cod_fee.value);
    return {
      getAmount,
      validateFraction
    };
  }
});
</script>

<style scoped></style>
