










































































import { ref, computed, defineComponent, Ref } from '@nuxtjs/composition-api';
import VaimoSlider from 'molecules/VaimoSlider.vue';

import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import VaimoButton from 'atoms/VaimoButton.vue';

export default defineComponent({
  name: 'CommitmentPush2__desktopDeclination1',
  components: {
    VaimoButton,
    VaimoSlider,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue')
  },
  props: {
    declinationData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const positionMap = {
      'Visual on the right for desktop': 'right',
      'Visual on the left for desktop': 'left'
    };

    const { getBannerFunction } = useDeclination(props.declinationData);
    const items = computed(
      () => props.declinationData?.itemsCollection?.items || []
    );

    const getVisualPosition = (item) => {
      return positionMap[item?.visualPosition] || 'right';
    };

    const getContainerClasses = (item) => {
      return {
        'text-center': item?.center?.includes('desktop'),
        'text-left': !item?.center?.includes('desktop')
      };
    };

    const currentSlideIndex: Ref = ref(0);

    const updateCurrentSlideIndex = (index: number) => {
      currentSlideIndex.value = index;
    };

    const currentSlideVisualPosition = computed(() => {
      const item = items.value[currentSlideIndex.value];
      return getVisualPosition(item) === 'left' ? 'visual-left' : 'visual-right';
    })


    return {
      items,
      currentSlideVisualPosition,
      getBannerFunction,
      getContainerClasses,
      getVisualPosition,
      updateCurrentSlideIndex
    };
  }
});
