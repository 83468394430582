
















import { computed, defineComponent, useFetch } from '@nuxtjs/composition-api';

import { addBasePath } from '~/helpers/addBasePath';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

export default defineComponent({
  name: 'VaimoContentBlock',
  components: {
    VaimoIcon: () => import('atoms/VaimoIcon.vue'),
    // SfImage
  },
  props: {
    image: {
      type: String,
      required: false,
      default: 'livre'
    },
    bunchKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const ENTRY_TITLE = 'title';
    const ENTRY_TEXT = 'text';
    const { query, queryResponse } = useContentfulGQL('contentBlock');
    const entries = computed(
      () =>
        queryResponse.value?.microdataBunchCollection?.items?.[0]
          ?.entriesCollection?.items || []
    );

    const getTitle = () => {
      return entries.value?.find((item) => item.key === ENTRY_TITLE)?.value;
    };

    const getLines = () => {
      return entries.value?.filter((item) => item?.key === ENTRY_TEXT);
    };

    useFetch(async () => {
      await query('getMicroDataBunchByKey', {
        key: props.bunchKey
      });
    });

    return {
      addBasePath,
      getTitle,
      getLines
    };
  }
});
