var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-info-screen"},[_c('div',{staticClass:"account-info-screen__info"},[_c('h1',{staticClass:"title"},[_vm._v("\n      "+_vm._s(_vm.$t('Personal Information'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"account-info-screen__wrapper"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.isJpStore)?[_c('div',{staticClass:"first-last-name"},[_c('ValidationProvider',{attrs:{"name":"second_name","rules":_vm.getRules({
                en_hk: 'chars|doubleQuotes',
                zh_hk: 'chars|doubleQuotes',
                ja_jp: 'chars',
                default: 'required|name'
              }),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"text","name":"second_name","placeholder":_vm.$t('Last Name'),"label":_vm.$t('Last Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"name","rules":_vm.getRules({
                en_hk: 'chars|doubleQuotes',
                zh_hk: 'chars|doubleQuotes',
                ja_jp: 'chars',
                default: 'required|name'
              }),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"text","name":"name","placeholder":_vm.$t('First Name'),"label":_vm.$t('First Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)})],1),_vm._v(" "),(_vm.config.use_kana)?_c('div',{staticClass:"first-last-name"},[_c('div',{staticClass:"last-name flex-1"},[_c('ValidationProvider',{attrs:{"name":"lastnamekana","rules":_vm.nameKanaRules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"text","name":"lastnamekana","placeholder":_vm.$t('Last name kana'),"label":_vm.$t('Last name kana'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastnamekana),callback:function ($$v) {_vm.$set(_vm.form, "lastnamekana", $$v)},expression:"form.lastnamekana"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"first-name flex-1"},[_c('ValidationProvider',{attrs:{"name":"firstnamekana","rules":_vm.nameKanaRules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"text","name":"firstnamekana","placeholder":_vm.$t('First name kana'),"label":_vm.$t('First name kana'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstnamekana),callback:function ($$v) {_vm.$set(_vm.form, "firstnamekana", $$v)},expression:"form.firstnamekana"}})]}}],null,true)})],1)]):_vm._e()]:[_c('div',{staticClass:"first-last-name"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|name","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"text","name":"name","placeholder":_vm.$t('First Name'),"label":_vm.$t('First Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"second_name","rules":"required|name","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"text","name":"second_name","placeholder":_vm.$t('Last Name'),"label":_vm.$t('Last Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}])})],1)],_vm._v(" "),_c('div',{staticClass:"item mb-base mt-base"},[_c('VaimoDatePickerWrapper',{attrs:{"model":_vm.form.date_of_birth,"popup-style":{ left: 0, top: '56px', marginTop: '10px' }},on:{"update:model":function($event){return _vm.$set(_vm.form, "date_of_birth", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(slotProps){return [_c('ValidationProvider',{attrs:{"name":"date_of_birth","rules":("date-of-birth:" + _vm.isUsCaStore),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',_vm._g(_vm._b({attrs:{"type":"text","name":"date_of_birth","described-by":"date-of-birth-explanation","label":_vm.$t('Date of birth'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":_vm.onDateInput},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('VaimoTooltip',{attrs:{"icon":"help_grisperle_12","explanation":"Share you Birthday details with us and we'll make sure to plan a birthday present for you.","explanation-id":"date-of-birth-explanation"}})]},proxy:true}],null,true),model:{value:(_vm.form.date_of_birth),callback:function ($$v) {_vm.$set(_vm.form, "date_of_birth", $$v)},expression:"form.date_of_birth"}},'SfInput',slotProps.props,false),slotProps.events))]}}],null,true)})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"item phone"},[_c('VaimoPhone',{attrs:{"classes":"input--with-label"},on:{"isPhoneValid":function($event){return _vm.phoneValidHandler($event)}},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1),_vm._v(" "),_c('div',{staticClass:"item"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"email_ex|required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"input--with-label",attrs:{"type":"email","name":"customer-email","placeholder":_vm.$t('Email'),"label":_vm.$t('Email'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_vm._v(" "),_c('span',{staticClass:"required-fields"},[_vm._v(_vm._s(_vm.$t('* Required fields')))]),_vm._v(" "),_c('VaimoButton',{staticClass:"save-btn",attrs:{"block":"","variant":"primary","disabled":_vm.loading || invalid || !_vm.form.mobile || !_vm.isPhoneValid,"label-centered":"","tabindex":"0"},on:{"click":_vm.updateCustomer}},[_vm._v("\n          "+_vm._s(_vm.$t('Save'))+"\n        ")])]}}])}),_vm._v(" "),_c('h2',{staticClass:"login-credentials-title"},[_vm._v(_vm._s(_vm.$t('Login Information')))]),_vm._v(" "),_c('div',{ref:"loginCredentials",staticClass:"login-credentials"},[_c('ValidationObserver',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
return [_c('div',{staticClass:"item"},[(!_vm.isLoggedInWithSocials)?_c('VaimoPasswordInput',{attrs:{"label":"Current password","placeholder":"Current password","provider-name":"curr_pass","rules":"required"},model:{value:(_vm.changePasswordData.currentPass),callback:function ($$v) {_vm.$set(_vm.changePasswordData, "currentPass", $$v)},expression:"changePasswordData.currentPass"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"item"},[_c('VaimoPasswordInput',{attrs:{"label":"New password","placeholder":"New password","type":_vm.new_password_type,"provider-name":"new_pass","rules":"required|password","with-info-text":""},model:{value:(_vm.changePasswordData.newPass),callback:function ($$v) {_vm.$set(_vm.changePasswordData, "newPass", $$v)},expression:"changePasswordData.newPass"}})],1),_vm._v(" "),_c('div',{staticClass:"item"},[_c('VaimoPasswordInput',{attrs:{"label":"Confirm New Password","placeholder":"Confirm New Password","type":_vm.new_password_type,"provider-name":"confirm_new_pass","rules":"required|password"},model:{value:(_vm.changePasswordData.confirmedNewPass),callback:function ($$v) {_vm.$set(_vm.changePasswordData, "confirmedNewPass", $$v)},expression:"changePasswordData.confirmedNewPass"}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.updateError || _vm.matchPasswords)?_c('div',{staticClass:"error-block"},[_vm._v("\n              "+_vm._s(_vm.updateError)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('VaimoButton',{staticClass:"save-btn",attrs:{"block":"","variant":"primary","label-centered":"","tabindex":"0","disabled":!_vm.checkPasswordFields ||
              invalid ||
              _vm.loading ||
              _vm.matchPasswords},on:{"click":function($event){return _vm.changeCustomerPassword(invalid)}}},[_vm._v("\n            "+_vm._s(_vm.$t('Save'))+"\n          ")])]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }