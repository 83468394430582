<template>
  <SfHeading
    v-if="heading || subHeading"
    :heading="heading"
    :heading-level="headingLevel"
    :heading-style="headingStyle"
    :sub-heading="subHeading"
    :sub-heading-level="subHeadingLevel"
    :sub-heading-style="subHeadingStyle"
    :sub-heading-position="subHeadingPosition"
    class="vaimo-heading"
    :class="{
      ...getSubHeadingPositions
    }"
  >
    <template #title>
      <component
        :is="headingLevel"
        v-if="!animatedHeading"
        class="vaimo-heading__title"
        :class="{
          ...headingLevelsClasses,
          ...headingStylesClasses,
          ...headingClasses
        }"
      >
        <VaimoSpyWrapper
          :tag="headingLinkComponent"
          :link="normalizeLink(headingLink)"
          :aria-label="headingText"
          class="reset-styles"
        >
          {{ headingText }}
        </VaimoSpyWrapper>
      </component>
      <component
        :is="headingLevel"
        v-else
        ref="headingEl"
        class="vaimo-heading__title"
        :class="{
          ...headingLevelsClasses,
          ...headingStylesClasses,
          animate: isVisible
        }"
        v-html="headingText"
      />
    </template>

    <template #description>
      <component
        :is="subHeadingLevel"
        v-if="!animatedDescription && subHeading"
        class="vaimo-heading__subtitle"
        :class="{
          ...subHeadingLevelsClasses,
          ...subHeadingStylesClasses
        }"
      >
        {{ subTitleText }}
      </component>
      <component
        :is="subHeadingLevel"
        v-else-if="subHeading"
        ref="subtitleEl"
        class="vaimo-heading__subtitle"
        :class="{
          ...subHeadingLevelsClasses,
          ...subHeadingStylesClasses,
          animate: isVisible
        }"
        v-html="subTitleText"
      />
    </template>
  </SfHeading>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref
} from '@nuxtjs/composition-api';
import { SfHeading, SfLink } from '@storefront-ui/vue';
import { useIntersectionObserver } from '@vueuse/core';
import { useTransitionStore } from '@/components/utils/stores/transitionStore';
import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'VaimoHeading',
  components: {
    VaimoSpyWrapper: () => import('atoms/helper/VaimoSpyWrapper.vue'),
    SfHeading,
    SfLink
  },
  props: {
    heading: {
      required: false,
      default: '',
      type: String
    },
    headingLevel: {
      required: false,
      type: String,
      default: 'h2',
      validator: (value) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
    },
    headingStyle: {
      required: false,
      type: String,
      default: 'default',
      validator: (value) => ['default', 'emphasized'].includes(value)
    },
    headingClasses: {
      required: false,
      type: String
    },
    headingLink: {
      required: false,
      type: String
    },
    subHeading: {
      required: false,
      default: '',
      type: String
    },
    subHeadingLevel: {
      required: false,
      type: String,
      default: 'h4',
      validator: (value) => ['h3', 'h4', 'h5', 'h6', 'p'].includes(value)
    },
    subHeadingStyle: {
      required: false,
      type: String,
      default: 'default',
      validator: (value) => ['default', 'emphasized'].includes(value)
    },
    subHeadingPosition: {
      required: false,
      type: String,
      default: 'below',
      validator: (value) => ['below', 'above'].includes(value)
    },
    animatedHeading: {
      required: false,
      default: false,
      type: Boolean
    },
    animatedDescription: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  setup(props) {
    const { normalizeLink } = useLink();

    const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    const headingStyles = ['default', 'emphasized'];
    const subHeadingLevels = ['h3', 'h4', 'h5', 'h6', 'p'];
    const subHeadingStyles = ['default', 'emphasized'];
    const subHeadingPositions = ['below', 'above'];

    const headingEl = ref(null);
    const isVisible = ref(false);

    const transitionStore = useTransitionStore();
    const handlePopstate = () => {
      transitionStore.setLoadInstantly(true);
    };

    const handleBeforeUnload = () => {
      transitionStore.setLoadInstantly(false);
    };

    onMounted(() => {
      window.addEventListener('popstate', handlePopstate);
      window.addEventListener('beforeunload', handleBeforeUnload);
      if (!transitionStore.loadInstantly) {
        useIntersectionObserver(
          headingEl,
          ([{ isIntersecting, target }], observerElement) => {
            if (isIntersecting) {
              isVisible.value = true;
              observerElement.unobserve(target);
            }
          }
        );
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('popstate', handlePopstate);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    });

    const headingLevelsClasses = computed(() => {
      return headingLevels.reduce((memo, headingLevel) => {
        memo[`${headingLevel}`] = props.headingLevel === headingLevel;

        return memo;
      }, {});
    });

    const headingStylesClasses = computed(() => {
      return headingStyles.reduce((memo, headingStyle) => {
        memo[`vaimo-heading__title-${headingStyle}`] =
          props.headingStyle === headingStyle;

        return memo;
      }, {});
    });

    const subHeadingLevelsClasses = computed(() => {
      return subHeadingLevels.reduce((memo, subHeadingLevel) => {
        memo[`${subHeadingLevel}`] = props.subHeadingLevel === subHeadingLevel;

        return memo;
      }, {});
    });

    const subHeadingStylesClasses = computed(() => {
      return subHeadingStyles.reduce((memo, subHeadingStyle) => {
        memo[`vaimo-heading__subtitle-${subHeadingStyle}`] =
          props.subHeadingStyle === subHeadingStyle;

        return memo;
      }, {});
    });

    const getSubHeadingPositions = computed(() => {
      return subHeadingPositions.reduce((memo, subHeadingPosition) => {
        memo[`vaimo-heading__subtitle-${subHeadingPosition}`] =
          props.subHeadingPosition === subHeadingPosition;

        return memo;
      }, {});
    });

    const headingText = computed(() => {
      if (!props.animatedHeading) {
        return props.heading;
      }
      let letterIndex = 0;
      return props.heading
        .split(' ')
        .map((word) => {
          let spanText = word
            .split('')
            .map((letter) => {
              letterIndex++;
              return `<span class="animate-text animate-text--${letterIndex}">${letter}</span>`;
            })
            .join('');
          return `<span class="word">${spanText} </span> `;
        })
        .join('');
    });

    const subTitleText = computed(() => {
      if (!props.animatedDescription) {
        return props.subHeading;
      }
      return props.subHeading
        .split(' ')
        .map((word) => {
          return `<span class="animate-description">${word}</span>${
            word === ' ' ? ' ' : ''
          }`;
        })
        .join(' ');
    });

    const headingLinkComponent = computed(() => {
      return props.headingLink ? 'SfLink' : null;
    });

    return {
      headingLevelsClasses,
      headingStylesClasses,
      subHeadingLevelsClasses,
      subHeadingStylesClasses,
      getSubHeadingPositions,
      headingLinkComponent,
      headingText,
      subTitleText,
      headingEl,
      normalizeLink,
      isVisible
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/diptyqueTheme/assets/styles/atoms/VaimoHeading';
</style>

<style lang="scss">
span.animate-text {
  opacity: 0;
  transform: translateY(15px);
  display: inline-block;
}
.animate span.animate-text {
  animation: fadeIn 0.1s ease-out forwards;
}
.animate span.animate-description {
  animation: fadeIn 0.05s ease-out forwards;
}
span.word {
  display: inline-block;
  &:after {
    content: ' ';
  }
}

span.animate-description {
  opacity: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: inline-block;
}

@for $i from 2 through 90 {
  .animate span.animate-text--#{$i} {
    animation-delay: 0.2s + $i * 0.1s;
    animation: fadeIn 0.2s + $i * 0.1s ease-out forwards;
  }
  .animate span.animate-description:nth-of-type(#{$i}) {
    animation: fadeIn $i * 0.05s ease-out forwards;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
