


















































































































import { SfLoader, SfLink } from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { useImage, useProduct } from '~/composables';
import { usePrice } from '~/modules/catalog/pricing/usePrice';
import { useSection } from '~/diptyqueTheme/composable/useSection';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'ProductSingleSection',
  components: {
    SfLoader,
    SfLink,
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoActionButton: () => import('atoms/VaimoActionButton.vue'),
    VaimoBadge: () => import('atoms/VaimoBadge.vue'),
    BlockWrapper: () => import('templates/BlockWrapper.vue'),
    Banner: () => import('templates/sections/Banner.vue'),
    RatioWrapper: () => import('templates/RatioWrapper.vue')
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { getProductDetails, loading } = useProduct();
    const { getMagentoImage } = useImage();
    const { getPricesBySku } = usePrice();
    const {
      headingData,
      truncatedTextData,
      getContentAlignClasses,
      getBlockElementClasses
    } = useSection(props.sectionData);
    const filterData = (items) => {
      if (!items) return [];
      return items.filter((el) => el);
    };
    // TODO Labels Labels should contain those labels that the product has in magento and those labels that are added in Contentful.
    // (will be finished when our environment is done)
    const labelsData = computed(() => {
      filterData(props.sectionData.labelsCollection.items);
    });
    const buttonsData = computed(() => {
      filterData(props.sectionData.buttonsCollection.items);
    });
    const banner = computed(() => props.sectionData?.banner);

    const productData = ref({});
    const fetchProductData = async (sku: string) => {
      if (props.sectionData.sku) {
        try {
          const data = await getProductDetails({
            filter: {
              sku: {
                eq: props.sectionData.sku
              }
            }
          });
          productData.value = data?.items?.[0] || [];
        } catch (error) {
          console.error(error);
        }
      } else {
        productData.value = [];
      }
    };

    const strippedHtml = (str): string => {
      let regex = /(<([^>]+)>)/gi;

      return str.replace(regex, '');
    };

    const getProductLink = (product: Product): string => {
      return `${productGetters.getSlug(product, product?.categories?.[0])}`;
    };

    useFetch(async () => {
      await fetchProductData(props.sectionData.sku);
    });

    const productDataPrice = ref({});
    // Critical TODO avoid use onMounted (will be redone when our environment is done)
    // TODO Add the ability to get the price of a product using the getProductDetails method instead of getPricesBySku
    onMounted(async () => {
      const { getPricesBySku } = usePrice();
      const priceData = await getPricesBySku([props.sectionData.sku], 1);
      productDataPrice.value = priceData?.items?.[0];
    });

    return {
      loading,
      productGetters,
      productData,
      headingData,
      truncatedTextData,
      productDataPrice,
      labelsData,
      buttonsData,
      banner,
      strippedHtml,
      getProductLink,
      getMagentoImage,
      getContentAlignClasses,
      elemClasses: getBlockElementClasses
    };
  }
});
