






















import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'InPageNav',
  components: {
    SectionsList: () => import('templates/SectionsList.vue'),
    VaimoTabs: () => import('molecules/VaimoTabs.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const sectionLists = computed(
      () => props.sectionData?.itemsCollection?.items || []
    );

    const tabs = computed(() => {
      const tabs =
        sectionLists.value?.map((list, i) => {
          const id = 'tab' + i;
          const title = list?.heading || 'Tab ' + (i + 1);
          const sections = list?.itemsCollection?.items || [];
          if (!sections.length) return null;

          return {
            id,
            title,
            sections
          };
        }) || [];

      return tabs.filter((t) => t);
    });

    const view = computed(() => {
      return props.sectionData?.type === 'In Page Nav - Anchors'
        ? 'anchors'
        : 'tabs';
    });

    return {
      sectionLists,
      view,
      tabs
    };
  }
});
