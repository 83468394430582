














import {
  defineComponent,
  useContext,
  useRouter,
  reactive
} from '@nuxtjs/composition-api';
import actionTypes from '~/diptyqueTheme/config/actionTypes';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { useCart } from '~/modules/checkout/composables/useCart';
import { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'VaimoActionButton',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    actionType: {
      required: true,
      type: String,
      validator: (value: string) =>
        [
          actionTypes.ADD_TO_CART,
          actionTypes.OPEN_PRODUCT_PAGE,
          actionTypes.OPEN_PAGE
        ].includes(value)
    },
    actionConfig: {
      required: true,
      type: Object
    },
    variant: {
      required: false,
      type: String,
      default: 'primary',
      validator: (value: string) =>
        [
          'primary',
          'default',
          'link',
          'circle',
          'small',
          'link with arrow',
          'link grey'
        ].includes(value)
    },
    iconPosition: {
      required: false,
      type: String,
      default: 'right',
      validator: (value: string) => ['right', 'left'].includes(value)
    },
    link: {
      required: false,
      default: '',
      type: String
    },
    disabled: Boolean
  },

  setup(props) {
    const { getProductDetails } = useProduct();
    const { addItem, loading } = useCart();
    const { app } = useContext();
    const router = useRouter();

    const currentAction = () => {
      const actions = {
        [actionTypes.ADD_TO_CART]: addToCart,
        [actionTypes.OPEN_PRODUCT_PAGE]: openProductPage,
        [actionTypes.OPEN_PAGE]: openPage
      };

      return actions[props.actionType]();
    };

    const productPayload = reactive({
      filter: {
        sku: {
          eq: props.actionConfig.sku
        }
      }
    });

    const openPage = () => {
      return true;
    };

    const addToCart = async () => {
      try {
        const { items } = await getProductDetails(productPayload);
        await addItem({ product: items[0] as Product, quantity: 1 });
      } catch (error) {
        router.push({ name: 'error' });
        console.error(error);
      }
    };

    const openProductPage = async () => {
      try {
        const { items } = await getProductDetails(productPayload);
        const slug: string = productGetters.getSlug(items[0] as Product);
        const path = `/p/${props.actionConfig.sku}${slug}`;
        router.push(app.localePath(path));
      } catch (error) {
        router.push({ name: 'error' });
        console.error(error);
      }
    };

    return {
      currentAction,
      loading
    };
  }
});
