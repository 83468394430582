<template>
  <SfBadge
    v-if="hasSlotContent"
    :variant="variant"
    class="vaimo-badge"
    :class="{
      ...variantsClasses,
      'bg-blanc': white
    }"
  >
    <slot />
  </SfBadge>
</template>

<script>
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfBadge } from '@storefront-ui/vue';

export default defineComponent({
  name: 'VaimoBadge',
  components: {
    SfBadge
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    white: Boolean,
    loading: Boolean
  },

  setup(props, { slots }) {
    const { app } = useContext();
    const isMacOS = computed(() => app.$device.isMacOS);

    const hasSlotContent = computed(() => {
      const slotContent = slots.default ? slots.default() : [];
      return slotContent.some(
        (vnode) => vnode.text && vnode.text.trim().length > 0
      );
    });

    const variants = ['default', 'primary'];

    const variantsClasses = computed(() => {
      return variants.reduce((memo, variant) => {
        memo[`vaimo-badge__${variant}`] = props.variant === variant;

        if (isMacOS.value) {
          memo[`vaimo-badge__${variant}--mac`] = props.variant === variant;
        }

        return memo;
      }, {});
    });

    return {
      hasSlotContent,
      variantsClasses
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/diptyqueTheme/assets/styles/atoms/VaimoBadge';
</style>
