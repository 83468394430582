








































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import EditorialPushItem from 'templates/sections/EditorialPush/EditorialPushItem.vue';

import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';

export default defineComponent({
  name: 'EditorialPush',
  components: {
    EditorialPushItem,
    VaimoTabs: () => import('molecules/VaimoTabs.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { isDesktop } = useScreenSize();
    const editorialCarouselElem = ref(null);
    const activeTabIndex = ref(0);

    const getItems = computed(
      () => props.sectionData.editorialPushItemsCollection?.items || []
    );

    const getTabs = computed(() => {
      return getItems.value.map((item) => ({
        title: item.tabName,
        id: item.sys?.id
      }));
    });

    const changeSlide = (index) => {
      activeTabIndex.value = index;
    };

    const changeByTabClick = (index) => {
      editorialCarouselElem.value.goTo(index);
    };

    return {
      isDesktop,
      editorialCarouselElem,
      changeSlide,
      activeTabIndex,
      changeByTabClick,
      getItems,
      getTabs
    };
  }
});
