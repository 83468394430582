import { render, staticRenderFns } from "./mobileDeclination2.vue?vue&type=template&id=cf956ec6&scoped=true&"
import script from "./mobileDeclination2.vue?vue&type=script&lang=ts&"
export * from "./mobileDeclination2.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf956ec6",
  null
  
)

export default component.exports