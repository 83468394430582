<template>
  <div id="filters">
    <SfSidebar
      :visible="searchStore.filtersOpen"
      class="vaimo-sidebar"
      @close="searchStore.toggleFilterSidebar"
      @keypress.enter="searchStore.toggleFilterSidebar"
    >
      <VaimoIcon
        icon="cross"
        class="cursor-pointer sidebar-aside-close"
        :label="$t('Close')"
        tabindex="0"
        @click.native="searchStore.toggleFilterSidebar"
        @keypress.native.enter="searchStore.toggleFilterSidebar"
      />
      <div class="filters">
        <div v-for="(facet, i) in searchStore.options" :key="i">
          <SfHeading
            :key="`filter-title-${facet.key}`"
            :level="4"
            :title="$t(facet.label)"
            class="filters__title sf-heading--left"
          />
          <div
            v-if="isFacetColor(facet)"
            :key="`${facet.key}-colors`"
            class="filters__colors"
          >
            <SfColor
              v-for="option in facet.options"
              :key="`${facet.id}-${option.value}`"
              :color="option.value"
              :selected="option.selected"
              class="filters__color"
              :aria-label="option.value"
              tabindex="0"
              @click="toggleOption(facet.key, option.name)"
              @keypress.native.enter="toggleOption(facet.key, option.name)"
            />
          </div>
          <div v-else>
            <div
              v-for="option in facet.options.slice(0, initialFilterAmount)"
              :key="option.value"
            >
              <SfFilter
                :key="`${facet.key}-${option.value}`"
                :label="
                  option.value + `${option.count ? ` (${option.count})` : ''}`
                "
                :selected="option.selected"
                :aria-label="option.value"
                class="filters__item"
                @change="toggleOption(facet.key, option.name)"
                @keypress.native.enter="toggleOption(facet.key, option.name)"
              />
            </div>
            <div v-show="isFilterListOpen">
              <div
                v-for="option in facet.options.slice(initialFilterAmount, facet.options.length)"
                :key="option.value"
              >
                <SfFilter
                  :key="`${facet.key}-${option.value}`"
                  :label="
                    option.value + `${option.count ? ` (${option.count})` : ''}`
                  "
                  :selected="option.selected"
                  class="filters__item"
                  :aria-label="option.value"
                  @change="toggleOption(facet.key, option.name)"
                  @keyup.native.enter="toggleOption(facet.key, option.name)"
                />
              </div>
            </div>
            <div v-if="facet.options.length > initialFilterAmount">
              <SfButton
                class="sf-button--pure filters__button-toggler"
                :aria-label="
                  isFilterListOpen ? $t('Show less') : $t('Show more')
                "
                @click="toggleFilterList"
                @keypress.native.enter="toggleFilterList"
              >
                {{ isFilterListOpen ? $t('Show less') : $t('Show more') }}
              </SfButton>
            </div>
          </div>
        </div>
      </div>

      <template #content-bottom>
        <div class="button-wrapper">
          <VaimoButton
            block
            variant="primary"
            class="apply-filters-btn"
            :aria-label="$t('Apply')"
            tabindex="0"
            @click="applyFilters"
            @keypress.native.enter="applyFilters"
          >
            {{ $t('Apply') }}
          </VaimoButton>
          <SfButton
            class="sf-button--full-width filters__button-clear"
            :aria-label="$t('Clear all')"
            tabindex="0"
            @click="clearFilters"
            @keypress.native.enter="clearFilters"
          >
            {{ $t('Clear all') }}
          </SfButton>
        </div>
      </template>
    </SfSidebar>
  </div>
</template>

<script>
import {
  SfSidebar,
  SfButton,
  SfHeading,
  SfFilter,
  SfAccordion,
  SfColor
} from '@storefront-ui/vue';
import { searchStore as useSearch, useUrlHelper } from '../index';
import { ref } from '@nuxtjs/composition-api';

export default {
  name: 'KlevuFiltersSidebar',
  components: {
    SfButton,
    SfSidebar,
    SfFilter,
    SfAccordion,
    SfColor,
    SfHeading,
    VaimoButton: () => import('../../../diptyqueTheme/components/atoms/VaimoButton.vue'),
  },
  props: {
    manager: {
      type: Object,
      default: {}
    }
  },
  setup(props) {
    const initialFilterAmount = 5;
    const isFilterListOpen = ref(false);
    const searchStore = useSearch();
    const { applyQuery } = useUrlHelper();

    const isFacetColor = (facet) => facet.key === 'color';

    const toggleFilterList = () => {
      isFilterListOpen.value = !isFilterListOpen.value;
    };

    const toggleOption = (key, name) => {
      props.manager.toggleOption(key, name);
    };

    const clearFilters = () => {
      props.manager.clearOptionSelections();
      applyQuery(props.manager);
    };

    const applyFilters = () => {
      searchStore.page = 1;
      applyQuery(props.manager);
    };

    return {
      initialFilterAmount,
      isFilterListOpen,
      toggleFilterList,
      clearFilters,
      toggleOption,
      isFacetColor,
      searchStore,
      applyFilters
    };
  }
};
</script>

<style lang="scss">
#filters .apply-filters-btn {
  padding: 20px;

  button .vaimo-button__wrapper {
    flex-direction: column;
  }
}

#filters {
  .sf-filter__label {
    font-size: 14px;
  }
}

#filters .filters__button-clear {
  text-align: center;
  width: 100%;
  color: #333;
  margin-top: -15px;
}

#filters .filters {
  padding: 0 20px;
}

#filters h4.sf-heading__title {
  margin-bottom: 15px;
  font-size: 20px;
  text-transform: uppercase;
  color: grey;
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
}

#filters .filters__button-toggler {
  display: block;
  margin-top: 20px;
  font-size: 14px;
  color: #000;
  font-weight: 300;
  text-decoration: underline;
}

#filters .sf-checkbox__checkmark {
  width: 12px;
  height: 12px;
  border: 1px solid var(--c-gris-nacre);
  border-radius: 0;
}

#filters .sf-checkbox__checkmark.is-active {
  width: 12px;
  height: 12px;
  border: 1px solid #000;
  background: none;
  border-radius: 0;
}

#filters .sf-checkbox__checkmark svg {
  width: 12px;
  height: 12px;

  path {
    fill: #000 !important;
  }
}
</style>
