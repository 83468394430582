























import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ContactPush',
  components: {
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue'),
    SfLink
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const contactLines = computed(() => {
      return props.sectionData.itemsCollection?.items || [];
    });

    return {
      contactLines
    };
  }
});
