





























import { defineComponent } from '@nuxtjs/composition-api';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'ProductBanner_DesktopDeclanation1',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    declinationData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getBannerData, getCTAdata } = useDeclination(props.declinationData);

    return {
      getBannerData,
      getCTAdata
    };
  }
});
