<template>
  <focus-trap
    :active="active"
    :initial-focus="initialFocusElement">
    <slot></slot>
  </focus-trap>
</template>

<script>
import { FocusTrap } from 'focus-trap-vue'

export default {
  components: {
    FocusTrap
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    initialFocusElement: {
      type: String,
      default: ''
    }
  }
};
</script>