










































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';
import EditorialPushItem from 'templates/sections/EditorialPush/EditorialPushItem.vue';

export default defineComponent({
  name: 'VaimoMenuLayerMobile',
  components: {
    Menu: () => import('organisms/menu/Menu.vue'),
    SfLink,
    EditorialPushItem
  },
  scrollToTop: true,
  props: {
    data: {
      required: true,
      type: Array
    },
    currentNav: {
      required: false,
      type: String,
      default: ''
    },
    currentNavLink: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['hide'],
  setup(props, { emit }) {
    const {
      i18n,
      app: { $gtm }
    } = useContext();
    const megaMenuMobileStore = useMegaMenuMobileStore();
    const getSectionData = computed(() =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props.data[0]?.data.filter((section) => section)
    );
    const hideLayer = (): void => {
      emit('hide');
    };
    const closeMenu = () => {
      megaMenuMobileStore.hide();
      megaMenuMobileStore.isMenuLayersVisible = false;
    };
    const isLastItemOfSameType = (index: number): boolean => {
      if (getSectionData.value) {
        return (
          getSectionData?.value[index]?.__typename !==
          getSectionData?.value[index + 1]?.__typename
        );
      }
      return false;
    };
    const getClickNavDetails = () => {
      $gtm.push({
        event: 'clickNav',
        mainLevel: props.currentNav,
        subLevel: i18n.t('Discover universe')
      });
    };
    return {
      getSectionData,
      hideLayer,
      isLastItemOfSameType,
      closeMenu,
      getClickNavDetails
    };
  }
});
