var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vaimo-phone-input"},[_c('div',{staticClass:"vaimo-phone-input__label"},[_vm._v(_vm._s(_vm.$t('Phone number'))+" *")]),_vm._v(" "),_c('vue-tel-input',{directives:[{name:"e2e",rawName:"v-e2e",value:('phone-input'),expression:"'phone-input'"}],staticClass:"vaimo-phone-input__input",attrs:{"all-countries":_vm.phoneInputCountries,"default-country":_vm.getCountryCode,"auto-default-country":false,"name":"phone","input-options":{
      required: _vm.required,
      autocomplete: _vm.autocomplete,
      showDialCode: true,
      maxlength: 20
    },"dropdown-options":{
      showFlags: _vm.showFlags,
      showSearchBox: _vm.showSearchBox,
      showDialCodeInList: true
    }},on:{"validate":_vm.onValidate,"focus":_vm.onFocus},model:{value:(_vm.localVmodel),callback:function ($$v) {_vm.localVmodel=$$v},expression:"localVmodel"}}),_vm._v(" "),(_vm.isMalformed)?_c('div',{staticClass:"vaimo-phone-input__error sf-input__error-message"},[_c('div',{staticClass:"vaimo-phone-input__error-message"},[_vm._v("\n      "+_vm._s(_vm.errorMessage)+"\n    ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }