



































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useContext,
  useFetch,
  watch
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';
import contentfulEntriesIds from '~/diptyqueTheme/config/contentfulEntries';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

export default defineComponent({
  name: 'VaimoPromoBanner',
  components: {
    CountdownTimer: () => import('atoms/CountdownTimer.vue'),
    SfLink
  },
  setup() {
    const { query, queryResponse } = useContentfulGQL('byKeyPromoBanner');
    const promoBannerText = ref('');
    const promoBannerLink = ref('');
    const promoBannerRef = ref(null);
    const showPromo = ref('');
    const headerMobile = ref(null);
    const headerMobileContent = ref(null);
    const promoBannerContentRef = ref(null);
    const isBannerHidden = ref(true);
    const promoBannerHeight = ref(null);
    const headerHeight = ref(null);
    const countDownData = ref<CountDownDataInterface>({});
    const isCountdownExpired = ref(false);
    const greyColor = '#F8F8F8';
    const blackColor = '#000000';
    const promoBackgroundColor = ref(greyColor);
    const promoTextColor = ref(blackColor);
    const {
      app: { i18n }
    } = useContext();

    interface CountDownDataInterface {
      countdownEndDataTime?: string;
      countdownShowHideSeconds?: boolean;
      countdownShowHide?: boolean;
      countDownExpiredFallBackText?: string;
    }

    useFetch(async () => {
      await query('getMicroDataBunchByKey', {
        key: contentfulEntriesIds.PROMO_BANNER_MICRODATA_ITEM_KEY,
        locale: i18n.localeProperties.contentfulLocale
      });
      const promoBannerContent =
        queryResponse.value?.microdataBunchCollection?.items?.[0]
          ?.entriesCollection?.items;

      if (promoBannerContent && promoBannerContent.length) {
        promoBannerContent.forEach(
          ({
            key,
            value,
            longValue,
            countdownEndDataTime,
            countdownShowHideSeconds,
            countdownShowHide,
            promoBannerBackgroundColor,
            promoBannerTextColor
          }) => {
            if (key === 'promo-banner-text') {
              let text = value;
              if (text === '##') text = ''; // Make '##' empty string to make it possible to hide banner for specific locales
              promoBannerText.value = text;

              if (countdownEndDataTime) {
                countDownData.value = {
                  countdownEndDataTime,
                  countdownShowHideSeconds,
                  countdownShowHide,
                  countDownExpiredFallBackText: longValue
                };
              }

              promoBackgroundColor.value =
                promoBannerBackgroundColor || greyColor;

              promoTextColor.value = promoBannerTextColor
                ? blackColor
                : '#ffffff';
            } else if (key === 'promo-banner-link') {
              promoBannerLink.value = value;
            }
          }
        );
      }
    });

    const setCountdownExpiredFlag = (flag: boolean) => {
      isCountdownExpired.value = flag;
      promoBannerText.value = countDownData.value.countDownExpiredFallBackText;
    };

    const closePromoBanner = () => {
      promoBannerRef.value && promoBannerRef.value.classList.remove('opened');
      promoBannerRef.value && promoBannerRef.value.classList.add('closed');

      const promoStorageValue = 'no';
      // eslint-disable-next-line no-magic-numbers
      const expirationDate = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // Set expiration to one week from now
      lsSet('show-promo', { promoStorageValue, expirationDate });

      adjustHeaderHeight();
    };

    const isCountDownEnabled = computed(
      () =>
        countDownData.value.countdownEndDataTime &&
        countDownData.value.countdownShowHide &&
        !isCountdownExpired.value
    );

    const adjustHeaderHeight = () => {
      if (promoBannerRef.value !== null && !promoBannerHeight.value) return;
      const headerMobileContentHeight =
        promoBannerRef.value?.classList.contains('opened')
          ? promoBannerHeight.value + headerHeight.value
          : headerHeight.value;

      if (headerMobile.value && headerMobileContentHeight) {
        headerMobile.value.style.height = `${headerMobileContentHeight}px`;
      }
    };

    onMounted(() => {
      const storedPromoValue = lsGet('show-promo');
      isBannerHidden.value = !!storedPromoValue?.promoStorageValue;

      if (
        storedPromoValue &&
        storedPromoValue.expirationDate > new Date().getTime()
      ) {
        showPromo.value = storedPromoValue.value;
      } else {
        showPromo.value = 'yes';
      }
    });

    watch(
      () => promoBannerText.value,
      () => {
        nextTick(() => {
          headerMobile.value = document.querySelector('.vaimo-header-mobile');
          headerMobileContent.value = document.querySelector(
            '.vaimo-header-mobile__content'
          );
          promoBannerHeight.value = promoBannerContentRef.value?.clientHeight;
          headerHeight.value = headerMobileContent.value?.clientHeight;
          adjustHeaderHeight();
        });
      }
    );

    return {
      promoBannerRef,
      countDownData,
      isCountDownEnabled,
      promoBannerContentRef,
      isBannerHidden,
      promoBannerText,
      promoBannerLink,
      showPromo,
      setCountdownExpiredFlag,
      closePromoBanner,
      promoBackgroundColor,
      promoTextColor
    };
  }
});
