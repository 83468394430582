























































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoVidePlayer',
  props: {
    autoplay: Boolean,
    muted: Boolean,
    loop: Boolean,
    preload: Boolean,
    title: { type: String, default: '' },
    src: { type: String, required: true },
    controlsColor: { type: String, default: 'black' }
  },
  setup(props) {
    const videoRef = ref<HTMLVideoElement>(null);
    const state = reactive({
      isMuted: props.muted,
      isPaused: !props.autoplay,
      isObserving: false,
      stoppedByUser: false,
      playedByUser: false
    });
    const controlsClasses = computed(() => `text-${props.controlsColor}`);

    const videoSettings = computed(() => ({
      muted: state.isMuted ? true : null,
      loop: props.loop ? true : null,
      playsinline: true
    }));

    const videoControls = {
      play() {
        videoRef.value?.play();
        state.isPaused = false;
      },
      pause() {
        videoRef.value?.pause();
        state.isPaused = true;
      },
      mute() {
        state.isMuted = true;
      },
      unmute() {
        state.isMuted = false;
      },
      togglePlayback() {
        state.isPaused ? handlePlayClick() : handlePauseClick();
      }
    };

    const onPressPlay = () => {
      state.isPaused ? videoControls.play() : videoControls.pause();
    };

    const onPressMute = () => {
      state.isMuted === false ? videoControls.mute() : videoControls.unmute();
    };

    const getColoredIcon = (icon) => {
      const color =
        props.controlsColor === 'black' ? '' : '_' + props.controlsColor;
      return icon + color;
    };

    const canPlayVideo = (isIntersecting: boolean) => {
      const isAutoplayEnabled = props.autoplay;
      const isManualPlay = !props.autoplay && state.playedByUser;
      const isStoppedByUser = state.stoppedByUser;
      const isVideoPaused = state.isPaused;

      return (
        isIntersecting &&
        (isAutoplayEnabled || isManualPlay) &&
        !isStoppedByUser &&
        isVideoPaused
      );
    };

    // eslint-disable-next-line no-undef
    const handleIntersection: IntersectionObserverCallback = ([video]) => {
      if (video.isIntersecting && !state.isObserving) {
        state.isObserving = true;
      }

      if (canPlayVideo(video.isIntersecting)) {
        videoControls.play();
      }

      if (!video.isIntersecting && !state.isPaused) {
        videoControls.pause();

        if (!state.isMuted) videoControls.mute();
      }
    };

    // Intersection Observer for autoplay
    let observer;

    const startObserving = () => {
      if (!videoRef.value || !IntersectionObserver) return;

      observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '-100px 0px -50px 0px',
        threshold: 0.2
      });

      observer.observe(videoRef.value);
    };

    const handlePlayClick = () => {
      videoControls.play();
      state.stoppedByUser = false;
      state.playedByUser = true;
    };
    const handlePauseClick = () => {
      videoControls.pause();
      state.stoppedByUser = true;
    };

    onMounted(() => {
      nextTick(startObserving);
    });

    onBeforeUnmount(() => {
      if (observer) {
        observer.disconnect();
      }
    });

    return {
      videoRef,
      videoSettings,
      videoControls,
      getColoredIcon,
      onPressPlay,
      onPressMute,
      controlsClasses,
      handlePlayClick,
      handlePauseClick,
      ...toRefs(state)
    };
  }
});
