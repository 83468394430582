




















































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'ParagraphFlexible',
  components: {
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    Callout: () => import('templates/sections/Callout/index.vue'),
    SfLink
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();

    const paragraphs = computed(() => {
      if (props.sectionData?.itemsCollection?.items?.length) {
        return props.sectionData.itemsCollection.items.filter(
          (item, i) => item.type === 'Paragraph Item' && i < 3
        );
      }
      return [];
    });

    const callout = computed(() => {
      if (props.sectionData?.itemsCollection?.items?.length) {
        return props.sectionData.itemsCollection.items.find(
          (item) => item.type === 'Callout'
        );
      }
      return false;
    });

    return {
      normalizeLink,
      paragraphs,
      callout
    };
  }
});
