




















































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';

export default defineComponent({
  name: 'SubscriptionLegalNotice',
  setup() {
    const { config } = useConfig();
    const {
      app: { i18n }
    } = useContext();

    const isSpecificStore = computed(() =>
      ['en_us', 'fr_us', 'ja_jp', 'en_hk', 'zh_hk'].includes(
        config.value.store_code
      )
    );

    const serviceTermsLinks = computed(() => {
      return termsOfServiceLinks[i18n.locale];
    });

    return {
      isSpecificStore,
      serviceTermsLinks
    };
  }
});
