var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfSection',{staticClass:"section",attrs:{"title-heading":_vm.title}},[_c('SfLoader',{class:{ loading: _vm.loading },attrs:{"loading":_vm.loading}},[_c('SfCarousel',{staticClass:"carousel",attrs:{"data-cy":"related-products-carousel","settings":{ peek: 16, breakpoints: { 1023: { peek: 0, perView: 2 } } }},scopedSlots:_vm._u([{key:"prev",fn:function(prevArrow){return [_c('SfButton',{staticClass:"sf-arrow",attrs:{"aria-label":"previous"},on:{"click":function($event){return prevArrow.go('prev')}}},[_c('VaimoIcon',{attrs:{"icon":"arrowleft","label":_vm.$t('Previous')}})],1)]}},{key:"next",fn:function(nextArrow){return [_c('SfButton',{staticClass:"sf-arrow",attrs:{"aria-label":"next"},on:{"click":function($event){return nextArrow.go('next')}}},[_c('VaimoIcon',{attrs:{"icon":"arrowright","label":_vm.$t('Next')}})],1)]}}])},_vm._l((_vm.mappedProducts),function(product,i){return _c('SfCarouselItem',{key:i,staticClass:"carousel__item"},[_c('SfProductCard',{attrs:{"image-tag":"nuxt-img","title":_vm.productGetters.getName(product),"image-width":_vm.imageSizes.productCard.width,"image-height":_vm.imageSizes.productCard.height,"image":_vm.getMagentoImage(_vm.productGetters.getProductThumbnailImage(product)),"nuxt-img-config":{
            fit: 'cover',
          },"regular-price":_vm.$fc(_vm.productGetters.getPrice(product).regular),"special-price":_vm.productGetters.getPrice(product).special &&
              _vm.$fc(_vm.productGetters.getPrice(product).special),"link":_vm.localePath(
              ("/p/" + (_vm.productGetters.getProductSku(
                product
              )) + (_vm.productGetters.getSlug(product, product.categories[0])))
            ),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(product),"reviews-count":_vm.productGetters.getTotalReviews(product),"is-in-wishlist":_vm.isInWishlist({ product: product }),"is-added-to-cart":_vm.isInCart(product),"wishlist-icon":_vm.isAuthenticated ? 'heart' : '',"is-in-wishlist-icon":_vm.isAuthenticated ? 'heart_fill' : ''},on:{"click:wishlist":function($event){return _vm.addItemToWishlist(product)},"click:add-to-cart":function($event){return _vm.addItemToCart({ product: product, quantity: 1 })}}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }