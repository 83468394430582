


























import { computed, defineComponent, ref } from '@vue/composition-api';
import VaimoProducts from 'organisms/VaimoProducts.vue';

export default defineComponent({
  name: 'ProductCarousel',
  components: {
    VaimoProducts,
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const wrapperProps = ref<{
      slider?: { [key: string]: any };
      grid?: { [key: string]: any };
    }>({
      slider: {
        mobileQuantityVisible: 2
      }
    });

    const getItem = computed<{ [key: string]: any }>(
      () => props.sectionData.itemsCollection?.items?.[0]
    );

    const isProductsShown = computed<boolean>(
      () => !(getItem.value?.type === 'category' && !getItem.value.cid)
    );

    const getCategoryQueryData = computed<{
      categoryID: string;
      limit: number;
    }>(() => ({
      categoryID: getItem.value.cid,
      limit: getItem.value.limit || 50
    }));

    const getLayoutDisplay = computed<string>(() => {
      const displayMap = {
        'Default for current module': 'slider',
        Carousel: 'slider',
        Grid: 'grid'
      };

      return displayMap[getItem.value.layout] || 'slider';
    });

    const footerVisible = computed<boolean>(() => {
      return (
        props.sectionData?.buttonCollection?.items.filter((button) => button).length > 0
      );
    });

    const headerVisible = computed<boolean>(
      () => !!(props.sectionData.heading || props.sectionData.descr)
    );

    return {
      getCategoryQueryData,
      headerVisible,
      footerVisible,
      getLayoutDisplay,
      isProductsShown,
      wrapperProps
    };
  }
});
