<template>
  <div class="goodtoknow-item bg-gris_light text-center p-3md">
    <VaimoBanner
      v-if="image"
      :src="image"
      :max-optimized-width="100"
      ratio="1:1"
      class="goodtoknow-item__image mx-auto max-w-[40px] mb-md"
    />
    <div v-if="title" class="goodtoknow-item__title paragraph-3bold mb-sm">
      {{ title }}
    </div>
    <div
      v-if="description"
      class="goodtoknow-item__description paragraph-4"
      v-html="$dompurify($markdown.render(description))"
    ></div>
    <VaimoButton
      v-if="link"
      :link="link"
      variant="link"
      class="goodtoknow-item__link mt-md"
    >
      {{ label || $t('Read more') }}
    </VaimoButton>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'GoodToKnowItem',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue')
  },
  props: {
    image: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String
    },
    description: {
      required: true,
      type: String
    },
    link: {
      required: false,
      type: String,
      default: ''
    },
    label: {
      required: false,
      type: String,
      default: ''
    }
  }
});
</script>

<style scoped lang="scss">
.goodtoknow-item {
  height: 100%;

  &__link {
    ::v-deep {
      .vaimo-button {
        min-height: auto;
      }
    }
  }

  &__description {
    ::v-deep {
      a {
        @apply links underline;
      }
    }
  }
}
</style>
