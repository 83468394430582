

















import { computed, defineComponent } from '@vue/composition-api';

import { parseStringToDate } from '~/helpers/dateHelpers';

export default defineComponent({
  name: 'VaimoBooxiLifeTimeLabel',
  props: {
    lifetime: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const formattedTime = computed(() => {
      const date = parseStringToDate(props.lifetime);

      if (!date) {
        return null;
      }

      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${hours}:${minutes}`;
    });

    return {
      formattedTime
    };
  }
});
