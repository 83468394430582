



















import { defineComponent } from '@nuxtjs/composition-api';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import { SfLink } from '@storefront-ui/vue';
import { useLink } from '~/diptyqueTheme/composable/useLink';

export default defineComponent({
  name: 'EditorialCardDesktopDeclination1',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoSpyWrapper: () => import('atoms/helper/VaimoSpyWrapper.vue'),
    SfLink
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const { getBannerData } = useDeclination(props.sectionData);
    return {
      getBannerData,
      normalizeLink
    };
  }
});
