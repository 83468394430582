<template>
  <div class="buttons-section">
    <VaimoSectionTemplate
      :section-data="sectionData"
      :unique-key="uniqueKey"
      class="text-center"
      :class="{ compact: isCompact }"
      :content-visible="false"
      :header-visible="true"
    />
  </div>
</template>

<script lang="js">
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ButtonsSection',
  components: {
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    return {
      isCompact: computed(() => {
        return props.sectionData?.type === 'Buttons Compact';
      })
    };
  }
});
</script>

<style lang="scss" scoped>
.buttons-section {
  & > .compact {
    margin-top: -35px;
    @include for-screen-m-and-l {
      margin-top: -60px;
    }
  }

  ::v-deep {
    .vst .module__buttons:first-child {
      margin-top: 0;
    }
  }
}
</style>
