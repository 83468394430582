

















import { defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import VaimoIcon from 'atoms/VaimoIcon.vue';

export default defineComponent({
  name: 'VaimoTryAndBuy',
  components: {
    VaimoIcon,
  },
  props: {
    image: {
      required: false,
      type: String,
      default: 'offerte'
    },
    text: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup() {
    return {
      addBasePath
    };
  }
});
